import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import {  message, Layout, Spin } from 'antd';

import { Button, Form, Input,Row } from 'antd';
 import {  Container } from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';
import 'antd/dist/antd.css';
import '../Admin/admin.css'
import UserContext from '../../UserContext';


class Admin extends Component {
  static contextType = UserContext

  constructor(props) {
    super(props)
    this.state = {
      
    }
  }
  componentDidMount() {
    const isLoggedIn = sessionStorage.getItem('isLoggedIn')

    if (isLoggedIn ==='false') {
        
        this.props.history.push('/login')
      }
    }

 
   /* sign out */
   signOut() {
    const { setLoggin } = this.context
    setLoggin('false')
    this.props.history.push('/login')
    sessionStorage.setItem('isLoggedIn', false);
    
    
}

  render() {
    return (
      <div>
      {/* <div className='sign-out'>
      <Button className='' id='sign-out' loading={this.state.isLoading}  
                     onClick={() => this.signOut()} >
                         Sign out
                      </Button>
      </div> */}
      <Container>
      <div className='row-1'><h3 className='heading-font'>Super Admin</h3></div>
       <div className='row-1'>
       
       <div className='button-spacing'>
       <Link to={{ pathname: '/organization-list'}}>
        
         <Button className='btn-type' id='' loading={this.state.isLoading}  
                                               >
                                                   Organization List
          </Button>
          </Link>
          </div>
          <div className='button-spacing'>
          <Link to={{ pathname: '/user-list'}}>
          <Button className='btn-type' id='' loading={this.state.isLoading}  
                                                >
                                                  Users List
          </Button>
          </Link>
          </div>
         
       </div>
       </Container>
       </div>
    );
  }
}

export default Admin;