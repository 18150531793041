import requestServer from '../request-server';
import CONFIG from '../config'


var internals = {};

internals.sendNotification = function (data) {

    // let data = {};
    return requestServer('/notification/sendNotification', data, 'POST', {}, null, null, true, CONFIG.USER_PATH);
}

export default internals;
