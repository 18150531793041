import React, { Component, } from 'react';
import {
    Row, Col,  Input,  Modal,
     Button,  message,
     Table, 

} from 'antd';
// import {
//     UserOutlined, BellFilled
// } from '@ant-design/icons';
import 'antd/dist/antd.css';
import './ParticipantPermission.css'
import { Link, } from 'react-router-dom';
import UserContext from '../../../UserContext';
import ApiService from '../../../services/api-services';
import TextArea from 'antd/lib/input/TextArea';
const { Search } = Input;
 var searchItem = [];
// const Add = 'Add'

export default class dashboard extends Component {
    static contextType = UserContext

    constructor(props) {
        super(props);
        var formData ={ };
        this.state = {
            token: sessionStorage.getItem('token'),
            formData: formData,
            permissionFullyLoaded: false,
            isFetchSpeciality: false,
            isLoading: false,
            permissionName: '',
            description: '',
            // value: 0,
            // permissionData: [],

        }
    }
   
    // signOut() {
    //     const { setLoggin } = this.context
    //     setLoggin('false');
    //     this.props.history.push('/login')
    //     sessionStorage.setItem('isLoggedIn', false);
    // }
    componentDidMount() {
        if(this.state.token === null || this.state.token === '') {
            this.props.history.push('/login')
            window.location.reload(true)
        }
        this.fetchPermission()
    }


    fetchPermission = () => {
        ApiService.adddetails.permission().then(data => {
            //console.log(data)
            this.setState({ isFetchSpeciality: true })
            if (data.status === 200) {
                let resData = Array.from(data.data);
                let newData = []
                console.log(resData)

                for (let i = 0; i < resData.length; i++) {
                    newData.push({
                        id: resData[i]['id'],
                        name: resData[i]['name'],
                        discription: resData[i]['discription'],
                        logo_url: resData[i]['logo_url'],
                        createdAt: resData[i]['createdAt'],
                        updatedAt: resData[i]['updatedAt'],
                    })
                }
                this.setState({ permissionFullyLoaded: true, permissionData: newData, isLoading: true })
            }
        })
            .catch(error => {
                this.setState({ isFetchSpeciality: false })
            });
    }

    //  onChange(checked) {
    //         console.log(`switch to ${checked}`);
    //         let permissionData = this.state.permissionData;
    //         if(checked) {
    //             this.setState({
    //                 permissionData: {
    //                     ...permissionData,
    //                     status: checked.target.value ? 1 : 0
    //                 }
    //             })
    //             return
    //         } 
        
    // }

    handleChange = (e, field) => {
        if (field === 'permission') {
            this.setState({ permissionName: e },
                function () {

                });
        } else  if (field === 'desc') {
            this.setState({ description: e},
                function () {

             }); 
        }
    }

    doSave() {
       // alert('dosave')
       
        const {permissionName, description} = this.state;
        let name = this.state.permissionName
        let desc = this.state.description
        let str = "/^[a-zA-Z ]*$/";
        let patt =  RegExp(/^[a-zA-Z ]*$/).test(name)
        let patt1 =  RegExp(/^[a-zA-Z ]*$/).test(desc)
        console.log(patt,'patt')
        if (name === '' || patt === false || desc === '' || patt1 === false ) {
          
            message.error('Please Enter valid details ');
            return
        }
        let body = {
            "name": this.state.permissionName.charAt(0).toUpperCase() + this.state.permissionName.slice(1),
            "description": this.state.description,

        }
        console.log(body,'permission')
        // debugger
        ApiService.adddetails.addpermission(body).then(data => {
            this.setState({ isLoading: false })
            if (data.status === 200) {
                message.success('Successfully added the Participant Permission')
                this.setState({setModalVisible: false})
                this.setState({modalVisible: false})
                this.fetchPermission();
                this.props.history.push('/ParticipantPermission')
            }

        })
            .catch(error => {
                this.setState({ isLoading: false })

                if (error.data.status === 702) {
                    message.error('permission already exist');
                }

            });
    }

    handleOk = () => {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false, visible: false });
        }, 3000);
    };

    setModalVisible = (args) => this.setState({ modalVisible: args })

    Tablecolumns = () => [

        {
            title: 'Permission',
            dataIndex: 'name',
            render: name => (<>
                {name}
            </>),
            width: '90%',

        },
        // {
        //     title: 'Date Added',
        //     dataIndex: '',
        //     width: '25%',

        // },
        // {
        //     title: 'Added By',
        //     dataIndex: '',
        //     width: '25%',

        // },
        {
            title: 'Action',
            key: 'action',
            width: '10%',
            // render: (url, record) => {
            //     return <span>
            //         <div><Switch checked={this.state.permissionData.status} onChange={() => this.onChange(this.state.permissionData.status)} /> </div>
            //         </span>;
            //   }        
        }

    ];

      
    render()
    {
        const { permissionData } = this.state;
        //console.log(codeData,'cc')
        if(permissionData) {
            let orgTag = permissionData.sort(function (a, b) {
                return a.name.localeCompare(b.name);
    
            });
        }
        return (
            <>
             <Row>
            <Col span={8} className="flexcenter">
                <h3 className='heading-font'>PARTICIPANT PERMISSION</h3>
            </Col>
            {/* <Col span={16}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                    <ul className="closecodeheaderMenu">
                        <li className="bellNotification">
                            <BellFilled style={{ color: '#0252a1' }} />
                        </li>
                        <li className="profile_button">

                            <div className="headerPorifileDiv">
                                <Avatar shape="square" size="large" icon={<UserOutlined />} style={{ borderRadius: '30%' }} />
                                <span className="proifle_name">
                                    Welcome Sally Rivera
                                </span>
                            </div>
                        </li>
                        <li className="logoutbutton">
                            <Button className="buttonOutline" ghost size="default" onClick={() => this.signOut()}>
                                Logout
                            </Button>
                        </li>
                    </ul>
                </div>
            </Col> */}
            </Row> 
            <Row>
           <div className="search-close-code">
           <Search
                    placeholder="Search Permission"
                    onSearch={value => console.log(value)}
                    onKeyUp={
                        value => {
                        // console.log(value.target.value)
                        ApiService.general.search_close_code_permission({ type: 'permission',key: value.target.value })
                            .then(res => {
                                console.log(res)
                                if(res.data === null && value.target.value === '') {
                                    this.props.history.push('/ParticipantPermission')
                                    console.log('hiias')
                                } else {
                                    this.setState({permissionData:res.data,  isLoading: true})
                                    this.Tablecolumns()
                                }
                                // this.setView(res.data)
                            })
                            .catch(err => {
                                console.log(err)
                            })
                    }
                }
                />
                </div>
                {/* <div className="permission-row">
                <Link to={{ pathname: '' }}>
                    <Button className='adduser-btn' id=''  onClick={() => this.setModalVisible(true)}  loading={!this.state.isLoading }  >
                    Add Permission
                    </Button>
                </Link>
                                     {/* <Link to={{ pathname: '/admin' }}>
                                        <Button className='btn-type' id=''  >
                                            Back
                                </Button>
                                    </Link>  
            </div>  */}
           {/* <button type="primary" className="add-btn">Add Close Code</button>*/}
            </Row>

            <Row>
                <Col span={24}>
                    <div class="table-scroll">
                        <Table
                            columns={this.Tablecolumns()}
                            //rowKey={record => record.id}
                            dataSource={this.state.permissionData}
                            pagination={this.state.pagination}
                            loading={!this.state.isLoading}
                          //  onChange={this.handleTableChange}
                        />

                    </div>
                    </Col>
                    <Modal
                        title="Add Permission"
                        centered
                        visible={this.state.modalVisible}
                        okButtonProps={{ style: { display: 'none' } }}
                        onOk={this.handleOk}
                        onCancel={() => this.setState({ modalVisible: !this.state.modalVisible , permissionName: ''})}
                        footer={[
                            <div className="ant-modal-body-footer">
                                <Button key="back" onClick={() => this.setState({ modalVisible: !this.state.modalVisible , permissionName: '', description: ''})}>
                                    Return
                        </Button>,
                        <Button
                                    htmlType="submit"
                                    key="submit" type="primary"  onClick={() => this.doSave()}>
                                    Submit
                        </Button>,
                        </div>,
                        ]}
                    >
                        <div className='row-4'>
                            <div className='modal_speciality' >

                                <Input
                                    className={'text-input' + ' ' + this.state.ErrorInputClass}
                                    id=""
                                    placeholder="Participant Permission"
                                    required
                                    type="text"
                                    value={this.state.permissionName}
                                    onChange={(e) => this.handleChange(e.target.value, 'permission')}

                                />  
                            </div>
                        </div>
                        <div className='row-4'>
                            <div className='modal_speciality' >

                                 <TextArea
                                    className={'text-input' + ' ' + this.state.ErrorInputClass}
                                    id="description1"
                                    placeholder="Description"
                                    required
                                    value={this.state.description}
                                    onChange={(e) => this.handleChange(e.target.value, 'desc')}

                                /> 
                            </div>
                        </div>
                    </Modal>
             </Row>
        </>
        );
    }
 }
    