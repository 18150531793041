import { Button, Col, Row, Switch, message } from "antd";
import React, { useEffect, useState } from "react";
import ApiService from "../../../services/api-services";
import confirm from "antd/lib/modal/confirm";

export default function AiAccess(props) {
  const [aiConfig, setAiConfig] = useState(0);
  const [id, setId] = useState();
  const token = sessionStorage.getItem("token");
  useEffect(() => {
    if (token === null || token === "") {
      props.history.push("/login");
      window.location.reload(true);
    }
    fetchGlobalConfig();
  });
  const fetchGlobalConfig = () => {
    ApiService.adddetails.getPasswordLength().then((data) => {
      if (data.status === 200) {
        setAiConfig(data.data["is_ai"]);
        setId(data.data["id"]);
      }
    });
  };

  const showDeleteConfirm = (currentvalue) => {
    confirm({
      className: "popup-margin ",
      title:
        "Are you sure want to" +
        " " +
        `${currentvalue === 1 ? "Deactivate" : "Activate"}` +
        " " +
        "AI?",
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      height: "158px",
      onOk() {
        switchAiConfig();
      },
      onCancel() {},
    });
  };

  const switchAiConfig = () => {
    let body = {
      id: id,
      is_ai: aiConfig == 1 ? 0 : 1,
    };
    ApiService.adddetails.updatePasswordLength(body).then((data) => {
      if (data.status === 200) {
        message.success("Successfully updated Ai Configuration");
        setAiConfig(!aiConfig);
      }
    });
  };

  return (
    <div>
      <Row>
        <Col span={12}>
          <h3 className="heading-font">Access To AI</h3>
        </Col>
        <Col span={12} style={{ textAlign: "end" }}>
          <Switch
            style={{ marginRight: 30, marginTop: 5 }}
            checked={aiConfig === 0 ? false : true}
            onClick={() => {
              showDeleteConfirm(aiConfig);
            }}
          ></Switch>
        </Col>
      </Row>
    </div>
  );
}
