import React, { Component } from "react";
import {
  Row,
  Col,
  Input,
  Modal,
  Button,
  message,
  Table,
  Form,
  Tooltip,
  Switch,
  Select,
  DatePicker,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  FullscreenExitOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import "./InAppNotification.css";
import { Link } from "react-router-dom";
import UserContext from "../../../UserContext";
import ApiService from "../../../services/api-services";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";

//import Form from 'antd/lib/form/Form';
const FormItem = Form.Item;
const { Search } = Input;
var searchItem = [];
// const Add = 'Add'
const { confirm } = Modal;
const ReactDragListView = window["react-drag-listview"];
const dateFormat = "YYYY-MM-DD-HH-mm-ss";
const dateFormat2 = "YYYY-MM-DDTHH-mm-ss";
export default class dashboard extends Component {
  static contextType = UserContext;
  formRef = React.createRef();
  formRef2 = React.createRef();
  constructor(props) {
    super(props);
    var formData = {
      org_id: [],
      role_id: [],
      trigger_date: "",
      notification_content: "",
    };
    this.state = {
      stateFormData: formData,
      orgData: [],
      roleData: [],
      notificationData: [],
      isLoading: false,
      modalVisible: false,
      token: sessionStorage.getItem("token"),
      rowOrgId: [],
      rowRoleId: [],
      rowNotificationContent: "",
      rowId: "",
      rowTriggerDate: "",

      formData: formData,
      closecodeFullyLoaded: false,
      isFetchSpeciality: false,
      timeframeName: "",
      visible: false,
      time_frame_id: "",
      time_frame_name: "",
      timeFrameData: [],
      // value: 0,
      // codeData: [],
    };
  }

  fetchOrg = () => {
    ApiService.adddetails.getOrgList().then((data) => {
      if (data.status === 200) {
        console.log("level1", data.data);
        const newData = data.data.filter((item) => {
          if (item.name && item.org_id) {
            if (item.status !== 0) {
              return item;
            }
          }
        });
        this.setState({ orgData: newData });
      }
    });
  };

  fetchRole = () => {
    ApiService.adddetails.getRoleType().then((data) => {
      if (data.status === 200) {
        console.log("level2", data.data.response);
        const newData = data.data.response.filter((item) => {
          if (item.role_name && item.role_id && item.status !== 0) {
            if (
              item.role_name !== "Submitter Staff-New Chat" &&
              item.role_name !== "Submitter Provider-New Chat" &&
              item.role_name !== "Submitter Provider-@ Specialty" &&
              item.role_name !== "Submitter Staff-@ Specialty" &&
              item.role_name !== "Responder Provider - @ Specialty" &&
              item.role_name !== "Staff Responder - @ Specialty"
            ) {
              return item;
            }
          }
        });
        this.setState({ roleData: newData });
      }
    });
  };
  // signOut() {
  //     const { setLoggin } = this.context
  //     setLoggin('false');
  //     this.props.history.push('/login')
  //     sessionStorage.setItem('isLoggedIn', false);
  // }
  componentDidMount() {
    if (this.state.token === null || this.state.token === "") {
      this.props.history.push("/login");
      window.location.reload(true);
    }

    this.fetchOrg();
    this.fetchRole();
    this.fetchExistingNotification();
  }

  fetchExistingNotification = () => {
    let body = {
      type: "view",
    };

    ApiService.adddetails
      .addNotification(body)
      .then((data) => {
        if (data.status === 200) {
          let resData = Array.from(data.data);
          let newData = [];
          console.log("fetchData", resData);
          for (let i = 0; i < resData.length; i++) {
            //if (resData[i]["status"] === 1) {
            newData.push({
              id: resData[i]["id"],
              content: resData[i]["content"],
              time: resData[i]["time"],
              org_ids: resData[i]["org_ids"],
              status: resData[i]["status"],
              roles: resData[i]["roles"],
            });
            //}
          }
          this.setState({
            //closecodeFullyLoaded: true,
            notificationData: newData,
            isLoading: true,
          });
        }
      })
      .catch((error) => {
        message.error("Something went wrong");
        console.log("something went wrong...", error);
      });
  };

  showModal = (id, content, orgId, triggerDate, roles) => {
    //Form.resetFields(["organization"]);
    // this.formRef2.current.resetFields();

    this.setState({ rowOrgId: [] });
    this.setState({ rowRoleId: [] });
    this.setState({ rowTriggerDate: "" });
    this.setState({ rowNotificationContent: "" });

    this.setState((prev) => {
      return {
        rowId: id,
        rowNotificationContent: content,
        rowOrgId: orgId,
        rowTriggerDate: triggerDate,
        rowRoleId: roles,
        visible: !prev.visible,
      };
    });
    //this.formRef.setFieldsValue({});
  };

  setModalVisible = (args) => this.setState({ modalVisible: args });

  // showMessage() {
  //     message.warn('Coming Soon...');
  // }

  // onChange(checked) {
  //     console.log(`switch to ${checked}`);
  //     let codeData = this.state.codeData;
  //     if(checked) {
  //         this.setState({
  //             codeData: {
  //                 ...codeData,
  //                 status: checked.target.value ? 1 : 0
  //             }
  //         })
  //         return
  //     }
  // }

  handleChange = (e, field) => {
    const { stateFormData } = this.state;
    // if (field === "timeframe") {
    //   this.setState({ timeframeName: e }, function () {});
    // }
    if (field === "org_id") {
      console.log("testing1", field + "---", e);
      //this.setState({ org_id: e });
      this.setState({
        stateFormData: { ...stateFormData, [field]: e },
      });
      return;
    } else if (field === "update_org_id") {
      this.setState({
        rowOrgId: e,
      });
    } else if (field === "update_role_id") {
      this.setState({ rowRoleId: e });
    } else if (field === "role_id") {
      this.setState({
        stateFormData: { ...stateFormData, [field]: e },
      });
      return;
    } else if (field === "trigger_date") {
      this.setState({
        stateFormData: {
          ...stateFormData,
          [field]: moment(e).format("YYYY-MM-DDTHH:mm:ss"),
        },
      });
      return;
    } else if (field === "notification_content") {
      this.setState({
        stateFormData: { ...stateFormData, [field]: e },
      });
      return;
    } else if (field === "update_notification_content") {
      this.setState({
        rowNotificationContent: e,
      });
    } else if (field === "update_trigger_date") {
      if (e !== null) {
        this.setState({
          rowTriggerDate: moment(e).format("YYYY-MM-DDTHH:mm:ss"),
        });
      } else {
        //message.error("Please select a valid date!");
        this.setState({ rowTriggerDate: "" });
        return;
      }
    }
  };

  updateChange = (e, field) => {
    if (field === "timeframe") {
      this.setState({ time_frame_name: e }, function () {});
    }
  };

  doSave() {
    if (
      this.state.stateFormData.notification_content === "" ||
      this.state.stateFormData.org_id.length <= 0 ||
      this.state.stateFormData.role_id.length <= 0 ||
      this.state.stateFormData.trigger_date === ""
    ) {
      message.error("Please fill the mandatory fields ");
      return;
    }

    //below code is to convert to utc time
    var isoDateString = new Date(
      this.state.stateFormData.trigger_date
    ).toISOString();
    console.log("this is united time 3", isoDateString);

    let body = {
      type: "add",
      org_ids: this.state.stateFormData.org_id,
      roles: this.state.stateFormData.role_id,
      content: this.state.stateFormData.notification_content,
      time: isoDateString,
    };
    console.log(body, "111");
    ApiService.adddetails
      .addNotification(body)
      .then((data) => {
        //this.setState({ isLoading: false });
        if (data.status === 200) {
          message.success("Successfully added the Notification");
          this.setState({ modalVisible: false });
          this.setState({
            stateFormData: {
              org_id: [],
              role_id: [],
              notification_content: "",
              trigger_date: "",
            },
          });
          console.log("after sve", this.state.stateFormData);
          this.formRef.current.resetFields();
          this.fetchExistingNotification();
          this.props.history.push("/in-app-notification");
        }
      })
      .catch((error) => {
        if (error.data.status === 708) {
          message.error("Notification already exists");
        } else {
          message.error("Something went wrong");
        }
        console.log("caught_error", error);
      });
  }

  updateSave() {
    // alert('dosave')
    console.log("up", this.state.rowId);
    console.log("up4 current", this.state.rowTriggerDate);
    const pattern = /^[\w\s.,!?:;'"/&%$#@()*+-]*((https?:\/\/|www\.)[^\s]*)?$/;

    if (
      this.state.rowNotificationContent === "" ||
      this.state.rowOrgId <= 0 ||
      this.state.rowRoleId <= 0 ||
      this.state.rowTriggerDate === "" ||
      this.state.rowTriggerDate === null
    ) {
      message.error("Please fill the mandatory fields ");
      return;
    } else if (!pattern.test(this.state.rowNotificationContent)) {
      message.error("Please enter valid input");
      return;
    } else {
      var isoDateString = new Date(this.state.rowTriggerDate).toISOString();
      console.log("this is united time 3", isoDateString);
      let body = {
        type: "update",
        id: this.state.rowId,
        org_ids: this.state.rowOrgId,
        roles: this.state.rowRoleId,
        content: this.state.rowNotificationContent,
        time: isoDateString,
      };
      //console.log("this is id", idPassed);
      ApiService.adddetails
        .addNotification(body)
        .then((data) => {
          //this.setState({ isLoading: false });
          if (data.status === 200) {
            message.success("Successfully updated the notification");
            this.setState({ visible: false });
            this.fetchExistingNotification();
            this.props.history.push("/in-app-notification");
          }
        })
        .catch((error) => {
          //this.setState({ isLoading: false });
          message.error("Something went wrong");
        });
    }
  }

  showDeleteModal(id) {
    var that = this;
    confirm({
      className: "popup-margin ",
      title: "Are you sure want to delete" + " " + "this Notification?",
      //   content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      height: "158px",
      onOk() {
        console.log("id 1- test");
        that.deleteNotificationRowById(id);
      },
      onCancel() {},
    });
  }
  getRowOrgIdDefaultValue() {
    return this.state.rowOrgId;
  }
  deleteNotificationRowById(idPassed) {
    let body = {
      type: "delete",
      id: idPassed,
    };
    //console.log("this is id", idPassed);
    ApiService.adddetails
      .addNotification(body)
      .then((data) => {
        //this.setState({ isLoading: false });
        if (data.status === 200) {
          message.success("Successfully deleted the notification");
          //this.setState({ visible: false });
          this.fetchExistingNotification();
          this.props.history.push("/in-app-notification");
        }
      })
      .catch((error) => {
        //this.setState({ isLoading: false });
        message.error("Something went wrong");
      });
  }

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  setModalVisible = (args) => this.setState({ modalVisible: args });

  renderNotificationContent = (rowContent) => {
    var localContent = rowContent;
    var url = localContent.match(/\bhttps?:\/\/\S+/gi);
    var plainContent = localContent.replace(/(?:https?|ftp):\/\/[\n\S]+/g, "");
    return { text: plainContent, url: url };
  };

  renderLink = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const matches = text.match(urlRegex);
    if (matches) {
      let lastIndex = 0;

      return (
        <span>
          {matches.map((url, index) => {
            const startIndex = text.indexOf(url, lastIndex);
            const endIndex = startIndex + url.length;
            const nonUrlText = text.substring(lastIndex, startIndex);

            lastIndex = endIndex;

            return (
              <React.Fragment key={index}>
                {nonUrlText && <span>{nonUrlText}</span>}
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  {url}
                </a>
              </React.Fragment>
            );
          })}
          {lastIndex < text.length && <span>{text.substring(lastIndex)}</span>}
        </span>
      );
    } else {
      return <span>{text}</span>; // No URL found, render the original text
    }
  };
  Tablecolumns = () => [
    {
      title: "Notification Content",
      dataIndex: "content",
      render: (url, record) => {
        return (
          <span className={record.status == 0 ? "hide" : "show"}>
            {this.renderLink(record["content"])}
          </span>
        );
      },
      width: "50%",
    },

    {
      title: "Date & Time",
      dataIndex: "time",
      render: (url, record) => {
        return (
          <span className={record.status == 0 ? "hide" : "show"}>
            {moment(record["time"]).format("YYYY-MM-DD  HH:mm:ss")}
          </span>
        );
      },
      width: "40%",
    },
    {
      title: "Actions",
      width: "10%",
      render: (url, record) => {
        return (
          console.log("record", record.status),
          (
            <span className={record.status == 0 ? "hide" : "show"}>
              {
                <li id="table-action">
                  <Tooltip title="Edit">
                    <EditOutlined
                      id="table-edit"
                      onClick={() =>
                        this.showModal(
                          record["id"],
                          record["content"],
                          record.org_ids,
                          record["time"],
                          record.roles
                        )
                      }
                    ></EditOutlined>
                  </Tooltip>
                  <div onClick={(e) => this.showDeleteModal(record["id"])}>
                    <Tooltip title="Delete">
                      <DeleteOutlined id="table-delete" />
                    </Tooltip>
                  </div>
                </li>
              }
            </span>
          )
        );
      },
    },
  ];

  render() {
    const { timeFrameData, orgData, roleData } = this.state;
    //console.log(codeData,'cc')
    if (timeFrameData) {
      let orgTag = timeFrameData.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
    }

    let listOrg =
      orgData.length > 0 &&
      orgData.map((items, i) => {
        return (
          <option key={items.org_id} value={items.org_id}>
            {items.name}
          </option>
        );
      }, this);

    let listRole =
      roleData.length > 0 &&
      roleData.map((items, i) => {
        return (
          <option key={items.role_id} value={items.role_id}>
            {items.role_name}
          </option>
        );
      }, this);

    let disabledDate = (current) => {
      // Can not select days before today and today
      return current && current < moment().startOf("day");
    };
    return (
      <>
        <Row>
          <Col span={8} className="flexcenter">
            <h3 className="heading-font">IN APP NOTIFICATION</h3>
          </Col>
          <div className="closecode-row">
            <Link to={{ pathname: "" }}>
              <Button
                className="add-btn"
                id=""
                onClick={() => this.setModalVisible(true)}
              >
                New Notification
              </Button>
            </Link>
          </div>
        </Row>

        <Row>
          <Col span={24}>
            <div class="table-scroll">
              {/* <ReactDragListView {...dragProps}> */}
              <Table
                columns={this.Tablecolumns()}
                //rowKey={record => record.id}
                dataSource={this.state.notificationData}
                pagination={this.state.pagination}
                loading={!this.state.isLoading}
                //  onChange={this.handleTableChange}
              />
              {/* </ReactDragListView> */}
            </div>
          </Col>

          <Modal
            title="Create New Notification"
            centered
            visible={this.state.modalVisible}
            okButtonProps={{ style: { display: "none" } }}
            onCancel={() =>
              this.setState({
                visible: !this.state.modalVisible,
              })
            }
            footer={[
              <div className="ant-modal-body-footer">
                <Button
                  key="back"
                  onClick={() =>
                    this.setState({
                      modalVisible: !this.state.modalVisible,
                      timeframeName: "",
                    })
                  }
                >
                  Return
                </Button>

                <Button
                  htmlType="submit"
                  key="submit"
                  type="primary"
                  onClick={() => this.doSave()}
                >
                  Submit
                </Button>
              </div>,
            ]}
          >
            <div className="row-4">
              <Form ref={this.formRef}>
                <div className="modal_speciality">
                  {/* <Input
                  className={"text-input" + " " + this.state.ErrorInputClass}
                  id=""
                  placeholder="Sub Group"
                  required
                  type="text"
                  value={this.state.timeframeName}
                  onChange={(e) =>
                    this.handleChange(e.target.value, "timeframe")
                  }
                /> */}
                  <div style={{ width: "100%", marginRight: 20 }}>
                    <div style={{ display: "flex", flexdirection: "column" }}>
                      <label style={{ marginRight: 5, color: "red" }}>*</label>
                      <label>Organization</label>
                    </div>
                    <Form.Item
                      name="Organization"
                      className="display"
                      rules={[
                        {
                          required: true,
                          message: "Please select Organization",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        className="text-select"
                        //mode="tags"
                        placeholder="Choose Organization"
                        onChange={(e) => this.handleChange(e, "org_id")}
                      >
                        {listOrg}
                      </Select>
                    </Form.Item>
                  </div>
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", flexdirection: "column" }}>
                      <label style={{ marginRight: 5, color: "red" }}>*</label>
                      <label>Role</label>
                    </div>
                    <Form.Item
                      name="Role"
                      className="display"
                      rules={[
                        {
                          required: true,
                          message: "Please select Role",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        className="text-select"
                        //mode="tags"
                        placeholder="Choose Role"
                        onChange={(e) => this.handleChange(e, "role_id")}
                      >
                        {listRole}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="modal_speciality">
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", flexdirection: "column" }}>
                      <label style={{ marginRight: 5, color: "red" }}>*</label>
                      <label>Notification Content</label>
                    </div>
                    <Form.Item
                      name="notification_content"
                      className="display"
                      rules={[
                        {
                          required: true,
                          message: "Please enter content",
                        },
                        {
                          pattern:
                            /^[\w\s.,!?:;'"/&%$#@()*+-]*((https?:\/\/|www\.)[^\s]*)?$/,
                          message: "Please enter valid content",
                        },
                      ]}
                    >
                      <TextArea
                        id="notification_content"
                        className="text-select"
                        placeholder="Enter notification content"
                        onChange={(event) =>
                          this.handleChange(
                            event.target.value,
                            "notification_content"
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="modal_speciality">
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", flexdirection: "column" }}>
                      <label style={{ marginRight: 5, color: "red" }}>*</label>
                      <label>Trigger Date & Time</label>
                    </div>
                    <Form.Item
                      name="trigger_date"
                      className="display"
                      rules={[
                        {
                          required: true,
                          message: "Please choose trigger date",
                        },
                      ]}
                    >
                      <DatePicker
                        showTime
                        className="text-input"
                        placeholder="Trigger Date & Time"
                        disabledDate={disabledDate}
                        // defaultValue={moment('', dateFormat)}
                        format={dateFormat}
                        value={this.state.formData.dob}
                        onChange={(e) => this.handleChange(e, "trigger_date")}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          </Modal>

          {/* edit close code modal */}
          <Modal
            title="Edit Notification"
            centered
            visible={this.state.visible}
            okButtonProps={{ style: { display: "none" } }}
            onCancel={() =>
              this.setState({
                visible: !this.state.visible,
              })
            }
            footer={[
              <div className="ant-modal-body-footer">
                <Button
                  key="back"
                  onClick={() =>
                    this.setState({
                      visible: !this.state.visible,
                      timeframeName: "",
                    })
                  }
                >
                  Return
                </Button>

                <Button
                  htmlType="submit"
                  key="submit"
                  type="primary"
                  onClick={() => this.updateSave()}
                >
                  Submit
                </Button>
              </div>,
            ]}
          >
            {/* <div className="row-4">
              <div className="modal_speciality">
                <Input
                  className={"text-input" + " " + this.state.ErrorInputClass}
                  id=""
                  placeholder="Time Frame"
                  required
                  type="text"
                  value={this.state.time_frame_name}
                  onChange={(e) =>
                    this.updateChange(e.target.value, "timeframe")
                  }
                />
              </div>
            </div> */}
            <div className="row-4">
              <div className="modal_speciality">
                <div style={{ width: "100%", marginRight: 20 }}>
                  <div style={{ display: "flex", flexdirection: "column" }}>
                    <label style={{ marginRight: 5, color: "red" }}>*</label>
                    <label>Organization</label>
                  </div>
                  <Select
                    mode="multiple"
                    className="text-select"
                    //mode="tags"
                    name="organization"
                    value={this.getRowOrgIdDefaultValue()}
                    placeholder="Choose Organization"
                    onChange={(e) => this.handleChange(e, "update_org_id")}
                  >
                    {listOrg}
                  </Select>
                </div>

                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", flexdirection: "column" }}>
                    <label style={{ marginRight: 5, color: "red" }}>*</label>
                    <label>Role</label>
                  </div>

                  <Select
                    mode="multiple"
                    className="text-select"
                    //mode="tags"
                    value={this.state.rowRoleId}
                    placeholder="Choose Role"
                    onChange={(e) => this.handleChange(e, "update_role_id")}
                  >
                    {listRole}
                  </Select>
                </div>
              </div>
              <div className="modal_speciality">
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", flexdirection: "column" }}>
                    <label style={{ marginRight: 5, color: "red" }}>*</label>
                    <label>Notification Content</label>
                  </div>

                  <TextArea
                    id="notification_content"
                    className="text-select"
                    edit
                    value={this.state.rowNotificationContent}
                    placeholder="Enter notification content"
                    onChange={(event) =>
                      this.handleChange(
                        event.target.value,
                        "update_notification_content"
                      )
                    }
                  />
                </div>
              </div>
              <div className="modal_speciality">
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", flexdirection: "column" }}>
                    <label style={{ marginRight: 5, color: "red" }}>*</label>
                    <label>Trigger Date & Time</label>
                  </div>

                  <DatePicker
                    showTime
                    className="text-input"
                    placeholder="Trigger Date & Time"
                    disabledDate={disabledDate}
                    //defaultValue={moment("", dateFormat)}
                    defaultValue={moment(this.state.rowTriggerDate)}
                    format={dateFormat}
                    //value={this.state.rowTriggerDate}
                    onChange={(e) =>
                      this.handleChange(e, "update_trigger_date")
                    }
                  />
                </div>
              </div>
            </div>
          </Modal>
        </Row>
      </>
    );
  }
}
// sprint35
