import React, { Component, lazy, Suspense } from "react";
import {
  Row,
  Col,
  Avatar,
  Input,
  Menu,
  Modal,
  Dropdown,
  Checkbox,
  Button,
  Divider,
  Tooltip,
  message,
  Select,
  Table,
  Popconfirm,
} from "antd";
import {
  UserOutlined,
  BellFilled,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import { Link, NavLink, Redirect } from "react-router-dom";
import moment from "moment";
import "./StaffResponder.css";
import { Container } from "reactstrap";
import CONFIG from "../../../services/config";
import UserContext from "../../../UserContext";
import ApiService from "../../../services/api-services";
const { Search } = Input;
var searchItem = [];

export default class dashboard extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    var user_id,
      type,
      formData = {};
    if (this.props.location.state != undefined) {
      formData = {
        user_id: this.props.location.state.user_id,
        type: this.props.location.state.type,
        first_name: this.props.location.state.first_name,
      };
    }
    this.state = {
      formData: formData,
      adminFullyLoaded: false,
      isFetchSpeciality: false,
      isLoading: false,
      searchText: "",
      searchedColumn: "",
      adminData: [],
      formType: type,
      // formData: {},
      formData: {
        first_name: "",
        last_name: "",
        email: "",
        user_id: "",
        org_id: "",
      },
    };
  }

  editBtnClick = (
    user_id,
    first_name,
    last_name,
    email,
    password,
    org_id,
    organization,
    dob,
    role,
    mobile,
    experience,
    hospital,
    bio,
    image_url,
    role_id,
    type
  ) => {
    this.props.history.push({
      pathname: "/add-staffResponder",
      state: {
        user_id: user_id,
        first_name: first_name,
        last_name: last_name,
        email: email,
        password: password,
        org_id: org_id,
        organization: organization,
        dob: dob,
        role: role,
        role_id: role_id,
        mobile: mobile,
        experience: experience,
        hospital: hospital,
        bio: bio,
        image_url: image_url,
        type,
      },
    });
  };
  componentDidMount() {
    this.fetchStaffRes();
  }

  // handleReset = clearFilters => {
  //     clearFilters();
  //     this.setState({ searchText: '' });
  //  };

  //  getColumnSearchProps = (args, field) => {
  //         const dataIndex = args == null ? '' : args
  //         return ({
  //             filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
  //             <div style={{ padding: 8 }}>
  //                 <Input
  //                 ref={node => {
  //                     this.searchInput = node;
  //                 }}
  //                 placeholder={`Search ${dataIndex}`}
  //                 value={selectedKeys[0]}
  //                 onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
  //                 onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
  //                 style={{ width: 188, marginBottom: 8, display: 'block' }}
  //                 />
  //                 <Button
  //                 type="primary"
  //                 onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
  //                 icon={<SearchOutlined />}
  //                 size="small"
  //                 style={{ width: 90, marginRight: 8 }}
  //                 >
  //                 Search
  //                 </Button>
  //                 <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
  //                 Reset
  //                 </Button>
  //             </div>
  //             ),
  //             filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  //             onFilter: (value, record) =>
  //             record[dataIndex]
  //             .toString()
  //             .toLowerCase()
  //             .includes(value.toLowerCase()),
  //             onFilterDropdownVisibleChange: visible => {
  //                 if (visible) {
  //                     setTimeout(() => this.searchInput.select());
  //                 }
  //             },
  //         })
  //  };
  //  handleSearch = (selectedKeys, confirm, dataIndex) => {
  //     confirm();
  //     this.setState({
  //         searchText: selectedKeys[0],
  //         searchedColumn: dataIndex,
  //     });
  // };

  fetchStaffRes = () => {
    let body = {
      org_id: "",
    };
    ApiService.adddetails
      .staffResList(body)
      .then((data) => {
        this.setState({ isFetchSpeciality: true, isLoading: true });
        if (data.status === 200) {
          this.setState({ isLoading: true });
          let resData = Array.from(data.data);
          let newData = [];

          for (let i = 0; i < resData.length; i++) {
            let date = moment(resData[i]["createdAt"]);
            let showDate = date.format("MM-DD-YYYY");
            newData.push({
              user_id: resData[i]["user_id"],
              first_name: resData[i]["first_name"],
              last_name: resData[i]["last_name"],
              name: resData[i]["first_name"] + " " + resData[i]["last_name"],
              email: resData[i]["email"],
              mobile: resData[i]["mobile"],
              organization: resData[i]["organization.name"],
              org_id: resData[i]["organization.org_id"],
              createdAt: showDate,
              password: resData[i]["password"],
              hospital_name: resData[i]["hospital_name"],
              bio: resData[i]["bio"],
              experience: resData[i]["experience"],
              image_url:
                resData[i]["image_url"] === null ? "" : resData[i]["image_url"],
              role: resData[i]["role.role_name"],
              role_id: resData[i]["role.role_id"],
              dob:
                resData[i]["dob"] === "undefined-undefined-undefined"
                  ? ""
                  : resData[i]["dob"] === null
                  ? ""
                  : resData[i]["dob"],
            });
          }
          this.setState({ adminFullyLoaded: true, staffData: newData });
        }
      })
      .catch((error) => {
        this.setState({ isFetchSpeciality: false });
      });
  };

  Tablecolumns = () => [
    {
      title: "Name",
      dataIndex: "name",
      render: (url, record) => {
        return (
          <div
            className="table-doctor"
            onClick={() =>
              this.editBtnClick(
                record["user_id"],
                record["first_name"],
                record["last_name"],
                record["email"],
                record["password"],
                record["org_id"],
                record["organization"],
                record["dob"],
                record["role"],
                record["mobile"],
                record["experience"],
                record["hospital_name"],
                record["bio"],
                record["image_url"],
                record["role_id"],
                "edit"
              )
            }
          >
            <Avatar
              shape="square"
              size="large"
              icon={
                record.image_url !== "" ? (
                  record.image_url !== null ? (
                    <img src={record.image_url}></img>
                  ) : (
                    <UserOutlined />
                  )
                ) : (
                  <UserOutlined />
                )
              }
              style={{ borderRadius: "50%", position: "relative" }}
            />

            <span style={{ padding: 10 }}>{record["name"]}</span>
          </div>
        );
      },

      width: "25%",
    },
    // {
    //     title: 'Organization',
    //     dataIndex: 'organization',
    //     render: (url, record) => {
    //         return <span>{record["organization"]}</span>;
    //     },
    //     //...this.getColumnSearchProps('organization'),
    //     width: '25%',

    // },
    {
      title: "Mobile",
      dataIndex: "mobile",
      render: (url, record) => {
        return <span>{record["mobile"]}</span>;
      },
      width: "25%",
    },
    {
      title: "Email Id",
      dataIndex: "email",
      render: (url, record) => {
        return <span>{record["email"]}</span>;
      },
      width: "25%",
    },
    {
      title: "Added Date",
      dataIndex: "createdAt",
      render: (url, record) => {
        return <span>{record["createdAt"]}</span>;
      },
      // sorter: 'true',
      width: "25%",
    },
    {
      title: "Action",
      dataIndex: [],
      width: "20%",
      render: (url, record) => {
        return (
          <span>
            {
              <li id="table-action">
                <Tooltip title="Edit">
                  <EditOutlined
                    id="table-edit"
                    onClick={() =>
                      this.editBtnClick(
                        record["user_id"],
                        record["first_name"],
                        record["last_name"],
                        record["email"],
                        record["password"],
                        record["org_id"],
                        record["organization"],
                        record["dob"],
                        record["role"],
                        record["mobile"],
                        record["experience"],
                        record["hospital_name"],
                        record["bio"],
                        record["image_url"],
                        record["role_id"],
                        "edit"
                      )
                    }
                  ></EditOutlined>
                </Tooltip>
              </li>
            }
          </span>
        );
      },
    },
  ];

  render() {
    const { staffData } = this.state;
    //  let orgTag = staffData.sort(function (a, b) {
    //      return a.name.localeCompare(b.name);

    //  });
    return (
      <>
        <Row>
          <Col span={8} className="flexcenter">
            <h3 className="heading-font">STAFF RESPONDER</h3>
          </Col>
        </Row>
        <Row>
          <div className="sub-bar-other">
            <Search
              placeholder="Search Staff Responder"
              onSearch={(value) => console.log(value)}
              onKeyUp={(value) => {
                // console.log(value.target.value)
                ApiService.general
                  .search_user({
                    user_type: "staff_responder",
                    key: value.target.value,
                    org_id: "",
                  })
                  .then((res) => {
                    console.log(res);
                    if (res.data === null && value.target.value === "") {
                      this.props.history.push("/StaffResponder");
                    } else {
                      let resData = Array.from(res.data);
                      let newData = [];
                      for (let i = 0; i < resData.length; i++) {
                        let date = moment(resData[i]["createdAt"]);
                        let showDate = date.format("MM-DD-YYYY");
                        newData.push({
                          user_id: resData[i]["user_id"],
                          first_name: resData[i]["first_name"],
                          last_name: resData[i]["last_name"],
                          name:
                            resData[i]["first_name"] +
                            " " +
                            resData[i]["last_name"],
                          email: resData[i]["email"],
                          mobile: resData[i]["mobile"],
                          org_id: resData[i]["organization.org_id"],
                          createdAt: showDate,
                          password: resData[i]["password"],
                          image_url:
                            resData[i]["image_url"] === null
                              ? ""
                              : resData[i]["image_url"],
                          role_id: resData[i]["role_id"],
                          org_id: resData[i]["org_id"],
                          hospital_name: resData[i]["hospital_name"],
                          bio: resData[i]["bio"],
                          dob: resData[i]["dob"],
                          experience: resData[i]["experience"],
                        });
                      }
                      this.setState({ staffData: newData, isLoading: true });
                      this.Tablecolumns();
                    }
                    // this.setView(res.data)
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            />
            <Link to={{ pathname: "/add-staffResponder" }}>
              <Button
                className="adduser-btn"
                type="primary"
                id=""
                loading={!this.state.isLoading}
              >
                Add Staff
              </Button>
            </Link>
          </div>
        </Row>

        <Row>
          <Col span={24}>
            <div class="table-scroll">
              <Table
                columns={this.Tablecolumns()}
                dataSource={this.state.staffData}
                pagination={this.state.pagination}
                loading={!this.state.isLoading}
                //  onChange={this.handleTableChange}
              />
            </div>
          </Col>
        </Row>
      </>
    );
  }
}
