import React, { useState, useEffect, useCallback } from "react";
import { Form, Select } from "antd";
import ApiService from "../../../../services/api-services";

const Option = Select.Option;

export default ({ handleChange, data }) => {
  const [groupData, setGroupData] = useState(data);
  const [inititalload, setInitialload] = useState(false);
  const [groupArrayFields, setgroupArrayFields] = useState([]);
  const [currentGroupData, setCurrentGroupData] = useState([]);
  let groupTag = groupData.sort(function (a, b) {
    console.log(groupData, "data");
    return a.name.localeCompare(b.name);
  });

  useEffect(() => {
    let body = {
      type: "list_user",
    };
    ApiService.adddetails.subGroups(body).then((data) => {
      if (data.status === 200) {
        console.log(data, "hero");
        const newData = data.data.filter((item) => {
          if (item.name && item.id) {
            if (item.status !== 0) {
              return item;
            }
          }
        });
        setCurrentGroupData(newData);

        // RequestComponentData();
        // setinitialFormLoad((d) => !d);
        //loading();
      }
    });
    console.log("temp", currentGroupData);
  }, []);
  return (
    sessionStorage.getItem("rolename") === "Super Admin" && (
      <Form.Item
        name="medical_group"
        label="Medical Group"
        className="display org_select"
        rules={[
          {
            required: true,
            message: "Please select the medical group",
          },
        ]}
        style={{ width: "100%", marginRight: "auto" }}
      >
        <Select
          showSearch
          //labelInValue
          placeholder="Select a medical group"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          style={{
            width: "100%",
          }}
        >
          {console.log("current group data", currentGroupData)}
          {currentGroupData.length &&
            currentGroupData.map((item, index) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              );
            })}
        </Select>
      </Form.Item>
    )
  );
};
// sprint35
