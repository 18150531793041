import React, { Component, lazy, Suspense } from 'react';
import {
    Row, Col, Avatar, Input, Menu, Modal,
    Dropdown, Checkbox, Button, Divider, Tooltip, message,
    Select, Table, Popconfirm,

} from 'antd';
import {
    UserOutlined, BellFilled, EditOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import { Link, NavLink, Redirect } from 'react-router-dom';
import  moment from 'moment';
import './AdminManagement.css'
import { Container } from 'reactstrap';
import CONFIG from "../../../services/config"
import UserContext from '../../../UserContext';
import ApiService from '../../../services/api-services'; 
const { Search } = Input;
var searchItem = [];

export default class dashboard extends Component {
    static contextType = UserContext

    constructor(props) {
        super(props);
        var user_id, type, formData = {};
        if (this.props.location.state != undefined) {
            formData = {
            user_id : this.props.location.state.user_id,
            type :this.props.location.state.type,
            first_name: this.props.location.state.first_name,
            }
        }
        this.state = {

            formData: formData,
            adminFullyLoaded: false,
            isFetchSpeciality: false,
            isLoading: false,
            adminData: [],
                formType: type,
                // formData: {},
                formData: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    user_id: '',
                    org_id: '',
                }
            }
    }



    editBtnClick = (user_id,first_name,last_name,email,password,org_id,organization,type) => {
        console.log(user_id)
    // var bodyFormData = new FormData();
    // let body = {
    //     "user_id": this.state.formData.user_id,
    // }

    // ApiService.adddetails.adminlist(body).then(data => {
    //     console.log(data,'11')
    //     if (data.status === 200) {
           
    //        this.setState({ SpecialityFullyLoaded: true, adminData: data.data })
    //     }       
    // })
  
        this.props.history.push({
            pathname: '/add-admin',
            state: {
                user_id: user_id,
                first_name: first_name,
                last_name: last_name,
                email: email,
                password: password,
                org_id: org_id,
                organization: organization,
                type
            }
        })
        
    }
   
    // signOut() {
    //     const { setLoggin } = this.context
    //     setLoggin('false');
    //     this.props.history.push('/login')
    //     sessionStorage.setItem('isLoggedIn', false);
    // }
    componentDidMount() {
        ApiService.adddetails.adminlist().then(data => {
            console.log(data,'res')
            this.setState({ isFetchSpeciality: true, isLoading: true })
            if (data.status === 200) {
                this.setState({isLoading: true})
                let resData = Array.from(data.data);
                let newData = []
                console.log(resData)

                for (let i = 0; i < resData.length; i++) {
                    //console.log(resData[i]['status'])
                    let status = resData[i]['status']
                    console.log(status)
                    if(status===0) {
                        status= 'Inactive' 
                    } else {
                        status= 'Active'
                    }
                //     newData.push({
                //         user_id: resData[i]['user_id'],
                //         first_name: resData[i]['first_name'],
                //         last_name: resData[i]['last_name'],
                //         email: resData[i]['email'],
                //         password: resData[i]['password'],
                //        // updatedAt: resData[i]['updatedAt'],
                //     })
                // }
                let date = moment(resData[i]['createdAt'])
                    let showDate = date.format('MM-DD-YYYY'); 
                    newData.push({
                        user_id: resData[i]['user_id'],
                        first_name:resData[i]['first_name'],
                        last_name:resData[i]['last_name'],
                        name: resData[i]['first_name'] + ' ' + resData[i]['last_name'],
                        email: resData[i]['email'],
                        organization: resData[i]['organization.name'],
                        org_id: resData[i]['organization.org_id'],
                        createdAt: showDate,
                        password: resData[i]['password'],
                        status: status,
        
                    })
                   
                }
                this.setState({ adminFullyLoaded: true, adminData: newData })
            }
        })
            .catch(error => {
                this.setState({ isFetchSpeciality: false })
            });


    }

    Tablecolumns = () => [

        {
            title: 'Admin Name',
            dataIndex: 'name',
            render: (url, record) => {
                return <span>{record["name"]}</span>;
              },
            width: '25%', 

        }, 
        {
            title: 'Organization',
            dataIndex: 'organization',
            render: (url, record) => {
                return <span>{record["organization"]}</span>;
              },
            width: '25%', 

        },
        {
            title: 'Email Id',
            dataIndex: 'email',
            render: (url, record) => {
                return <span>{record["email"]}</span>;
              }
,
            width: '25%',

        },
        {
            title: 'Added Date',
            dataIndex: 'createdAt',
            render: (url, record) => {
                return <span>{record["createdAt"]}</span>;
              },
           // sorter: 'true',
            width: '25%',

        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (url, record) => {
                return <span>{record["status"]}</span>;
              },
           // sorter: 'true',
            width: '25%',
        },
        {
            title: 'Action',
            dataIndex: [],
            width: '20%',
            // render: (url, record) => {
            //     return <span>{<li id='table-action'><Tooltip title="Edit"><EditOutlined id='table-edit'
            //     onClick={() => this.editBtnClick(record['user_id'],record['first_name'],record['last_name'],record['email'],record['password'],record['org_id'],record['organization'],'edit')}
            //     >
            //         </EditOutlined>
            //         </Tooltip>
            //         </li>
            //         }
            //         </span>;
            //   }

        },

    ];

      
    render()
    {
         const { adminData } = this.state;
         let orgTag = adminData.sort(function (a, b) {
             return a.name.localeCompare(b.name);

         });
        return (
            <>
           <Row>
            <Col span={8} className="flexcenter">
                <h3 className='heading-font'>ADMIN MANAGEMENT</h3>
            </Col>
            {/* <Col span={16}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                    <ul className="closecodeheaderMenu">
                        <li className="bellNotification">
                            <BellFilled style={{ color: '#0252a1' }} />
                        </li>
                        <li className="profile_button">

                            <div className="headerPorifileDiv">
                                <Avatar shape="square" size="large" icon={<UserOutlined />} style={{ borderRadius: '30%' }} />
                                <span className="proifle_name">
                                    Welcome Sally Rivera
                                </span>
                            </div>
                        </li>
                        <li className="logoutbutton">
                            <Button className="buttonOutline" ghost size="default" onClick={() => this.signOut()}>
                                Logout
                            </Button>
                        </li>
                    </ul>
                </div>
            </Col> */}
            </Row>  
            <Row>
            <div className="sub-bar">
                <Search
                     placeholder="Search Admin"
                     onSearch={value => console.log(value)}
                     onKeyUp={
                        value => {
                        // console.log(value.target.value)
                        ApiService.general.search_admin({ search_key: value.target.value })
                            .then(res => {
                                console.log(res)
                                if(res.data === null && value.target.value === '') {
                                    this.props.history.push('/organization-list')
                                    console.log('hiias')
                                } else {
                         let resData = Array.from(res.data);
                                        let newData = []
                                        for (let i = 0; i < resData.length; i++) {
                                            let status = resData[i]['status']
                                            console.log(status)
                                            if(status===0) {
                                                status= 'Inactive' 
                                            } else {
                                                status= 'Active'
                                            }
                                            let date = moment(resData[i]['createdAt'])
                                            let showDate = date.format('MM-DD-YYYY'); 
                                            newData.push({
                                                user_id: resData[i]['user_id'],
                                                first_name:resData[i]['first_name'],
                                                last_name:resData[i]['last_name'],
                                                name: resData[i]['first_name'] + ' ' + resData[i]['last_name'],
                                                email: resData[i]['email'],
                                                organization: resData[i]['organization.name'],
                                                org_id: resData[i]['organization.org_id'],
                                                createdAt: showDate,
                                                password: resData[i]['password'],
                                                status: status,
                                
                                            })

                                         }
                                    this.setState({adminData: newData, isLoading: true})
                                    this.Tablecolumns()
                                }
                               // this.setView(res.data)
                            })
                            .catch(err => {
                                console.log(err)
                            })
                    }
                }
                />
                 <Link to={{ pathname: '/add-admin' }}>
                                        <Button className='adduser-btn' type="primary" id=''  loading={!this.state.isLoading }  >
                                        Add Admin
                                        </Button>
                                    </Link>
                                    {/* <Link to={{ pathname: '/admin' }}>
                                        <Button className='btn-type' id='' loading={!this.state.isLoading}  >
                                            Back
                                </Button>
                                    </Link> */}
                                   
            </div>
           {/* <button type="primary" className="add-btn">Add Close Code</button>*/}
            </Row>

            <Row>
                <Col span={24}>
                    <div class="table-scroll">
                        <Table
                            columns={this.Tablecolumns()}
                            //rowKey={record => record.id} not my
                            dataSource={this.state.adminData}  
                            pagination={this.state.pagination}
                            loading={!this.state.isLoading}
                          //  onChange={this.handleTableChange}
                        />

                    </div>
                    </Col>
                    </Row>
        </>
        );
    }
 }