import React, { Component, lazy, Suspense } from "react";
import {
  Row,
  Col,
  Spin,
  Input,
  Tooltip,
  Button,
  message,
  Modal,
  Popconfirm,
  Table,
  Avatar,
  Switch,
} from "antd";
import "antd/dist/antd.css";
import {
  EditOutlined,
  DeleteOutlined,
  UserOutlined,
  SearchOutlined,
  SortAscendingOutlined,
  SlidersOutlined,
  BellFilled,
  AlertFilled,
  CloudDownloadOutlined,
} from "@ant-design/icons";
import moment from "moment";
import "./admin.css";
import { Link, NavLink, Redirect } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ApiService from "../../services/api-services";
// import { isLoggedIn } from '../../helpers/utility';
import { Container } from "reactstrap";
// import $, { $window } from "jquery";
import CONFIG from "../../services/config";
import BottomScrollListener from "react-bottom-scroll-listener";
import UserContext from "../../UserContext";

var items = [];
const { Search } = Input;
const { confirm } = Modal;
const data = [];
const Tablecolumns = [];
var searchItem = [];
var titles = new Map();

export default class OrganizationList extends Component {
  static contextType = UserContext;

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );
  constructor(props) {
    super(props);
    var org_id,
      type,
      name,
      address,
      org_type,
      primary_contact,
      secondary_contact,
      formData = {};
    if (this.props.location.state != undefined) {
      formData = {
        org_id: this.props.location.state.org_id,
      };
    }
    this.state = {
      token: sessionStorage.getItem("token"),
      page: 1,
      isSearching: false,
      tracks: [],
      SpecialityFullyLoaded: false,
      orgData: [],
      viewData: [],
      hasMoreItems: true,
      OrgSuccessFullyLoaded: false,
      isLoading: false,
      rowLoader: false,
      isDelLoading: false,
      loadingFirstTime: true,
      isFocusing: false,
      paginationLoading: false,
      items: [],
      formType: type,
      isZipLogoLoading: false,
      formData: {
        org_id: "",
        name: "",
        address: "",
        org_type: [],
        primary_contact: "",
        secondary_contact: "",
      },
    };
  }

  componentDidMount() {
    if (this.state.token === null || this.state.token === "") {
      this.props.history.push("/login");
      window.location.reload(true);
    }
    this.getOrganizationList();
    const isLoggedIn = sessionStorage.getItem("isLoggedIn");

    if (isLoggedIn === "false") {
      this.props.history.push("/login");
    }
    sessionStorage.removeItem("orgProfile"); //for uploading image
  }

  editBtnClick(
    org_id,
    org_npi,
    name,
    address,
    org_type,
    primary_contact,
    secondary_contact,
    speciality,
    image_url,
    address_obj,
    type
  ) {
    if (type === "view") {
      var bodyFormData = new FormData();
      // sessionStorage.getItem("rolename") === "Admin" && (
      //     this.setState({org_id: sessionStorage.getItem("org_id")})
      // )
      let body = {
        org_id: this.state.formData.org_id,
      };

      ApiService.adddetails.viewOrg(body).then((data) => {
        //  console.log(data.data)
        if (data.status === 200) {
          this.setState({ SpecialityFullyLoaded: true, viewData: data.data });
        }
      });
    }
    this.props.history.push({
      pathname: "/add-orgn",
      state: {
        org_id: org_id,
        org_npi: org_npi,
        name: name,
        address: address,
        org_type: org_type,
        primary_contact: primary_contact,
        secondary_contact: secondary_contact,
        speciality: speciality,
        image_url: image_url,
        address_obj: address_obj,
        type,
      },
    });
  }

  showDeleteConfirm(org_id, status) {
    var that = this;
    // let draftId = this.state.formData._id;
    confirm({
      className: "popup-margin ",
      title:
        "Are you sure want to" +
        " " +
        `${status === 1 ? "Deactivate" : "Activate"}` +
        " " +
        "this Organization?",
      //   content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        that.deleteOrganization(org_id, status);
      },
      onCancel() {},
    });
  }

  deleteOrganization(org_id, status) {
    let body = {
      org_id: org_id,
      status: status === 0 ? 1 : 0,
    };

    ApiService.adddetails
      .deleteOrg(body)
      .then((data) => {
        this.setState({ isDelLoading: true });
        this.setState({ rowLoader: true });

        if (data.status === 200) {
          //message.success('Successfully deleted');
          // this.setState({rowLoader: false})

          this.getOrganizationList();
          this.props.history.push("/organization-list");
        }
        // else if (data.statusCode === 400) {
        //     message.error('Category already exist');
        // }
        // else if (data.status === 401) {
        //     this.props.history.push('/login')
        // }
      })
      .catch((error) => {
        this.setState({ isDelLoading: false });

        // if (error.data.statusCode === 400) {
        //     message.error('Category already exist');
        // }
      });
  }

  Tablecolumns = () => [
    {
      title: "Name",
      dataIndex: [],
      width: "25%",
      render: (url, record) => {
        return (
          <div className={record.status === 0 ? "hide" : "show"}>
            <div class="table-doctor">
              <div>
                <Avatar
                  shape="square"
                  size="large"
                  icon={
                    record.image_url !== "" && record.image_url !== null ? (
                      <img
                        style={{ borderRadius: "50%" }}
                        src={record.image_url}
                      ></img>
                    ) : (
                      <UserOutlined />
                    )
                  }
                  style={{ borderRadius: "50%", position: "relative" }}
                />
              </div>
              <div
                id="table-name"
                style={{
                  cursor: "pointer",
                  wordBreak: "break-word",
                  wordSpacing: "4px",
                }}
                onClick={
                  record.status === 1
                    ? () =>
                        this.editBtnClick(
                          record.org_id,
                          record.org_npi,
                          record.name,
                          record.address,
                          record.org_type,
                          record.primary_contact,
                          record.secondary_contact,
                          record.speciality,
                          record.image_url,
                          record.address_obj,
                          "edit"
                        )
                    : null
                }
              >
                {record["name"]}
              </div>
            </div>
          </div>
        );
      },
    },

    {
      title: "Type",
      dataIndex: [],
      width: "20%",
      render: (url, record) => {
        return (
          <span className={record.status == 0 ? "hide" : "show"}>
            {record["type"]}
          </span>
        );
      },
    },

    {
      title: "Primary Contact",
      dataIndex: [],
      width: "20%",
      render: (url, record) => {
        return (
          <span className={record.status == 0 ? "hide" : "show"}>
            {record["primary_contact"]}
          </span>
        );
      },
    },

    {
      title: "Added Date",
      dataIndex: [],
      width: "20%",
      render: (url, record) => {
        return (
          <span className={record.status == 0 ? "hide" : "show"}>
            {record["createdAt"]}
          </span>
        );
      },
    },

    {
      title: "Address",
      dataIndex: [],
      width: "20%",
      render: (url, record) => {
        return (
          <span className={record.status == 0 ? "hide" : "show"}>
            {record["address"]}
          </span>
        );
      },
    },

    {
      title: "Action",
      dataIndex: [],
      width: "20%",
      render: (url, record) => {
        return (
          <span>
            {
              <li id="table-action">
                {record.status === 1 ? (
                  <Tooltip title="Edit">
                    <EditOutlined
                      id="table-edit"
                      style={{ margin: 14 }}
                      onClick={() =>
                        this.editBtnClick(
                          record.org_id,
                          record.org_npi,
                          record.name,
                          record.address,
                          record.org_type,
                          record.primary_contact,
                          record.secondary_contact,
                          record.speciality,
                          record.image_url,
                          record.address_obj,
                          "edit"
                        )
                      }
                    ></EditOutlined>
                  </Tooltip>
                ) : (
                  <EditOutlined style={{ margin: 14, opacity: 0.5 }} />
                )}

                <div
                  onClick={() =>
                    this.showDeleteConfirm(record.org_id, record.status)
                  }
                >
                  <Link
                    to={{
                      pathname: "organization-list",
                      state: { id: record.org_id, type: "delete" },
                    }}
                  >
                    <Switch
                      style={{ margin: 10 }}
                      loading={this.state.rowLoader}
                      checked={record.status === 0 ? false : true}
                    >
                      {}
                    </Switch>
                  </Link>{" "}
                </div>
              </li>
            }
          </span>
        );
      },
    },
  ];

  getOrganizationList() {
    if (!this.state.isFocusing) {
      var self = this;
      if (this.state.loadingFirstTime) {
        this.setState({ OrgSuccessFullyLoaded: true });
      } else {
        this.setState({ paginationLoading: true });
      }

      ApiService.adddetails
        .getOrgList(this.state.page.toString(), "48", "")
        .then((data) => {
          this.setState({ rowLoader: false });

          if (data.data != null) {
            let resData = Array.from(data.data);
            let newData = [];
            for (let i = 0; i < resData.length; i++) {
              let date = moment(resData[i]["createdAt"]);
              let showDate = date.format("DD-MM-YYYY");
              newData.push({
                name: resData[i]["name"],
                type: resData[i]["organization_type.org_type"],
                primary_contact: resData[i]["primary_contact"],
                image_url: resData[i]["image_url"],
                createdAt: showDate,
                address: resData[i]["address"],
                secondary_contact: resData[i]["secondary_contact"],
                speciality: resData[i]["speciality"],
                org_id: resData[i]["org_id"],
                org_type: resData[i]["org_type"],
                address_obj: resData[i]["address_obj"],
                status: resData[i]["status"],
              });
            }
            this.setState({ orgData: newData, isLoading: true });
          } else {
            if (data.status === 401) {
              this.props.history.push("/login");
            }
            self.setState({
              hasMoreItems: false,
              paginationLoading: false,
            });
          }
          console.log(this.state.orgData, "orgData");
        })
        .catch((error) => {
          self.setState({
            hasMoreItems: false,
          });
          this.setState({
            isLoading: false,
            paginationLoading: false,
            OrgSuccessFullyLoaded: false,
          });
        });
    }
  }
  /* sign out */
  // signOut() {
  //     const { setLoggin } = this.context
  //     setLoggin('false')
  //     this.props.history.push('/login')
  //     sessionStorage.setItem('isLoggedIn', false);
  // }

  render() {
    const { orgData } = this.state;
    let orgTag = orgData.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });

    return (
      <>
        <Row>
          <Col span={8} className="flexcenter">
            <h3 className="heading-font">ORGANIZATION LIST</h3>
          </Col>
          {/* <Col span={16}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                    <ul className="closecodeheaderMenu">
                        <li className="bellNotification">
                            <BellFilled style={{ color: '#0252a1' }} />
                        </li>
                        <li className="profile_button">

                            <div className="headerPorifileDiv">
                                <Avatar shape="square" size="large" icon={<UserOutlined />} style={{ borderRadius: '30%' }} />
                                <span className="proifle_name">
                                    Welcome Sally Rivera
                                </span>
                            </div>
                        </li>
                        <li className="logoutbutton">
                            <Button className="buttonOutline" ghost size="default" onClick={() => this.signOut()}>
                                Logout
                            </Button>
                        </li>
                    </ul>
                </div>
            </Col> */}
        </Row>
        <Row>
          <div className="sub-bar-other">
            <Search
              placeholder="Search Organization"
              onSearch={(value) => console.log(value)}
              onKeyUp={(value) => {
                // console.log(value.target.value)
                ApiService.general
                  .search_org({ name: value.target.value })
                  .then((res) => {
                    console.log(res);
                    if (res.data === null && value.target.value === "") {
                      this.props.history.push("/organization-list");
                      console.log("hiias");
                    } else {
                      let resData = Array.from(res.data);
                      let newData = [];
                      for (let i = 0; i < resData.length; i++) {
                        let date = moment(resData[i]["createdAt"]);
                        let showDate = date.format("MM-DD-YYYY");
                        newData.push({
                          name: resData[i]["name"],
                          type: resData[i]["organization_type.org_type"],
                          primary_contact: resData[i]["primary_contact"],
                          createdAt: showDate,
                          address: resData[i]["address"],
                          primary_contact: resData[i]["primary_contact"],
                          secondary_contact: resData[i]["secondary_contact"],
                          speciality: resData[i]["speciality"],
                          org_id: resData[i]["org_id"],
                          org_type: resData[i]["org_type"],
                          image_url: resData[i]["image_url"],
                          address_obj: resData[i]["address_obj"],
                          status: resData[i]["status"],
                        });
                      }
                      this.setState({ orgData: newData, isLoading: true });
                      this.Tablecolumns();
                    }
                    // this.setView(res.data)
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            />
            <Link to={{ pathname: "/add-orgn" }}>
              <Button
                className="adduser-btn"
                id=""
                type="primary"
                loading={!this.state.isLoading}
              >
                Add Organization
              </Button>
            </Link>
            {/* <Link to={{ pathname: '/admin' }}>
                                        <Button className='btn-type' id='' loading={!this.state.isLoading}  >
                                            Back
                                </Button>
                                    </Link> */}
            {/* search box for doctors*/}
          </div>
        </Row>

        <div>
          <Row>
            <Col span={24}>
              <div class="fill-table">
                {
                  <Table
                    columns={this.Tablecolumns()}
                    //rowKey={record => record.id} not my
                    dataSource={this.state.orgData} //new assigned from api
                    pagination={this.state.pagination}
                    loading={!this.state.isLoading}
                  />
                }
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
