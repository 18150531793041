import React, { Component, useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Modal,
  Button,
  message,
  Table,
  Form,
  Tooltip,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import dayjs from "dayjs";
import "antd/dist/antd.css";
import "./UserDetails.css";
import { Link } from "react-router-dom";
import UserContext from "../../../UserContext";
import ApiService from "../../../services/api-services";
import TextArea from "antd/lib/input/TextArea";
//import Form from 'antd/lib/form/Form';
const FormItem = Form.Item;
const { Search } = Input;
var searchItem = [];
// const Add = 'Add'

export default () => {
  const [totalPages, setTotalPages] = useState(1);
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  // constructor(props) {
  //   super(props);
  //   var formData = {};
  //   this.state = {
  //     token: sessionStorage.getItem("token"),
  //     formData: formData,
  //     closecodeFullyLoaded: false,
  //     isFetchSpeciality: false,
  //     isLoading: false,
  //     counter: 1,
  //     totalPages: 1,
  //   };
  // }

  // componentDidMount() {
  //   if (this.state.token === null || this.state.token === "") {
  //     this.props.history.push("/login");
  //     window.location.reload(true);
  //   }
  //   this.fetchCloseCode();
  // }

  // fetchCloseCode = () => {
  //   let body = {
  //     page: this.state.counter,
  //     limit: 10,
  //   };

  //   ApiService.adddetails.userLoginDetails(body).then((data) => {
  //     //console.log(data.data["page_details"]["total_pages"], "user");
  //     this.setState({ isFetchSpeciality: true });
  //     if (data.status === 200) {
  //       let resData = Array.from(data.data["users"]);
  //       let newData = [];

  //       for (let i = 0; i < resData.length; i++) {
  //         //console.log(resData[i], "user");
  //         newData.push({
  //           id: resData[i]["user_id"],
  //           type: resData[i]["login_details"]
  //             ? resData[i]["login_details"]["device_type"]
  //             : "",
  //           time: resData[i]["login_details"]
  //             ? resData[i]["login_details"]["login_time"]
  //             : "",
  //           first_name: resData[i]["first_name"],
  //           last_name: resData[i]["last_name"],
  //           name: resData[i]["first_name"] + resData[i]["last_name"],
  //           is_pwd_update: resData[i]["is_password_update"],
  //         });
  //       }
  //       this.setState({
  //         closecodeFullyLoaded: true,
  //         codeData: newData,
  //         isLoading: true,
  //         totalPages: data.data["page_details"]["total_pages"],
  //       });
  //     }
  //   });
  //   //   .catch((error) => {
  //   //     this.setState({ isFetchSpeciality: false });
  //   //   });
  // };

  // handleTableChange = () => {
  //   this.setState((prevState, props) => ({
  //     counter: prevState.counter + 1,
  //   }));
  //   this.fetchCloseCode();
  // };

  const Tablecolumns = [
    {
      title: "Users",
      dataIndex: "name",
      //render: (close_code) => <>{close_code}</>,
      width: "35%",
    },
    {
      title: "Logged Date/Time",
      dataIndex: "time",
      // sorter: (a, b) => a.time - b.time,
      width: "25%",
    },
    {
      title: "Device Type",
      dataIndex: "type",
      width: "25%",
    },
    {
      title: "Verified",
      dataIndex: "is_pwd_update",
      width: "25%",
    },
  ];

  useEffect(() => {
    fetchRecords(1);
  }, []);

  const fetchRecords = (page) => {
    setisLoading(true);
    let body = {
      page: page,
      limit: 10,
    };
    ApiService.adddetails.userLoginDetails(body).then((data) => {
      //console.log(data.data["page_details"]["total_pages"], "user");
      if (data.status === 200) {
        let resData = Array.from(data.data["users"]);
        let newData = [];

        for (let i = 0; i < resData.length; i++) {
          //console.log(resData[i], "user");
          newData.push({
            id: resData[i]["user_id"],
            type: resData[i]["login_details"]
              ? resData[i]["login_details"]["device_type"]
              : "",
            time: resData[i]["login_details"]
              ? moment
                  .tz(
                    resData[i]["login_details"]["login_time"],
                    "America/Los_Angeles"
                  )
                  .format("MM-DD-YYYY h:mm:ss A")
              : "",
            first_name: resData[i]["first_name"],
            last_name: resData[i]["last_name"],
            name: resData[i]["first_name"] + resData[i]["last_name"],
            is_pwd_update:
              resData[i]["is_password_update"] === 1 ||
              resData[i]["login_details"] === null
                ? "YES"
                : "NO",
          });
        }
        // if (newData) {
        //   let orgTag = newData.sort(function (a, b) {
        //     return a.name.localeCompare(b.name);
        //   });
        // }
        setDataSource(newData);
        setTotalPages(data.data["page_details"]["total_pages"]);
        setisLoading(false);
      }
    });
  };

  return (
    <>
      <Row>
        <Col span={8} className="flexcenter">
          <h3 className="heading-font">ALL USERS</h3>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <div class="table-scroll">
            <Table
              columns={Tablecolumns}
              //rowKey={record => record.id}
              dataSource={dataSource}
              //pagination={this.state.pagination}
              pagination={{
                total: totalPages,
                onChange: (page) => {
                  fetchRecords(page);
                },
              }}
              loading={isLoading}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
