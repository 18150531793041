import React, { useState, useEffect } from "react";

import { Row, Col, Button, Table, Form, message, Input } from "antd";
import Org from "./Prasantation/Org";

import "./index.css";

import ApiService from "../../../services/api-services";
import Tablecolumns from "./Tabelcolumn";
const { Search } = Input;
var searchItem = [];

export default ({ handleEdit, showDeleteConfirm, saveOrgId, org_id, setaddStaff }) => {
  const [initialFormLoad, setinitialFormLoad] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const [staffData, setstaffData] = useState([]);
  //const [staffData1, setstaffData1] = useState([])
  const [initialValues, setinitialValues] = useState({});
  const [pagination, setpagination] = useState();
  const [orgData, setOrgdata] = useState([]);
  const [searchTimer, setSearchTimer] = useState(0);
  const [lastUpdated, setLastUpdated] = useState("");
  const [formRef] = Form.useForm();

  const RequestComponentData = () => {
    ApiService.adddetails.getOrgList().then((data) => {
      if (data.status === 200) {
        const newData = data.data.filter((item) => {
          if (item.name && item.org_id) {
            if (item.status !== 0) {
              return item;
            }
          }
        });
        console.log(newData, "new");
        setOrgdata(newData);
        console.log("upper3", orgData);

        setinitialFormLoad((d) => !d);
      }
    });
  };

  const staffComponentData = () => {
    if (sessionStorage.getItem("rolename") === "Admin") {
      ApiService.adddetails
        .atStaff({
          org_id: sessionStorage.getItem("org_id"),
        })
        .then((response) => {
          if (response.status !== 200) {
            return message.error("server error");
          }
          if (response.data) {
            let staffTag = response.data.sort(function (a, b) {
              return a.first_name.localeCompare(b.first_name);
            });
            setstaffData(staffTag);
            setisLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    initialLoadAllStaff();
    RequestComponentData();
    staffComponentData();
  }, []);

  const initialLoadAllStaff = () => {
    try {
      setisLoading(true);

      sessionStorage.getItem("rolename") === "Super Admin" &&
        ApiService.adddetails
          .atStaff({
            org_id: "",
          })
          .then((response) => {
            if (response.status !== 200) {
              return message.error("server error");
            }
            if (response.data) {
              let staffTag = response.data.sort(function (a, b) {
                return a.first_name.localeCompare(b.first_name);
              });
              setstaffData(staffTag);
              setisLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
          });
    } catch (error) { }
  };
  // below code is for loading staff by selection org before
  const onFinish = (values) => {
    try {
      setisLoading(true);
      saveOrgId(values.organization.value);
      sessionStorage.getItem("rolename") === "Super Admin" &&
        ApiService.adddetails
          .atStaff({
            org_id: values.organization.value,
          })
          .then((response) => {
            if (response.status !== 200) {
              return message.error("server error");
            }
            if (response.data) {
              let staffTag = response.data.sort(function (a, b) {
                return a.first_name.localeCompare(b.first_name);
              });
              setstaffData(staffTag);
              setisLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
          });
    } catch (error) { }
  };

  // const onorgFinish = values => {
  //     try {
  //         setisLoading(true)
  //         saveOrgId(sessionStorage.getItem("org_id"))
  //         sessionStorage.getItem("rolename") === "Admin" && (
  //         ApiService.adddetails.atStaff({
  //             org_id: sessionStorage.getItem("org_id")
  //         })
  //             .then(response => {
  //                 if (response.status !== 200) {
  //                     return message.error('server error')
  //                 }
  //                 if (response.data) {
  //                     setstaffData1(response.data)
  //                     setisLoading(false)
  //                 }

  //             })
  //         )
  //             .catch(error => {
  //                 console.log(error)
  //             })
  //     } catch (error) {

  //     }
  // };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return initialFormLoad ? (
    <>loading </>
  ) : (
    <div className="body-color" id="viewStaff">
      <Row>
        <Col span={8} className="flexcenter">
          <h3 className="heading-font">SUBMITTER STAFF</h3>
        </Col>
      </Row>
      {/* below form is for */}
      {/* <Form
        form={formRef}
        initialValues={initialValues}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="view_form"
      >
        <Org data={orgData} />
        <Form.Item className="org_view_submit">
          {sessionStorage.getItem("rolename") === "Super Admin" && (
            <Button
              className="btn-type"
              id=""
              loading={isLoading}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          )}
        </Form.Item>
      </Form> */}
      <Row>
        <div className="sub-bar-other">
          {sessionStorage.getItem("rolename") === "Super Admin" && (
            <Search
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
                width: "100%",
              }}
              placeholder="Search Submitter Staff"
              // onSearch={(value) => console.log(value)}
              onChange={(value) => {
                // Reset timer for user input
                clearTimeout(searchTimer);
                // Persist inputEvent for later use
                value.persist()
                // Keep track of latest query timestamp
                setLastUpdated(value.timeStamp);
                // Pull data, but wait for 300ms to let user finish typing
                setSearchTimer(setTimeout(() => {
                  // If this is the latest query
                  if (value.timeStamp >= lastUpdated) {
                    setisLoading(true);
                    ApiService.general
                      .search_user({
                        user_type: "staff_submitter",
                        key: value.target.value,
                        org_id: "",
                      })
                      .then((response) => {
                        // console.log(response, "rret");
                        if (value.timeStamp >= lastUpdated && response.data) {
                          console.log(value.timeStamp + ' & ' + lastUpdated)
                          let searchTag = response.data.sort(function (a, b) {
                            return a.first_name.localeCompare(b.first_name);
                          });
                          setstaffData(searchTag);
                          setinitialValues(response.data);
                          setisLoading(false);
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }
                }, 300))

              }}
            />
          )}
          {sessionStorage.getItem("rolename") === "Admin" && (
            <Search
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
              placeholder="Search submitter staff"
              // onSearch={(value) => console.log(value)}
              onChange={(value) => {
                // Reset timer for user input
                clearTimeout(searchTimer);
                // Persist inputEvent for later use
                value.persist()
                // Keep track of latest query timestamp
                setLastUpdated(value.timeStamp);
                // Pull data, but wait for 300ms to let user finish typing
                setSearchTimer(setTimeout(() => {
                  // If this is the latest query
                  if (value.timeStamp >= lastUpdated) {
                    setisLoading(true);
                    ApiService.general
                      .search_user({
                        user_type: "staff_submitter",
                        key: value.target.value,
                        org_id: sessionStorage.getItem("org_id"),
                      })
                      .then((response) => {
                        console.log(response, "rret");
                        if (response.data) {
                          let searchTag = response.data.sort(function (a, b) {
                            return a.first_name.localeCompare(b.first_name);
                          });
                          setstaffData(searchTag);
                          setinitialValues(response.data);
                          setisLoading(false);
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }
                }, 300));
              }}
            />
          )}
          <a>
            <Button
              className="addConsumerButton"
              // className="btn-type new_button"
              // style={{ marginTop: 54 }}
              type="primary"
              onClick={(e) => {
                setaddStaff(true);
              }}
            >
              Add Staff
            </Button>
          </a>
        </div>
      </Row>

      <Table
        columns={Tablecolumns(handleEdit, orgData)}
        dataSource={staffData}
        pagination={pagination}
        loading={isLoading}
      />

      {/* 
            {sessionStorage.getItem("rolename") === "Admin" && (
            <Table
                columns={Tablecolumns(handleEdit, showDeleteConfirm)}
                dataSource={staffData1}
                pagination={pagination}
                loading={isLoading}
            />
            )} */}
    </div>
  );
};
