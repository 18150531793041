import React from 'react'
import { Input, Form } from 'antd';

export default ({ handleChange }) => {
    return <>
        <Form.Item
            name="email"
            label="Email"
            className="display"
            rules={[
                {
                    required: true,
                    message: 'Please enter your mail ID'
                },
                {
                    //pattern: /^[a-z0-9+_.-]+@[a-z0-9.-]+$/,
                    //pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-]+.[a-zA-Z0-9-]+$/,
                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid mail ID'

                },
            ]}
        >

            <Input className='text-input'
                type="email"
                placeholder="Email Id"
                onChange={(e) => handleChange(e.target.value, 'email')}
            />

        </Form.Item>
    </>
}