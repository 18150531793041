import React, { Component, lazy, Suspense } from "react";
import {
  Row,
  Col,
  Avatar,
  Input,
  Menu,
  Modal,
  Dropdown,
  Button,
  message,
  Select,
  Table,
  Form,
  DatePicker,
  Tooltip,
} from "antd";
import { UserOutlined, BellFilled, EditOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import { Link, NavLink, Redirect } from "react-router-dom";
import moment from "moment";
import "./PromoCode.css";
import { Container } from "reactstrap";
import CONFIG from "../../../services/config";
import UserContext from "../../../UserContext";
import ApiService from "../../../services/api-services";
const FormItem = Form.Item;
const { Search } = Input;
var searchItem = [];
const dateFormat = "MM-DD-YYYY";

export default class dashboard extends Component {
  static contextType = UserContext;
  formRef = React.createRef();

  constructor(props) {
    super(props);
    var user_id,
      type,
      formData = {};
    if (this.props.location.state != undefined) {
      formData = {
        user_id: this.props.location.state.user_id,
        type: this.props.location.state.type,
        first_name: this.props.location.state.first_name,
      };
    }
    this.state = {
      formData: formData,
      promoData: false,
      isFetchSpeciality: false,
      isLoading: false,
      Visible: false,
      createVisible: false,
      orgData: [],
      planData: [],
      promo: "",
    };
  }

  componentDidMount() {
    this.fetchPromo();
    this.fetchOrg();
    this.fetchPlan();
  }

  fetchPromo = () => {
    ApiService.adddetails
      .promolist()
      .then((data) => {
        if (data.status === 200) {
          let resData = Array.from(data.data);
          let newData = [];
          console.log(resData);

          for (let i = 0; i < resData.length; i++) {
            newData.push({
              name: resData[i]["name"],
              start_date: resData[i]["start_date"],
              end_date: resData[i]["end_date"],
              org_id: resData[i]["organization.org_id"],
              plan_id: resData[i]["plan_id"],
              organization: resData[i]["organization.name"],
              plan: resData[i]["promo_plan.plan_name"],
              addedDate: resData[i]["createdAt"],
            });
          }
          this.setState({ promoData: newData });
        }
      })
      .catch((error) => {
        this.setState({ isFetchSpeciality: false });
      });
  };

  fetchOrg = () => {
    ApiService.adddetails.getOrgList().then((data) => {
      if (data.status === 200) {
        const newData = data.data.filter((item) => {
          if (item.name && item.org_id) {
            if (item.status !== 0) {
              return item;
            }
          }
        });
        this.setState({ orgData: newData });
      }
    });
  };

  fetchPlan = () => {
    ApiService.adddetails.planlist().then((data) => {
      if (data.status === 200) {
        //  console.log("tagOrg",data)
        this.setState({ planData: data.data });
      }
    });
  };

  generatePromo = () => {
    ApiService.adddetails.generatePromo().then((data) => {
      console.log(data, "et");
      if (data.status === 200) {
        //  console.log("tagOrg",data)
        this.setState({ promo: data.data["promo_code"] });
        this.setModalVisible(true);
      }
      console.log(this.state.promo, "ppp");
    });
  };

  promoVisible = () => {
    this.generatePromo();
  };

  setModalVisible = (args) => this.setState({ Visible: args });
  createModalVisible = (args) => this.setState({ createVisible: args });

  handleChange = (e, field) => {
    console.log(e);
    const { formData } = this.state;

    if (field === "name") {
      console.log(e);
      this.setState({
        formData: { ...formData, [field]: e },
      });
      return;
    } else if (field === "start_date") {
      console.log(e);
      e = moment(e).format("MM-DD-YYYY");
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "end_date") {
      console.log(e);
      e = moment(e).format("MM-DD-YYYY");
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "org_id") {
      console.log(e);

      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "plan_id") {
      console.log(e);

      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else {
      this.setState(
        {
          formData: { ...formData, [field]: e.target.value },
        },
        function () {}
      );
    }
  };

  handleCancel = () => {
    this.setState({
      createVisible: false,
    });
    this.formRef.current.resetFields();
  };

  handlegenCancel = () => {
    this.setState({
      Visible: false,
    });
    this.setState({ promo: "" });
    this.formRef.current.resetFields();
  };

  doSave() {
    const { formData } = this.state;
    //to check the validation of the form items
    const val = this.formRef.current.validateFields();

    let name = this.state.formData.name;
    let org = this.state.formData.org_id;
    let plan = this.state.formData.plan_id;
    let startDate = this.state.formData.start_date;
    let endDate = this.state.formData.end_date;
    let spec_promo = RegExp(/^[_@./#&+-]*$/).test(name);
    let promo = RegExp(/^[0-9]*$/).test(name);
    console.log(startDate, "ddddd");
    if (
      org === "" ||
      plan === "" ||
      org === undefined ||
      plan === undefined ||
      org === null ||
      plan === null ||
      name === ""
    ) {
      message.error("Please fill the mandatory fields ");
      return;
    }
    if (
      startDate === "" ||
      endDate === "" ||
      startDate === undefined ||
      endDate === undefined ||
      startDate === null ||
      endDate === null
    ) {
      message.error("Please select the Dates ");
      return;
    }
    if (startDate > endDate) {
      message.warn("Please choose the proper end date");
      return;
    }
    if (spec_promo === true || promo === true) {
      message.error("Please enter the valid promo code name");
      return;
    }
    let body = {
      //"name": this.state.formData.name.charAt(0).toUpperCase() + this.state.formData.name.slice(1),
      name: this.state.formData.name,
      start_date: this.state.formData.start_date,
      end_date: this.state.formData.end_date,
      org_id: this.state.formData.org_id,
      plan_id: this.state.formData.plan_id,
    };
    ApiService.adddetails
      .createPromo(body)
      .then((data) => {
        this.setState({ isLoading: false });
        if (data.status === 200) {
          message.success("Successfully created the Promo code");
          this.setState({ createVisible: false });
          this.setState({ formData: "" });
          this.formRef.current.resetFields();
          this.fetchPromo();
          this.props.history.push("/PromoCode");
        }
      })
      .catch((error) => {
        if (error.data.status === 400 || error.data.status === undefined) {
          message.error("Please enter all the required fields");
        }
        if (error.data.status === 708) {
          message.error("Promo code already exists");
        }
      });
  }

  genSave() {
    const { formData } = this.state;
    const val = this.formRef.current.validateFields();

    let org = this.state.formData.org_id;
    let plan = this.state.formData.plan_id;
    let startDate = this.state.formData.start_date;
    let endDate = this.state.formData.end_date;
    if (
      org === "" ||
      plan === "" ||
      org === undefined ||
      plan === undefined ||
      org === null ||
      plan === null
    ) {
      message.error("Please fill the mandatory fields");
      return;
    }
    if (
      startDate === "" ||
      endDate === "" ||
      startDate === undefined ||
      endDate === undefined ||
      startDate === null ||
      endDate === null
    ) {
      message.error("Please select the Dates ");
      return;
    }
    if (startDate > endDate) {
      message.warn("Please choose the proper end date");
      return;
    }
    let body = {
      name: this.state.promo,
      start_date: this.state.formData.start_date,
      end_date: this.state.formData.end_date,
      org_id: this.state.formData.org_id,
      plan_id: this.state.formData.plan_id,
    };
    // console.log("body yenta untu", body);
    ApiService.adddetails
      .createPromo(body)
      .then((data) => {
        this.setState({ isLoading: false });
        if (data.status === 200) {
          message.success("Successfully created the Promo code");
          this.setState({ Visible: false });
          this.setState({ formData: "" });
          //to reset or clear the input fields
          this.formRef.current.resetFields();
          this.fetchPromo();
          this.props.history.push("/PromoCode");
        }
      })
      .catch((error) => {
        if (error.data.status === 400 || error.data.status === undefined) {
          message.error("Please enter all the required fields");
        }
        if (error.data.status === 708) {
          message.error("Promo code already exists");
        }
      });
  }

  Tablecolumns = () => [
    {
      title: "Promo Code",
      dataIndex: "name",
      render: (url, record) => {
        return <span>{record["name"]}</span>;
      },
      width: "20%",
    },
    {
      title: "Date Added",
      dataIndex: "addedDate",
      render: (url, record) => {
        return <span>{moment(record["addedDate"]).format("MM-DD-YYYY")}</span>;
      },
      width: "20%",
    },
    {
      title: "Added By",
      dataIndex: "",
      render: (url, record) => {
        return <span>{record[""]}</span>;
      },
      width: "20%",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      render: (url, record) => {
        return <span>{moment(record["start_date"]).format("MM-DD-YYYY")}</span>;
      },
      // sorter: 'true',
      width: "20%",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      render: (url, record) => {
        return <span>{moment(record["end_date"]).format("MM-DD-YYYY")}</span>;
      },
      // sorter: 'true',
      width: "20%",
    },
    {
      title: "Organization",
      dataIndex: "organization",
      render: (url, record) => {
        return <span>{record["organization"]}</span>;
      },
      width: "20%",
    },
    {
      title: "Plan",
      dataIndex: "plan",
      render: (url, record) => {
        return <span>{record["plan"]}</span>;
      },
      // sorter: 'true',
      width: "20%",
    },
    // {
    //     title: 'Action',
    //     dataIndex: 'status',
    //     width: '25%',
    //                 render: (url, record) => {
    //         return <span>{<li id='table-action'><Tooltip title="Edit"><EditOutlined id='table-edit'
    //          onClick={() => this.editBtnClick(record['user_id'],record['first_name'],record['last_name'],record['email'],record['password'],record['org_id'],record['organization'],'edit')}
    //         >
    //             </EditOutlined>
    //             </Tooltip>
    //             </li>
    //             }
    //             </span>;
    //       }
    // }
  ];

  render() {
    const { orgData, planData, formData } = this.state;
    let startDate = this.state.formData.start_date;
    console.log(startDate, "date");

    let orgTag = orgData.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });

    let listOrg =
      orgData.length > 0 &&
      orgData.map((items, i) => {
        return (
          <option key={items.org_id} value={items.org_id}>
            {items.name}
          </option>
        );
      }, this);

    let planTag = planData.sort(function (a, b) {
      return a.plan_name.localeCompare(b.plan_name);
    });

    let listPlan =
      planData.length > 0 &&
      planData.map((items, i) => {
        return (
          <option key={items.id} value={items.id}>
            {items.plan_name}
          </option>
        );
      }, this);

    let disabledDate = (current) => {
      // Can not select days before today and today
      return current && current < moment().startOf("day");
    };

    // let minDate = moment();
    // minDate.subtract(0, "years");
    // minDate = minDate.format("YYYY-MM-DD");

    let disabledDate1 = (startDate) => {
      return startDate && startDate < moment().endOf("day");
    };

    return (
      <>
        <Row>
          <Col span={8} className="flexcenter">
            <h3 className="heading-font">PROMO CODE</h3>
          </Col>
          {/* <Col span={16}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                    <ul className="closecodeheaderMenu">
                        <li className="bellNotification">
                            <BellFilled style={{ color: '#0252a1' }} />
                        </li>
                        <li className="profile_button">

                            <div className="headerPorifileDiv">
                                <Avatar shape="square" size="large" icon={<UserOutlined />} style={{ borderRadius: '30%' }} />
                                <span className="proifle_name">
                                    Welcome Sally Rivera
                                </span>
                            </div>
                        </li>
                        <li className="logoutbutton">
                            <Button className="buttonOutline" ghost size="default" onClick={() => this.signOut()}>
                                Logout
                            </Button>
                        </li>
                    </ul>
                </div>
            </Col> */}
        </Row>
        <Row>
          <div className="sub-bar">
            <Search
              placeholder="Search Promo Code"
              onSearch={(value) => console.log(value)}
              disabled={true}
              //      onKeyUp={
              //         value => {
              //         // console.log(value.target.value)
              //         ApiService.general.search_admin({ search_key: value.target.value })
              //             .then(res => {
              //                 console.log(res)
              //                 if(res.data === null && value.target.value === '') {
              //                     this.props.history.push('/organization-list')
              //                     console.log('hiias')
              //                 } else {
              //          let resData = Array.from(res.data);
              //                         let newData = []
              //                         for (let i = 0; i < resData.length; i++) {
              //                             let status = resData[i]['status']
              //                             console.log(status)
              //                             if(status===0) {
              //                                 status= 'Inactive'
              //                             } else {
              //                                 status= 'Active'
              //                             }
              //                             let date = moment(resData[i]['createdAt'])
              //                             let showDate = date.format('MM-DD-YYYY');
              //                             newData.push({
              //                                 user_id: resData[i]['user_id'],
              //                                 first_name:resData[i]['first_name'],
              //                                 last_name:resData[i]['last_name'],
              //                                 name: resData[i]['first_name'] + ' ' + resData[i]['last_name'],
              //                                 email: resData[i]['email'],
              //                                 organization: resData[i]['organization.name'],
              //                                 org_id: resData[i]['organization.org_id'],
              //                                 createdAt: showDate,
              //                                 password: resData[i]['password'],
              //                                 status: status,

              //                             })

              //                          }
              //                     this.setState({adminData: newData, isLoading: true})
              //                     this.Tablecolumns()
              //                 }
              //                // this.setView(res.data)
              //             })
              //             .catch(err => {
              //                 console.log(err)
              //             })
              //     }
              // }
            />
            <div>
              <Button
                className="adduser-btn"
                type="primary"
                id=""
                onClick={() => this.createModalVisible(true)}
              >
                Create
              </Button>
            </div>
            <div>
              <Button
                className="btn-type"
                id=""
                type="primary"
                onClick={() => this.promoVisible()}
              >
                Generate
              </Button>
            </div>
          </div>
          {/* <button type="primary" className="add-btn">Add Close Code</button>*/}
        </Row>

        <Row>
          <Col span={24}>
            <div class="table-scroll">
              <Table
                columns={this.Tablecolumns()}
                //rowKey={record => record.id} not my
                dataSource={this.state.promoData}
                pagination={this.state.pagination}
                //  loading={!this.state.isLoading}
                //  onChange={this.handleTableChange}
              />
            </div>
          </Col>
        </Row>

        <div>
          <div className="modal">
            <Modal
              title="GENERATE PROMO CODE"
              // centered
              visible={this.state.Visible}
              okButtonProps={{ style: { display: "none" } }}
              //  onCancel={() => this.setState({ Visible: !this.state.Visible})}
              footer={[
                <div className="footer">
                  <Button
                    className="footer-btn"
                    htmlType="submit"
                    key="submit"
                    type="primary"
                    onClick={() => this.genSave()}
                  >
                    Generate
                  </Button>

                  <Button
                    className="footer-btn"
                    type="primary"
                    key="back"
                    onClick={() => this.handlegenCancel()}
                  >
                    Cancel
                  </Button>
                </div>,
              ]}
            >
              <div>
                <Form
                  ref={this.formRef}
                  initialValues={{
                    promocode: this.state.promo,
                  }}
                >
                  <FormItem
                    //label='First Name'
                    name="promocode"
                    className="display"
                    rules={[
                      {
                        required: true,
                        message: "Please enter promo code name",
                      },
                    ]}
                  >
                    <Input
                      className="text-input"
                      id=""
                      placeholder="Promo Code"
                      readOnly
                      //value={this.state.promo}
                      // onChange={(e) => this.handleChange( e.target.value,'name')}
                      //onChange={() => this.onFinish()}
                    />
                  </FormItem>
                  <Form.Item
                    name="start_date"
                    className="display"
                    rules={[
                      {
                        required: true,
                        message: "Please choose start date",
                      },
                    ]}
                    style={{ width: "45%" }}
                  >
                    <DatePicker
                      className="text-input"
                      placeholder="Start Date"
                      disabledDate={disabledDate}
                      // defaultValue={moment('', dateFormat)}
                      format={dateFormat}
                      value={this.state.formData.dob}
                      onChange={(e) => this.handleChange(e, "start_date")}
                    />
                  </Form.Item>
                  <Form.Item
                    name="end_date"
                    className="display"
                    rules={[
                      {
                        required: true,
                        message: "Please choose end date",
                      },
                    ]}
                    style={{ width: "45%", float: "right", marginTop: "-85px" }}
                  >
                    <DatePicker
                      className="text-input"
                      placeholder="End Date"
                      disabledDate={disabledDate1}
                      // defaultValue={moment('', dateFormat)}
                      format={dateFormat}
                      value={this.state.formData.dob}
                      onChange={(e) => this.handleChange(e, "end_date")}
                    />
                  </Form.Item>
                  <Form.Item
                    name="Organization"
                    className="display"
                    rules={[
                      {
                        required: true,
                        message: "Please select Organization",
                      },
                    ]}
                    style={{ width: "45%" }}
                  >
                    <Select
                      showSearch
                      className="text-select"
                      //mode="tags"
                      placeholder="Choose Organization"
                      onChange={(e) => this.handleChange(e, "org_id")}
                    >
                      {listOrg}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="plan"
                    className="display"
                    rules={[
                      {
                        required: true,
                        message: "Please select plan",
                      },
                    ]}
                    style={{ width: "45%", float: "right", marginTop: "-85px" }}
                  >
                    <Select
                      showSearch
                      className="text-select"
                      //mode="tags"
                      placeholder="Choose plan"
                      onChange={(e) => this.handleChange(e, "plan_id")}
                    >
                      {listPlan}
                    </Select>
                  </Form.Item>
                </Form>
              </div>
            </Modal>
          </div>
        </div>

        <div className="modal">
          <Modal
            title="CREATE PROMO CODE"
            // centered
            visible={this.state.createVisible}
            okButtonProps={{ style: { display: "none" } }}
            // onCancel={() => this.setState({ Visible: !this.state.createVisible})}
            footer={[
              <div className="footer">
                <Button
                  className="footer-btn"
                  htmlType="submit"
                  key="submit"
                  type="primary"
                  onClick={() => this.doSave()}
                >
                  Create
                </Button>

                <Button
                  className="footer-btn"
                  key="back"
                  type="primary"
                  onClick={() => this.handleCancel()}
                >
                  Cancel
                </Button>
              </div>,
            ]}
          >
            <div className="create-modal">
              <Form ref={this.formRef}>
                <FormItem
                  // label='promo code'
                  name="promocode"
                  className="display"
                  rules={[
                    {
                      required: true,
                      message: "Enter promo code (ex:WERFRW123@)",
                    },
                    {
                      pattern: /^\(?([A-Z0-9_@./#&+-]{10})\)?$/,
                      // pattern: /^[A-Z0-9_@./#&+-]*$/,
                      message:
                        "Please enter a valid promo code(You can only use uppercase letters, numbers & special char)",
                    },
                  ]}
                >
                  <Input
                    className="text-input"
                    id=""
                    placeholder="Enter Promo Code"
                    onChange={(e) => this.handleChange(e.target.value, "name")}
                    //onChange={() => this.onFinish()}
                  />
                </FormItem>
                <div className="start">
                  <Form.Item
                    name="start_date"
                    className="display"
                    rules={[
                      {
                        required: true,
                        message: "Please choose start date",
                      },
                    ]}
                    style={{ width: "45%" }}
                  >
                    <DatePicker
                      className="text-input"
                      placeholder="Start Date"
                      disabledDate={disabledDate}
                      // defaultValue={moment('', dateFormat)}
                      format={dateFormat}
                      value={this.state.formData.start_date}
                      onChange={(e) => this.handleChange(e, "start_date")}
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  name="end_date"
                  className="display"
                  rules={[
                    {
                      required: true,
                      message: "Please choose end date",
                    },
                  ]}
                  style={{ width: "45%", float: "right", marginTop: "-85px" }}
                >
                  <DatePicker
                    className="text-input"
                    placeholder="End Date"
                    // defaultValue={moment('', dateFormat)}
                    disabledDate={disabledDate1}
                    format={dateFormat}
                    value={this.state.formData.end_date}
                    onChange={(e) => this.handleChange(e, "end_date")}
                  />
                </Form.Item>
                <Form.Item
                  name="Organization"
                  className="display"
                  rules={[
                    {
                      required: true,
                      message: "Please select Organization",
                    },
                  ]}
                  style={{ width: "45%" }}
                >
                  <Select
                    showSearch
                    className="text-select"
                    //mode="tags"
                    placeholder="Choose Organization"
                    onChange={(e) => this.handleChange(e, "org_id")}
                  >
                    {listOrg}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="plan"
                  className="display"
                  rules={[
                    {
                      required: true,
                      message: "Please select plan",
                    },
                  ]}
                  style={{ width: "45%", float: "right", marginTop: "-85px" }}
                >
                  <Select
                    showSearch
                    className="text-select"
                    //mode="tags"
                    placeholder="Choose plan"
                    onChange={(e) => this.handleChange(e, "plan_id")}
                  >
                    {listPlan}
                  </Select>
                </Form.Item>
              </Form>
            </div>
          </Modal>
        </div>
      </>
    );
  }
}
