import React, { useState, useEffect } from "react";
import ApiService from "../../../services/api-services";
import { Table, Button, Modal, Form, Input, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { EditOutlined } from "@ant-design/icons";
import "./index.css";
const FormItem = Form.Item;

const AfyaPlan = () => {
  const [form] = Form.useForm();
  const [viewPlan, setViewPlan] = useState("");
  const [modalVissible, setModalVisible] = useState(false);
  const [type, setType] = useState("add");
  const [load, setLoad] = useState(false);

  const [planName, setPlanName] = useState("");
  const [month, setMonth] = useState("");
  const [amount, setAmount] = useState("");
  const [session, setSession] = useState("");
  const [planDesc, setPlanDesc] = useState("");
  const [id, setid] = useState("");

  useEffect(() => {
    fetchPlan();
  }, []);

  const fetchPlan = () => {
    ApiService.adddetails
      .planlist()
      .then((data) => {
        if (data.status === 200) {
          let resData = Array.from(data.data);
          let newData = [];

          for (let i = 0; i < resData.length; i++) {
            newData.push({
              plan_name: resData[i]["plan_name"],
              month: resData[i]["months"],
              amount: resData[i]["amount"],
              session: resData[i]["mx_sessn_month"],
              plan_desc: resData[i]["description"],
              id: resData[i]["id"],
            });
          }
          setViewPlan(newData);
        }
      })
      .catch((error) => {
        console.log(error, "view plan erro");
      });
  };
  const Tablecolumns = [
    {
      title: "Plan Name",
      dataIndex: "plan_name",
      render: (url, record) => {
        return <span>{record["plan_name"]}</span>;
      },
      width: "20%",
    },
    {
      title: "Plan Descrp",
      dataIndex: "plan_desc",
      render: (url, record) => {
        return <span>{record["plan_desc"]}</span>;
      },
      width: "20%",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (url, record) => {
        return <span>${record["amount"]}</span>;
      },
      width: "20%",
    },
    {
      title: "Months",
      dataIndex: "month",
      render: (url, record) => {
        return <span>{record["month"]}</span>;
      },
      width: "20%",
    },
    {
      title: "Max. Sessions",
      dataIndex: "session",
      render: (url, record) => {
        return <span>{record["session"]}</span>;
      },
      // sorter: 'true',
      width: "20%",
    },
    {
      title: "Action",
      dataIndex: "status",
      width: "25%",
      render: (url, record) => {
        return (
          <span>
            {
              <li id="table-action">
                <EditOutlined
                  id="table-edit"
                  onClick={() => editBtnClick(record)}
                ></EditOutlined>
              </li>
            }
          </span>
        );
      },
    },
  ];

  /*FOR CREATING THE PLAN-------STARTS*/
  const doSave = () => {
    let description = planDesc;
    let descriptionPattern = RegExp(/^[a-zA-Z ]*$/).test(description);

    let name = planName;
    let namePattern = RegExp(/^[a-zA-Z -]*$/).test(name);

    let monthVal = month;

    let sessionVal = session;

    let amountVal = amount;

    if (
      name === "" ||
      namePattern === false ||
      description === "" ||
      description === undefined ||
      monthVal === "" ||
      sessionVal === "" ||
      amountVal === "" ||
      monthVal === undefined ||
      sessionVal === undefined ||
      amountVal === undefined
    ) {
      message.error("Please fill the mandatory fields ");
      return;
    }
    if (amount === "0") {
      message.error("Please Enter valid amount ");
      return;
    }
    if (month === "0") {
      message.error("Please Enter valid no.of months ");
      return;
    }

    let body = {
      plan_name: planName.charAt(0).toUpperCase() + planName.slice(1) || "",
      months: parseInt(month),
      amount: parseFloat(amount),
      mx_sessn_month: parseInt(session),
      description: planDesc.charAt(0).toUpperCase() + planDesc.slice(1) || "",
    };

    ApiService.adddetails
      .createPlan(body)
      .then((data) => {
        if (data.status === 200) {
          message.success("Successfully added the Plan");
          fetchPlan();
          setLoad(true);
          form.resetFields();
          setModalVisible(false);
        }
        setLoad(false);
      })
      .catch((error) => {
        if (error.data.status === 400 || error.data.status === undefined) {
          message.error("Please enter all the required fields");
        }
        if (error.data.status === 708) {
          message.error("Plan already exists");
        }
      });
  };
  /*FOR CREATING THE PLAN-------ENDSSS*/

  /*FOR EDIT VIEW THE PLAN-------STARTS*/
  const editBtnClick = (val) => {
    setModalVisible(true);
    setType("edit");
    setPlanName(val.plan_name);
    setSession(val.session);
    setAmount(val.amount);
    setMonth(val.month);
    setPlanDesc(val.plan_desc);
    setid(val.id);
  };

  useEffect(() => {
    if (type === "edit") {
      form.setFieldsValue({ plan_name: planName });
      form.setFieldsValue({ plan_desc: planDesc });
      form.setFieldsValue({ month: month });
      form.setFieldsValue({ amount: amount });
      form.setFieldsValue({ session: session });
      return;
    }
  }, [planName, planDesc, month, amount, session, type, id]);
  /*FOR  EDIT VIEW THE PLAN-------ENDSSS*/

  /*FOR UPDATING THE PLAN-------STARTS*/
  const updateSave = () => {
    let description = planDesc;
    let descriptionPattern = RegExp(/^[a-zA-Z ]*$/).test(description);

    let name = planName;
    let namePattern = RegExp(/^[a-zA-Z -]*$/).test(name);

    let monthVal = month;

    let sessionVal = session;

    let amountVal = amount;

    if (
      name === "" ||
      namePattern === false ||
      description === "" ||
      description === undefined ||
      descriptionPattern === false ||
      monthVal === "" ||
      sessionVal === "" ||
      amountVal === "" ||
      monthVal === undefined ||
      sessionVal === undefined ||
      amountVal === undefined
    ) {
      message.error("Please fill the mandatory fields ");
      return;
    }
    if (amount === "0") {
      message.error("Please Enter valid amount ");
      return;
    }
    if (month === "0") {
      message.error("Please Enter valid no.of months ");
      return;
    }

    let body = {
      plan_name: planName.charAt(0).toUpperCase() + planName.slice(1) || "",
      months: parseInt(month),
      amount: parseFloat(amount),
      mx_sessn_month: parseInt(session),
      description: planDesc.charAt(0).toUpperCase() + planDesc.slice(1) || "",
      id: id,
    };

    ApiService.adddetails
      .editPlan(body)
      .then((data) => {
        if (data.status === 200) {
          message.success("Successfully Updated the Plan");
          fetchPlan();
          form.resetFields();
          setPlanName("");
          setSession("");
          setAmount("");
          setMonth("");
          setPlanDesc("");
          setid("");
          setType("add");
          setLoad(true);
          setModalVisible(false);
        }
        setLoad(false);
      })
      .catch((error) => {
        if (error.data.status === 400 || error.data.status === undefined) {
          message.error("Please enter all the required fields");
        }
        if (error.data.status === 706) {
          message.error("Plan already exists");
        }
      });
  };
  /*FOR  UPDATING THE PLAN-------ENDSSS*/

  const handleCancel = () => {
    setType("add");
    setPlanName("");
    setSession("");
    setAmount("");
    setMonth("");
    setPlanDesc("");
    setid("");
    setModalVisible(false);
    form.resetFields();
  };

  return (
    <div className="container">
      <div className="create_plan">
        <Button
          className="footer-btn"
          type="primary"
          onClick={() => setModalVisible(true)}
        >
          Create Plan
        </Button>
      </div>

      <div className="table_content">
        <Table columns={Tablecolumns} dataSource={viewPlan} />
      </div>

      <Modal
        // title="CREATE PLAN"
        title={type === "add" ? "Create Plan" : "Update Plan"}
        visible={modalVissible}
        okButtonProps={{ style: { display: "none" } }}
        footer={[
          <div className="footer">
            <Button
              className="footer-btn"
              htmlType="submit"
              key="submit"
              type="primary"
              loading={load}
              onClick={() => (type == "add" ? doSave() : updateSave())}
            >
              {type === "add" ? "Create" : "Update"}
            </Button>

            <Button
              className="footer-btn"
              key="back"
              type="primary"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
          </div>,
        ]}
      >
        <Form
          form={form}
          // initialValues={{
          //     plan_name: planName,
          //     plan_desc: planDesc,
          //     amount: amount,
          //     month: month,
          //     session: session
          // }}
        >
          <FormItem
            name="plan_name"
            label="Plan Name"
            className="display"
            rules={[
              {
                required: true,
                message: "Please enter Plan name",
              },
              {
                pattern: /^[a-zA-Z -]*$/,
                message: "Please enter a valid Plan name",
              },
            ]}
            style={{ width: "45%" }}
          >
            <Input
              className="text-input"
              value={planName}
              id=""
              placeholder="Plan Name"
              onChange={(e) => setPlanName(e.target.value)}
            />
          </FormItem>
          <Form.Item
            name="month"
            label="Month"
            className="display"
            rules={[
              {
                required: true,
                message: "Please enter month(max of 2 digit)",
              },
              {
                pattern: /^[0-9]*$/,
                message: "Please enter valid no.of months(max of 2 digit)",
              },
            ]}
            style={{ width: "45%", float: "right", marginTop: "-120px" }}
          >
            <Input
              className="text-input"
              id=""
              maxLength="2"
              placeholder="Months"
              onChange={(e) => setMonth(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="amount"
            label="Amount"
            className="display"
            rules={[
              {
                required: true,
                message: "Please enter amount in dollar",
              },
              {
                pattern: /^[1-9]\d*(\.\d+)?$/,
                message: "Please enter valid amount in dollar",
              },
            ]}
            style={{ width: "45%" }}
          >
            <Input
              className="text-input"
              id=""
              placeholder="Enter Amount"
              onChange={(e) => setAmount(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="session"
            label="Session"
            className="display"
            rules={[
              {
                required: true,
                message: "Please enter no.of session",
              },
              {
                pattern: /^[1-9]*$/,
                message: "Please enter valid no.of session",
              },
            ]}
            style={{ width: "45%", float: "right", marginTop: "-120px" }}
          >
            <Input
              className="text-input"
              id=""
              placeholder="Max.sessions per month"
              onChange={(e) => setSession(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="plan_desc"
            label="Plan Description"
            className="display"
            rules={[
              {
                required: true,
                message: "Please enter description of the plan",
              },
              // {
              //     pattern: /^[a-zA-Z -]*$/,
              //     message: 'Please enter valid description'

              // },
            ]}
            style={{ width: "100%" }}
          >
            <TextArea
              className="text-select"
              placeholder="Plan Description"
              onChange={(e) => setPlanDesc(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AfyaPlan;
