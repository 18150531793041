import React from "react";
import { Avatar, Tooltip } from "antd";
import { Link } from "react-router-dom";

import { EditOutlined, DeleteOutlined, UserOutlined } from "@ant-design/icons";

// this.state = {
// searchText: '',
// searchedColumn: '',
// }

// handleReset = clearFilters => {
// clearFilters();
// this.setState({ searchText: '' });
// };

// getColumnSearchProps = (args, field) => {
// const dataIndex = args == null ? '' : args
// return ({
// filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
// <div style={{ padding: 8 }}>
// <Input
// ref={node => {
// this.searchInput = node;
// }}
// placeholder={`Search ${dataIndex}`}
// value={selectedKeys[0]}
// onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
// onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
// style={{ width: 188, marginBottom: 8, display: 'block' }}
// />
// <Button
// type="primary"
// onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
// icon={<SearchOutlined />}
// size="small"
// style={{ width: 90, marginRight: 8 }}
// >
// Search
// </Button>
// <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
// Reset
// </Button>
// </div>
// ),
// filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
// onFilter: (value, record) =>
// record[dataIndex]
// .toString()
// .toLowerCase()
// .includes(value.toLowerCase()),
// onFilterDropdownVisibleChange: visible => {
// if (visible) {
// setTimeout(() => this.searchInput.select());
// }
// },
// })
// };
// handleSearch = (selectedKeys, confirm, dataIndex) => {
// confirm();
// this.setState({
// searchText: selectedKeys[0],
// searchedColumn: dataIndex,
// });
// };
const filterData = (data) =>
  data.map((item) => ({
    key: item,
    value: item,
    text: item,
  }));

export default (handleEdit, orgData) => [
  {
    title: "User Name",
    dataIndex: "",
    width: "25%",
    /* api integration here */

    render: (url, record) => {
      return (
        <div class="table-doctor">
          <Avatar
            shape="square"
            size="large"
            icon={
              record.image_url !== null ? (
                record.image_url !== "" ? (
                  <img src={record.image_url}></img>
                ) : (
                  <UserOutlined />
                )
              ) : (
                <UserOutlined />
              )
            }
            style={{ borderRadius: "50%", position: "relative" }}
          />

          <div
            id="table-name"
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleEdit({
                MPI: record.MPI,
                user_id: record.user_id,
                first_name: record.first_name,
                last_name: record.last_name,
                dob: record.dob === null ? "" : record.dob,
                hospital_name: record.hospital_name,
                medical_group:
                  record.medical_group === null ? "" : record.medical_group,
                email: record.email,
                about: record.bio,
                mobile: record.mobile,
                experience: record.experience,
                image_url: record.image_url,
                user_id: record.user_id,
              })
            }
          >
            {record["first_name"] + " " + record["last_name"]}
          </div>
        </div>
      );
    },
  },

  {
    title: "Hospital",
    dataIndex: "",
    width: "25%",
    render: (url, record) => {
      return <span>{record["hospital_name"]}</span>;
    },
  },

  {
    title: "Clinical org",
    dataIndex: "",
    width: "25%",
    render: (url, record) => {
      return <span>{record["med_grp_name"]}</span>;
    },
  },
  {
    title: "Organization",
    dataIndex: "org_name",
    width: "25%",
    filters: filterData(
      orgData
        .map((item) => item.name)
        .filter((value, index, self) => self.indexOf(value) === index)
    ),
    onFilter: (value, record) => {
      return record["org_name"] === value;
    },
  },

  {
    title: "Mobile Number",
    key: "action",
    width: "25%",
    render: (url, record) => {
      return <span>{record["mobile"]}</span>;
    },
  },

  {
    title: "Email",
    key: "action",
    width: "25%",
    render: (url, record) => {
      return <span>{record["email"]}</span>;
    },
  },
  {
    title: "Action",
    key: "action",
    width: "25%",

    render: (url, record) => {
      return (
        <span>
          {
            <li id="table-action">
              {console.log(record, "edit")}
              <Tooltip title="Edit">
                <EditOutlined
                  id="table-edit"
                  onClick={() =>
                    handleEdit({
                      MPI: record.MPI,
                      user_id: record.user_id,
                      first_name: record.first_name,
                      last_name: record.last_name,
                      dob: record.dob === null ? "" : record.dob,
                      hospital_name: record.hospital_name,
                      medical_group:
                        record.medical_group === null
                          ? ""
                          : record.medical_group,
                      email: record.email,
                      about: record.bio,
                      mobile: record.mobile,
                      experience: record.experience,
                      image_url: record.image_url,
                      user_id: record.user_id,
                      med_grp_name: record.med_grp_name,
                    })
                  }
                ></EditOutlined>
              </Tooltip>
            </li>
          }
        </span>
      );
    },
  },
];
