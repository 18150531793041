import React from "react";
import { Input, Form } from "antd";

export default ({ handleChange }) => {
  return (
    <>
      <Form.Item
        name="mobile"
        label="Mobile"
        className="display"
        rules={[
          {
            required: false,
            message: "Please enter Mobile number",
          },
          {
            pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,

            message: "Please enter a valid Phone number",
          },
        ]}
      >
        <Input
          className="text-input"
          placeholder="Mobile Number"
          onChange={(e) => handleChange(e.target.value, "mobile")}
        />
      </Form.Item>

      <Form.Item
        name="experience"
        label="Experience"
        className="display"
        rules={[
          {
            required: false,
            message: "Please enter your Experience",
          },
        ]}
      >
        <Input
          className="text-input"
          id=""
          placeholder="Experience in year"
          type="number"
          step="1"
          min="1"
          onChange={(e) => handleChange(e.target.value, "experience")}
        />
      </Form.Item>

      <Form.Item
        name="hospital_name"
        label="Hospital"
        className="display"
        rules={[
          {
            required: false,
            message: "Please enter Hospital Name",
          },
        ]}
      >
        <Input
          className="text-input"
          placeholder="Hospital Name"
          onChange={(e) => handleChange(e.target.value, "hospital_name")}
        />
      </Form.Item>

      <Form.Item
        name="about"
        label="Bio"
        className="display"
        rules={[
          {
            required: true,
            message: "Please enter Bio",
          },
        ]}
      >
        <Input.TextArea
          placeholder="About Doctor"
          onChange={(e) => handleChange(e.target.value, "about")}
        ></Input.TextArea>
      </Form.Item>
    </>
  );
};
