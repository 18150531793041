import React, { Component, useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Modal,
  Button,
  message,
  Table,
  Form,
  Checkbox,
} from "antd";
// import {
//     UserOutlined, BellFilled
// } from '@ant-design/icons';
import "antd/dist/antd.css";
import "./Responder.css";
import { Link } from "react-router-dom";
import UserContext from "../../../UserContext";
import ApiService from "../../../services/api-services";
import TextArea from "antd/lib/input/TextArea";
//import Form from 'antd/lib/form/Form';
const FormItem = Form.Item;
const { Search } = Input;
var searchItem = [];
// const Add = 'Add'

const StaffResponder = ({ options, user_id, getValues, doSorting }) => {
  const [loading, setload] = useState(true);
  const [values, setvalues] = useState([]);

  useEffect(() => {
    if (loading) {
      ApiService.adddetails
        .responder_staff_map_view({
          provider_id: user_id,
        })
        .then((res) => {
          let val = [];
          res &&
            res.data.map((item) => {
              val.push(item.user_id);
            });

          setvalues(val);
          setload(false);
        });
    }
  }, [loading, user_id, values]);
  const getContent = () => {
    console.log(values, options, "values");
    return (
      <Checkbox.Group
        style={{ width: "100%" }}
        options={doSorting(options)}
        onChange={(ev) => {
          getValues("staff", ev);
          setvalues(ev);
        }}
        checkboxid={user_id}
        value={values}
      ></Checkbox.Group>
    );
  };
  return loading ? "loading" : getContent();
};

export default class dashboard extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    var formData = {};
    this.state = {
      token: sessionStorage.getItem("token"),
      formData: "formData",
      staff: [],
      filteredProvider: [],
      // value: 0,
      // codeData: [],
    };
  }

  componentDidMount() {
    if (this.state.token === null || this.state.token === "") {
      this.props.history.push("/login");
      window.location.reload(true);
    }
    this.getResponder();
    this.getStaff();
  }

  showMessage = () => {
    message.success("Staff Responder has been linked successfully");
  };

  getStaff = () => {
    var bodyFormData = new FormData();
    let body = {
      org_id: "",
    };
    ApiService.adddetails.staffResList(body).then((data) => {
      if (data.status === 200) {
        this.setState({
          staff: data.data,
        });
      }
      console.log(this.state.staff, "ss");
    });
  };

  getResponder = () => {
    var bodyFormData = new FormData();
    let body = {
      org_id: "",
    };
    ApiService.adddetails.responderList(body).then((data) => {
      if (data.status === 200) {
        this.setState({ filteredProvider: data.data, provider: data.data });
        this.setState({ isLoading: false });
      }
    });
  };

  getValues = (field, checkedValues) => {
    const { formData } = this.state;
    console.log(field, "getvalFF");
    console.log(checkedValues, "getvalCC");

    if (checkedValues.target) {
      if (checkedValues.target.type === "checkbox") {
        let formData = this.state.formData;
        this.setState({
          formData: {
            ...formData,
            [field]: checkedValues.target.checked ? 1 : 0,
          },
        });
        return;
      }
      if (checkedValues.target.type === "radio") {
        this.setState({
          formData: {
            ...formData,
            [field]: checkedValues.target.value,
          },
        });
        return;
      }
    } else if (Array.isArray(checkedValues)) {
      this.setState({
        formData: {
          ...formData,
          [field]: checkedValues,
        },
      });

      return;
    } else if (
      typeof checkedValues === "string" ||
      typeof checkedValues === "number"
    ) {
      this.setState({
        formData: {
          ...formData,
          [field]: checkedValues,
        },
      });

      return;
    }
  };

  doSorting(data) {
    let sortSpec = data;
    let orgTag = sortSpec.sort(function (a, b) {
      return a.label.localeCompare(b.label);
    });
    return orgTag;
  }

  filteredData(searchQuery) {
    this.setState({
      filteredProvider:
        searchQuery === ""
          ? this.state.provider
          : this.state.provider.filter((item) => {
              const fullName =
                `${item.first_name} ${item.last_name}`.toLowerCase();
              return fullName.includes(searchQuery.toLowerCase());
            }),
    });
  }

  Tablecolumns = () => [
    {
      title: "Provider Name",
      dataIndex: [],
      render: (url, record) => {
        // console.log(record,'eee')
        return (
          <div value={record["user_id"]}>
            {record["first_name"]} {record["last_name"]}
          </div>
        );
      },
      width: "45%",
    },

    {
      title: "Staff",
      dataIndex: [],
      render: (url, record) => {
        const options = [];

        this.state.staff.map((item, index) => {
          options.push({
            label: item.first_name,
            value: item.user_id,
          });
        });
        return (
          <StaffResponder
            user_id={record["user_id"]}
            getValues={(field, e) => this.getValues(field, e)}
            options={options}
            doSorting={this.doSorting}
          />
        );
      },
      width: "45%",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        // console.log(text, record)

        return (
          <Button
            onClick={(e) => {
              const ele = document.querySelectorAll(
                `[checkboxid="${record["user_id"]}"]`
              );
              const tr = document.querySelectorAll(
                `[data-row-key="${record["user_id"]}"]`
              );
              const div = document.createElement("div");
              div.id = "wrapper-" + record["user_id"];

              const checkedElem = ele[0].querySelectorAll(
                ".ant-checkbox-checked>input[value]"
              );
              if (checkedElem.length < 1) {
                message.info("setting empty");
                // e.preventDefault()
                // return
              }

              div.style.position = "absolute";
              div.style.width = "100%";
              div.style.height = "100%";
              div.style.zIndex = "100";
              div.style.background = "transparent";
              div.style.left = 0;
              tr[0].style.filter = "blur(1px)";
              tr[0].appendChild(div);

              const staffList = [];
              checkedElem.forEach((e) => staffList.push(e["value"]));

              ApiService.adddetails
                .responder_staff_mapping({
                  provider_id: record["user_id"],
                  staff: staffList,
                })
                .then((res) => {
                  tr[0].removeChild(div);
                  tr[0].style.filter = "unset";
                });
              this.showMessage();
            }}
          >
            Link
          </Button>
        );
      },
    },
  ];

  render() {
    return (
      <>
        <Row>
          <Col span={8} className="flexcenter">
            <h3 className="heading-font">RESPONDER-STAFF LINKING</h3>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="sub-bar">
              <Search
                placeholder="Search by Provider Name"
                //value={searchQuery}
                onChange={(e) => {
                  this.filteredData(e.target.value);
                }}
              />
            </div>
            <div class="table-scroll">
              <Table
                columns={this.Tablecolumns()}
                rowKey={(record) => record.user_id}
                dataSource={this.state.filteredProvider}
                pagination={this.state.pagination}
                loading={this.state.isLoading}
              />
            </div>
          </Col>
        </Row>
      </>
    );
  }
}
