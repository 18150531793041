import React, { useState, useEffect, useCallback } from "react";
import { Form, Select } from "antd";

const Option = Select.Option;

export default ({ handleChange, data }) => {
  const [orgData, setOrgData] = useState(data);
  const [inititalload, setInitialload] = useState(false);
  const [orgArrayFields, setorgArrayFields] = useState([]);

  let orgTag = orgData.sort(function (a, b) {
    return a.name.localeCompare(b.name);
  });

  return (
    sessionStorage.getItem("rolename") === "Super Admin" && (
      <Form.Item
        name="organization"
        label="Organization"
        className="display org_select"
        rules={[
          {
            required: true,
            message: "Please select the Organization",
          },
        ]}
        style={{ width: "100%", marginRight: "auto" }}
      >
        <Select
          showSearch
          labelInValue
          placeholder="Select a Organization"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          style={{
            width: "100%",
          }}
        >
          {orgTag.length &&
            orgTag.map((orgitem, index) => {
              return (
                <Option key={orgitem.org_id} value={orgitem.org_id}>
                  {orgitem.name}
                </Option>
              );
            })}
        </Select>
      </Form.Item>
    )
  );
};
