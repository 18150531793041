import React, { Component } from "react";
import {
  Row,
  Col,
  Input,
  Modal,
  Button,
  message,
  Table,
  Form,
  Tooltip,
  Switch,
  Select,
  DatePicker,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  FullscreenExitOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import "./ConfigureMessages.css";
import { Link } from "react-router-dom";
import UserContext from "../../../UserContext";
import ApiService from "../../../services/api-services";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";

//import Form from 'antd/lib/form/Form';
const FormItem = Form.Item;
const { Search } = Input;
var searchItem = [];
// const Add = 'Add'
const { confirm } = Modal;
const ReactDragListView = window["react-drag-listview"];
const dateFormat = "YYYY-MM-DD-HH-mm-ss";
const dateFormat2 = "YYYY-MM-DDTHH-mm-ss";
const filterData = (data) =>
  data.map((item) => ({
    key: item,
    value: item,
    text: item,
  }));

export default class dashboard extends Component {
  static contextType = UserContext;
  formRef = React.createRef();
  formRef2 = React.createRef();
  constructor(props) {
    super(props);
    var formData = {
      org_id: [],
      phone_number: "",
      email_id: "",
      message_consumer_welcome_sms: "",
      message_consumer_in_person_visit_recommended: "",
      message_consumer_virtual_visit_recommended: "",
      message_consumer_consultation_complete: "",
      message_responder_provider_daily_unresponded: "",
      message_responder_provider_new_chat_notification: "",
      message_submitter_provider_daily_unresponded: "",
      message_submitter_provider_new_chat_notification: "",
    };
    //hola2
    this.state = {
      stateFormData: formData,
      orgData: [],
      allPhoneNumbers: [],
      configurationData: [],
      rowPhoneNumber: "",
      rowEmailId: "",
      rowOrgId: "",
      rowId: "",
      rowConsumerWelcomeSms: "",
      rowConsumerInPersonVisit: "",
      rowConsumerVirtualVisit: "",
      rowConsumerConsultationComplete: "",
      rowResponderProviderDailyUnresponded: "",
      rowResponderProviderNewChatNotification: "",
      rowSubmitterProviderDailyUnresponded: "",
      rowSubmitterProviderNewChatNotification: "",

      isLoading: false,
      modalVisible: false,
      token: sessionStorage.getItem("token"),

      formData: formData,
      closecodeFullyLoaded: false,
      isFetchSpeciality: false,
      timeframeName: "",
      visible: false,
      time_frame_id: "",
      time_frame_name: "",
      timeFrameData: [],
      // value: 0,
      // codeData: [],
    };
  }

  fetchOrg = () => {
    ApiService.adddetails.getOrgList().then((data) => {
      if (data.status === 200) {
        console.log("level1", data.data);
        const newData = data.data.filter((item) => {
          if (item.name && item.org_id) {
            if (item.status !== 0) {
              return item;
            }
          }
        });
        this.setState({ orgData: newData });
      }
    });
  };

  componentDidMount() {
    if (this.state.token === null || this.state.token === "") {
      this.props.history.push("/login");
      window.location.reload(true);
    }

    this.fetchOrg();
    //this.fetchRole();
    this.fetchExistingConfiguration();
  }

  fetchExistingConfiguration = () => {
    let body = {
      id: "list",
    };

    ApiService.adddetails
      .addTwilioMessageConfiguration(body)
      .then((data) => {
        if (data.status === 200) {
          let resData = Array.from(data.data);
          let newData = [];
          let newPhoneNumbers = [];
          console.log("fetchData", resData);
          for (let i = 0; i < resData.length; i++) {
            if (resData[i]["status"] === 1) {
              newData.push({
                id: resData[i]["id"],
                phoneNumber: resData[i]["twilio_phone"],
                "at_consumer-welcome_SMS":
                  resData[i]["at_consumer-welcome_SMS"],
                organization_name: resData[i]["organization.name"],
                organization_id: resData[i]["org_id"],
                status: resData[i]["status"],
                "at_consumer-in_person_visit_recommended":
                  resData[i]["at_consumer-in_person_visit_recommended"],
                "at_consumer-virtual_visit_recommended":
                  resData[i]["at_consumer-virtual_visit_recommended"],
                "at_consumer-consultation_complete":
                  resData[i]["at_consumer-consultation_complete"],
                "at_responder_provider-daily_unresponded":
                  resData[i]["at_responder_provider-daily_unresponded"],
                "at_responder_provider-new_chat_notification":
                  resData[i]["at_responder_provider-new_chat_notification"],
                "at_submitter_provider-daily_unresponded":
                  resData[i]["at_submitter_provider-daily_unresponded"],
                "at_submitter_provider-new_chat_notification":
                  resData[i]["at_submitter_provider-new_chat_notification"],
                email: resData[i]["email"],
                is_email: resData[i]["is_email"],
                is_phone: resData[i]["is_phone"],
              });
              newPhoneNumbers.push(resData[i]["twilio_phone"]);
            }
          }
          this.setState({
            //closecodeFullyLoaded: true,
            configurationData: newData,
            allPhoneNumbers: newPhoneNumbers,
            isLoading: true,
          });
        }
      })
      .catch((error) => {
        message.error("Something went wrong");
        console.log("something went wrong...", error);
      });
  };

  showModal = (
    id,
    rowPhoneNumber,
    rowOrgId,
    rowConsumerWelcomeSms,
    rowConsumerInPersonVisit,
    rowConsumerVirtualVisit,
    rowConsumerConsultationComplete,
    rowResponderProviderDailyUnresponded,
    rowResponderProviderNewChatNotification,
    rowSubmitterProviderDailyUnresponded,
    rowSubmitterProviderNewChatNotification,
    rowEmailId
  ) => {
    this.setState({ rowPhoneNumber: "" });
    this.setState({ rowOrgId: "" });
    this.setState({ rowConsumerWelcomeSms: "" });
    this.setState({ rowConsumerInPersonVisit: "" });
    this.setState({ rowConsumerVirtualVisit: "" });
    this.setState({ rowConsumerConsultationComplete: "" });
    this.setState({ rowResponderProviderDailyUnresponded: "" });
    this.setState({ rowResponderProviderNewChatNotification: "" });
    this.setState({ rowSubmitterProviderDailyUnresponded: "" });
    this.setState({ rowSubmitterProviderNewChatNotification: "" });
    this.setState({ rowEmailId: "" });
    this.setState((prev) => {
      return {
        rowPhoneNumber: rowPhoneNumber,
        rowId: id,
        rowOrgId: rowOrgId,
        rowConsumerWelcomeSms: rowConsumerWelcomeSms,
        rowConsumerInPersonVisit: rowConsumerInPersonVisit,
        rowConsumerVirtualVisit: rowConsumerVirtualVisit,
        rowConsumerConsultationComplete: rowConsumerConsultationComplete,
        rowResponderProviderDailyUnresponded:
          rowResponderProviderDailyUnresponded,
        rowResponderProviderNewChatNotification:
          rowResponderProviderNewChatNotification,
        rowSubmitterProviderDailyUnresponded:
          rowSubmitterProviderDailyUnresponded,
        rowSubmitterProviderNewChatNotification:
          rowSubmitterProviderNewChatNotification,
        rowEmailId: rowEmailId,
        visible: !prev.visible,
      };
    });
  };

  setModalVisible = (args) => this.setState({ modalVisible: args });

  handleChange = (e, field) => {
    const { stateFormData } = this.state;

    if (field === "org_id") {
      console.log("testing1", field + "---", e);
      //this.setState({ org_id: e });
      this.setState({
        stateFormData: { ...stateFormData, [field]: e },
      });
      return;
    } else if (field === "phone_number") {
      //console.log("testing1", field + "---", e);
      //this.setState({ org_id: e });
      this.setState({
        stateFormData: { ...stateFormData, [field]: e },
      });
      return;
      //hola3
    } else if (field === "email_id") {
      //console.log("testing1", field + "---", e);
      //this.setState({ org_id: e });
      this.setState({
        stateFormData: { ...stateFormData, [field]: e },
      });
      return;
      //hola3
    } else if (field === "update_phone_number") {
      this.setState({
        rowPhoneNumber: e,
      });
    } else if (field === "update_email_id") {
      this.setState({
        rowEmailId: e,
      });
    } else if (field === "message_consumer_welcome_sms") {
      this.setState({
        stateFormData: { ...stateFormData, [field]: e },
      });
      return;
    } else if (field === "update_message_consumer_welcome_sms") {
      this.setState({
        rowConsumerWelcomeSms: e,
      });
    } else if (field === "message_consumer_in_person_visit_recommended") {
      this.setState({
        stateFormData: { ...stateFormData, [field]: e },
      });
      return;
    } else if (field === "update_consumer_in_person_visit_recommended") {
      this.setState({
        rowConsumerInPersonVisit: e,
      });
    } else if (field === "message_consumer_virtual_visit_recommended") {
      this.setState({
        stateFormData: { ...stateFormData, [field]: e },
      });
      return;
    } else if (field === "update_message_consumer_virtual_visit_recommended") {
      this.setState({
        rowConsumerVirtualVisit: e,
      });
    } else if (field === "message_consumer_consultation_complete") {
      this.setState({
        stateFormData: { ...stateFormData, [field]: e },
      });
      return;
    } else if (field === "update_message_consumer_consultation_complete") {
      this.setState({
        rowConsumerConsultationComplete: e,
      });
    } else if (field === "message_responder_provider_daily_unresponded") {
      this.setState({
        stateFormData: { ...stateFormData, [field]: e },
      });
      return;
    } else if (
      field === "update_message_responder_provider_daily_unresponded"
    ) {
      this.setState({
        rowResponderProviderDailyUnresponded: e,
      });
    } else if (field === "message_responder_provider_new_chat_notification") {
      this.setState({
        stateFormData: { ...stateFormData, [field]: e },
      });
      return;
    } else if (
      field === "update_message_responder_provider_new_chat_notification"
    ) {
      this.setState({
        rowResponderProviderNewChatNotification: e,
      });
    } else if (field === "message_submitter_provider_daily_unresponded") {
      this.setState({
        stateFormData: { ...stateFormData, [field]: e },
      });
      return;
    } else if (
      field === "update_message_submitter_provider_daily_unresponded"
    ) {
      this.setState({
        rowSubmitterProviderDailyUnresponded: e,
      });
    } else if (field === "message_submitter_provider_new_chat_notification") {
      this.setState({
        stateFormData: { ...stateFormData, [field]: e },
      });
      return;
    } else if (
      field === "update_message_submitter_provider_new_chat_notification"
    ) {
      this.setState({
        rowSubmitterProviderNewChatNotification: e,
      });
    } else if (field === "update_org_id") {
      this.setState({
        rowOrgId: e,
      });
    }
  };

  doSave() {
    console.log("unta ?", this.state.stateFormData);

    if (
      this.state.stateFormData.phone_number === "" ||
      this.state.stateFormData.org_id.length <= 0 ||
      this.state.stateFormData.message_consumer_welcome_sms === "" ||
      this.state.stateFormData.message_consumer_in_person_visit_recommended ===
        "" ||
      this.state.stateFormData.message_consumer_virtual_visit_recommended ===
        "" ||
      this.state.stateFormData.message_consumer_consultation_complete === "" ||
      this.state.stateFormData.message_responder_provider_daily_unresponded ===
        "" ||
      this.state.stateFormData
        .message_responder_provider_new_chat_notification === "" ||
      this.state.stateFormData.message_submitter_provider_daily_unresponded ===
        "" ||
      this.state.stateFormData
        .message_submitter_provider_new_chat_notification === "" ||
      this.state.stateFormData.email_id === ""
    ) {
      message.error("Please fill the mandatory fields ");
      return;
    }

    let body = {
      id: "",
      org_id: this.state.stateFormData.org_id,
      twilio_phone: this.state.stateFormData.phone_number,
      "at_consumer-welcome_SMS":
        this.state.stateFormData.message_consumer_welcome_sms,
      "at_consumer-in_person_visit_recommended":
        this.state.stateFormData.message_consumer_in_person_visit_recommended,
      "at_consumer-virtual_visit_recommended":
        this.state.stateFormData.message_consumer_virtual_visit_recommended,
      "at_consumer-consultation_complete":
        this.state.stateFormData.message_consumer_consultation_complete,
      "at_responder_provider-daily_unresponded":
        this.state.stateFormData.message_responder_provider_daily_unresponded,
      "at_responder_provider-new_chat_notification":
        this.state.stateFormData
          .message_responder_provider_new_chat_notification,
      "at_submitter_provider-daily_unresponded":
        this.state.stateFormData.message_submitter_provider_daily_unresponded,
      "at_submitter_provider-new_chat_notification":
        this.state.stateFormData
          .message_submitter_provider_new_chat_notification,
      email: this.state.stateFormData.email_id,
      is_email: 1,
      is_phone: 1,
    };

    console.log(body, "finalresponse");
    ApiService.adddetails
      .addTwilioMessageConfiguration(body)
      .then((data) => {
        if (data.status === 200) {
          message.success("Successfully added message configuration");
          this.setState({ modalVisible: false });
          this.setState({
            stateFormData: {
              org_id: [],
              phone_number: "",
              message_consumer_welcome_sms: "",
              message_consumer_in_person_visit_recommended: "",
              message_consumer_virtual_visit_recommended: "",
              message_consumer_consultation_complete: "",
              message_responder_provider_daily_unresponded: "",
              message_responder_provider_new_chat_notification: "",
              message_submitter_provider_daily_unresponded: "",
              message_submitter_provider_new_chat_notification: "",
            },
          });
          console.log("after sve", this.state.stateFormData);
          this.formRef.current.resetFields();
          this.fetchExistingConfiguration();
          this.props.history.push("/configure-messages");
        }
      })
      .catch((error) => {
        if (error.data.status === 708) {
          message.error("Configuration already exists");
        } else {
          message.error("Something went wrong");
        }
        console.log("caught_error", error);
      });
  }

  updateSave() {
    const pattern =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      this.state.rowPhoneNumber === "" ||
      this.state.rowConsumerWelcomeSms === "" ||
      this.state.rowConsumerInPersonVisit === "" ||
      this.state.rowConsumerVirtualVisit === "" ||
      this.state.rowConsumerConsultationComplete === "" ||
      this.state.rowResponderProviderDailyUnresponded === "" ||
      this.state.rowResponderProviderNewChatNotification === "" ||
      this.state.rowSubmitterProviderDailyUnresponded === "" ||
      this.state.rowSubmitterProviderNewChatNotification === ""
    ) {
      message.error("Please fill the mandatory fields ");
      return;
    } else if (
      this.state.rowPhoneNumber.length < 10 ||
      this.state.rowPhoneNumber.length > 10
    ) {
      message.error("Please enter a valid number");
    } else if (!pattern.test(this.state.rowEmailId)) {
      message.error("Please enter a valid Email ID");
    } else {
      let body = {
        id: this.state.rowId,
        org_id: this.state.rowOrgId,
        twilio_phone: this.state.rowPhoneNumber,
        "at_consumer-welcome_SMS": this.state.rowConsumerWelcomeSms,
        "at_consumer-in_person_visit_recommended":
          this.state.rowConsumerInPersonVisit,
        "at_consumer-virtual_visit_recommended":
          this.state.rowConsumerVirtualVisit,
        "at_consumer-consultation_complete":
          this.state.rowConsumerConsultationComplete,
        "at_responder_provider-daily_unresponded":
          this.state.rowResponderProviderDailyUnresponded,
        "at_responder_provider-new_chat_notification":
          this.state.rowResponderProviderNewChatNotification,
        "at_submitter_provider-daily_unresponded":
          this.state.rowSubmitterProviderDailyUnresponded,
        "at_submitter_provider-new_chat_notification":
          this.state.rowSubmitterProviderNewChatNotification,
        email: this.state.rowEmailId,
      };
      ApiService.adddetails
        .addTwilioMessageConfiguration(body)
        .then((data) => {
          if (data.status === 200) {
            message.success("Successfully updated message configuration");
            this.setState({ visible: false });
            this.fetchExistingConfiguration();
            this.props.history.push("/configure-messages");
          }
        })
        .catch((error) => {
          message.error("Something went wrong");
        });
    }
  }

  showOtpConfirm(id, status, type) {
    var that = this;
    if (type === "phone") {
      confirm({
        className: "popup-margin ",
        title:
          "Are you sure you want to" +
          " " +
          `${status === 1 ? "Turn off Phone OTP" : "Turn on Phone OTP"}` +
          " " +
          "",
        //   content: 'Some descriptions',
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        height: "158px",
        onOk() {
          that.switchOTP(id, status, type);
        },
        onCancel() {},
      });
    } else if (type === "email") {
      confirm({
        className: "popup-margin ",
        title:
          "Are you sure you want to" +
          " " +
          `${status === 1 ? "Turn off Email OTP" : "Turn on Email OTP"}` +
          " " +
          "",
        //   content: 'Some descriptions',
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        height: "158px",
        onOk() {
          that.switchOTP(id, status, type);
        },
        onCancel() {},
      });
    }
  }

  switchOTP(id, status, type) {
    let body;
    if (type === "phone") {
      body = {
        id: id,
        is_phone: status === 1 ? 0 : 1,
      };
    } else if (type === "email") {
      body = {
        id: id,
        is_email: status === 1 ? 0 : 1,
      };
    }
    console.log("preBody", body);
    ApiService.adddetails
      .addTwilioMessageConfiguration(body)
      .then((data) => {
        if (data.status === 200) {
          message.success("Successfully Updated");
          this.setState({ visible: false });
          this.fetchExistingConfiguration();
          this.props.history.push("/configure-messages");
        }
      })
      .catch((error) => {
        message.error("Something went wrong");
      });
    //console.log("hola5", id, status);
    // if (status === 1) {
    //   let body = {
    //     type: "otp",
    //     id: id,
    //     name: 0 + "",
    //   };
    //   ApiService.adddetails.subGroups(body).then((data) => {
    //     this.setState({ isLoading: false });
    //     if (data.status === 200) {
    //       message.success("Successfully disabled OTP for selected sub group");
    //       this.setState({ visible: false });
    //       this.fetchSubGroups();
    //       this.props.history.push("/SubGroups");
    //     }
    //   });
    // } else {
    //   let body = {
    //     type: "otp",
    //     id: id,
    //     name: 1 + "",
    //   };
    //   ApiService.adddetails.subGroups(body).then((data) => {
    //     this.setState({ isLoading: false });
    //     if (data.status === 200) {
    //       message.success("Successfully enabled OTP for selected sub group");
    //       this.setState({ visible: false });
    //       this.fetchSubGroups();
    //       this.props.history.push("/SubGroups");
    //     }
    //   });
    // }
  }

  // showDeleteModal(id) {
  //   var that = this;
  //   confirm({
  //     className: "popup-margin ",
  //     title: "Are you sure want to delete" + " " + "this Notification?",
  //     //   content: 'Some descriptions',
  //     okText: "Yes",
  //     okType: "danger",
  //     cancelText: "No",
  //     height: "158px",
  //     onOk() {
  //       console.log("id 1- test");
  //       that.deleteNotificationRowById(id);
  //     },
  //     onCancel() {},
  //   });
  // }

  // deleteNotificationRowById(idPassed) {
  //   let body = {
  //     type: "delete",
  //     id: idPassed,
  //   };
  //   //console.log("this is id", idPassed);
  //   ApiService.adddetails
  //     .addNotification(body)
  //     .then((data) => {
  //       //this.setState({ isLoading: false });
  //       if (data.status === 200) {
  //         message.success("Successfully deleted the notification");
  //         //this.setState({ visible: false });
  //         this.fetchExistingNotification();
  //         this.props.history.push("/in-app-notification");
  //       }
  //     })
  //     .catch((error) => {
  //       //this.setState({ isLoading: false });
  //       message.error("Something went wrong");
  //     });
  // }

  // handleOk = () => {
  //   this.setState({ loading: true });
  //   setTimeout(() => {
  //     this.setState({ loading: false, visible: false });
  //   }, 3000);
  // };

  setModalVisible = (args) => this.setState({ modalVisible: args });

  Tablecolumns = () => [
    {
      title: "Organization",
      dataIndex: "organization_name",
      width: "15%",
      // render: (url, record) => {
      //   return (
      //     <span className={record.status == 0 ? "hide" : "show"}>
      //       {record["organization_name"]}
      //     </span>
      //   );
      // },
      filters: filterData(
        this.state.orgData
          .map((item) => item.name)
          .filter((value, index, self) => self.indexOf(value) === index)
      ),
      onFilter: (value, record) => {
        return record["organization_name"] === value;
      },
    },
    {
      title: "Twilio Number",
      dataIndex: "phoneNumber",
      // render: (url, record) => {
      //   return (
      //     <span className={record.status == 0 ? "hide" : "show"}>
      //       {record["phoneNumber"]}
      //     </span>
      //   );
      // },
      width: "15%",
      filters: filterData(
        this.state.allPhoneNumbers
          .map((item) => item)
          .filter((value, index, self) => self.indexOf(value) === index)
      ),
      onFilter: (value, record) => {
        return record["phoneNumber"] === value;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      // render: (url, record) => {
      //   return (
      //     <span className={record.status == 0 ? "hide" : "show"}>
      //       {record["phoneNumber"]}
      //     </span>
      //   );
      // },
      width: "20%",
      // filters: filterData(
      //   this.state.allPhoneNumbers
      //     .map((item) => item)
      //     .filter((value, index, self) => self.indexOf(value) === index)
      // ),
      // onFilter: (value, record) => {
      //   return record["phoneNumber"] === value;
      // },
    },

    {
      title: "Phone",
      key: "action",
      width: "10%",
      render: (url, record) => {
        return (
          <span>
            {
              <li id="table-action">
                <Switch
                  style={{ margin: 10, marginLeft: -4 }}
                  // loading={this.state.rowLoader}
                  checked={
                    record["is_phone"] === 0 || record["is_phone"] === null
                      ? false
                      : true
                  }
                  onClick={() =>
                    this.showOtpConfirm(
                      record["id"],
                      record["is_phone"],
                      "phone"
                    )
                  }
                >
                  {}
                </Switch>
              </li>
            }
          </span>
        );
      },
    },

    {
      title: "Email",
      key: "action",
      width: "10%",
      render: (url, record) => {
        return (
          <span>
            {
              <li id="table-action">
                <Switch
                  style={{ margin: 10, marginLeft: -4 }}
                  // loading={this.state.rowLoader}
                  checked={
                    record["is_email"] === 0 || record["is_email"] === null
                      ? false
                      : true
                  }
                  onClick={() =>
                    this.showOtpConfirm(
                      record["id"],
                      record["is_email"],
                      "email"
                    )
                  }
                >
                  {}
                </Switch>
              </li>
            }
          </span>
        );
      },
    },

    {
      title: "Actions",
      width: "10%",
      render: (url, record) => {
        return (
          console.log("record", record.status),
          (
            <span className={record.status == 0 ? "hide" : "show"}>
              {
                <li id="table-action">
                  <Tooltip title="Edit">
                    <EditOutlined
                      id="table-edit"
                      onClick={() =>
                        this.showModal(
                          record["id"],
                          record["phoneNumber"],
                          record["organization_id"],
                          record["at_consumer-welcome_SMS"],
                          record["at_consumer-in_person_visit_recommended"],
                          record["at_consumer-virtual_visit_recommended"],
                          record["at_consumer-consultation_complete"],
                          record["at_responder_provider-daily_unresponded"],
                          record["at_responder_provider-new_chat_notification"],
                          record["at_submitter_provider-daily_unresponded"],
                          record["at_submitter_provider-new_chat_notification"],
                          record["email"]
                        )
                      }
                    ></EditOutlined>
                  </Tooltip>
                  {/* <div onClick={(e) => this.showDeleteModal(record["id"])}>
                    <Tooltip title="Delete">
                      <DeleteOutlined id="table-delete" />
                    </Tooltip>
                  </div> */}
                </li>
              }
            </span>
          )
        );
      },
    },
  ];

  render() {
    const { timeFrameData, orgData } = this.state;
    //console.log(codeData,'cc')
    if (timeFrameData) {
      let orgTag = timeFrameData.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
    }

    let listOrg =
      orgData.length > 0 &&
      orgData.map((items, i) => {
        return (
          <option key={items.org_id} value={items.org_id}>
            {items.name}
          </option>
        );
      }, this);

    return (
      <>
        <Row>
          <Col span={8} className="flexcenter">
            <h3 className="heading-font">Configure Messages</h3>
          </Col>
          <div className="closecode-row">
            <Link to={{ pathname: "" }}>
              <Button
                className="add-btn"
                id=""
                onClick={() => this.setModalVisible(true)}
              >
                Add Message
              </Button>
            </Link>
          </div>
        </Row>

        <Row>
          <Col span={24}>
            <div class="table-scroll">
              {/* <ReactDragListView {...dragProps}> */}
              <Table
                columns={this.Tablecolumns()}
                //rowKey={record => record.id}
                dataSource={this.state.configurationData}
                pagination={this.state.pagination}
                loading={!this.state.isLoading}
                //  onChange={this.handleTableChange}
              />
              {/* </ReactDragListView> */}
            </div>
          </Col>

          <Modal
            title="Create New Message"
            centered
            visible={this.state.modalVisible}
            okButtonProps={{ style: { display: "none" } }}
            onCancel={() =>
              this.setState({
                visible: !this.state.modalVisible,
              })
            }
            footer={[
              <div className="ant-modal-body-footer">
                <Button
                  key="back"
                  onClick={() =>
                    this.setState({
                      modalVisible: !this.state.modalVisible,
                      timeframeName: "",
                    })
                  }
                >
                  Return
                </Button>

                <Button
                  htmlType="submit"
                  key="submit"
                  type="primary"
                  onClick={() => this.doSave()}
                >
                  Submit
                </Button>
              </div>,
            ]}
          >
            <div className="row-4">
              <Form ref={this.formRef}>
                <div className="modal_speciality">
                  <div style={{ width: "100%", marginRight: 0 }}>
                    <div style={{ display: "flex", flexdirection: "column" }}>
                      <label style={{ marginRight: 5, color: "red" }}>*</label>
                      <label>Organization</label>
                    </div>
                    <Form.Item
                      name="Organization"
                      className="display"
                      rules={[
                        {
                          required: true,
                          message: "Please select Organization",
                        },
                      ]}
                    >
                      <Select
                        //mode="multiple"
                        className="text-select"
                        //mode="tags"
                        placeholder="Choose Organization"
                        onChange={(e) => this.handleChange(e, "org_id")}
                      >
                        {listOrg}
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className="modal_speciality">
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", flexdirection: "column" }}>
                      <label style={{ marginRight: 5, color: "red" }}>*</label>
                      <label>Phone Number</label>
                    </div>
                    <Form.Item
                      name="phoneNumber"
                      className="display"
                      rules={[
                        // {
                        //   required: true,
                        //   message: "Please enter Mobile number",
                        // },
                        {
                          pattern:
                            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,

                          message: "Please enter a valid Phone number",
                        },
                      ]}
                    >
                      <Input
                        className="text-input"
                        placeholder="Enter mobile number"
                        value={this.state.formData.phoneNumber}
                        onChange={(e) =>
                          this.handleChange(e.target.value, "phone_number")
                        }
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="modal_speciality">
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", flexdirection: "column" }}>
                      <label style={{ marginRight: 5, color: "red" }}>*</label>
                      <label>Email</label>
                    </div>
                    <Form.Item
                      name="emailId"
                      className="display"
                      rules={[
                        // {
                        //   required: true,
                        //   message: "Please enter Mobile number",
                        // },
                        {
                          pattern:
                            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

                          message: "Please enter a valid Email ID",
                        },
                      ]}
                    >
                      <Input
                        className="text-input"
                        placeholder="Enter Email ID"
                        value={this.state.formData.email_id}
                        onChange={(e) =>
                          this.handleChange(e.target.value, "email_id")
                        }
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="modal_speciality">
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", flexdirection: "column" }}>
                      <label style={{ marginRight: 5, color: "red" }}>*</label>
                      <label>@consumer - welcome SMS</label>
                    </div>
                    <Form.Item
                      name="message_consumer_welcome_sms"
                      className="display"
                      rules={[
                        {
                          required: true,
                          message: "Please enter message",
                        },
                        // {
                        //   pattern: /^[a-zA-Z -]*$/,
                        //   message: "Please enter valid content",
                        // },
                      ]}
                    >
                      <TextArea
                        id="message_consumer_welcome_sms"
                        className="text-select"
                        placeholder="Enter message"
                        onChange={(event) =>
                          this.handleChange(
                            event.target.value,
                            "message_consumer_welcome_sms"
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="modal_speciality">
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", flexdirection: "column" }}>
                      <label style={{ marginRight: 5, color: "red" }}>*</label>
                      <label>@consumer - in person visit recommended</label>
                    </div>
                    <Form.Item
                      name="message_consumer_in_person_visit_recommended"
                      className="display"
                      rules={[
                        {
                          required: true,
                          message: "Please enter message",
                        },
                        // {
                        //   pattern: /^[a-zA-Z -]*$/,
                        //   message: "Please enter valid content",
                        // },
                      ]}
                    >
                      <TextArea
                        id="message_consumer_in_person_visit_recommended"
                        className="text-select"
                        placeholder="Enter message"
                        onChange={(event) =>
                          this.handleChange(
                            event.target.value,
                            "message_consumer_in_person_visit_recommended"
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="modal_speciality">
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", flexdirection: "column" }}>
                      <label style={{ marginRight: 5, color: "red" }}>*</label>
                      <label>@consumer - virtual visit recommended</label>
                    </div>
                    <Form.Item
                      name="message_consumer_virtual_visit_recommended"
                      className="display"
                      rules={[
                        {
                          required: true,
                          message: "Please enter message",
                        },
                        // {
                        //   pattern: /^[a-zA-Z -]*$/,
                        //   message: "Please enter valid content",
                        // },
                      ]}
                    >
                      <TextArea
                        id="message_consumer_virtual_visit_recommended"
                        className="text-select"
                        placeholder="Enter message"
                        onChange={(event) =>
                          this.handleChange(
                            event.target.value,
                            "message_consumer_virtual_visit_recommended"
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="modal_speciality">
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", flexdirection: "column" }}>
                      <label style={{ marginRight: 5, color: "red" }}>*</label>
                      <label>@consumer - consultation complete</label>
                    </div>
                    <Form.Item
                      name="message_consumer_consultation_complete"
                      className="display"
                      rules={[
                        {
                          required: true,
                          message: "Please enter message",
                        },
                        // {
                        //   pattern: /^[a-zA-Z -]*$/,
                        //   message: "Please enter valid content",
                        // },
                      ]}
                    >
                      <TextArea
                        id="message_consumer_consultation_complete"
                        className="text-select"
                        placeholder="Enter message"
                        onChange={(event) =>
                          this.handleChange(
                            event.target.value,
                            "message_consumer_consultation_complete"
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="modal_speciality">
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", flexdirection: "column" }}>
                      <label style={{ marginRight: 5, color: "red" }}>*</label>
                      <label>@responder provider - daily unresponded</label>
                    </div>
                    <Form.Item
                      name="message_responder_provider_daily_unresponded"
                      className="display"
                      rules={[
                        {
                          required: true,
                          message: "Please enter message",
                        },
                        // {
                        //   pattern: /^[a-zA-Z -]*$/,
                        //   message: "Please enter valid content",
                        // },
                      ]}
                    >
                      <TextArea
                        id="message_responder_provider_daily_unresponded"
                        className="text-select"
                        placeholder="Enter message"
                        onChange={(event) =>
                          this.handleChange(
                            event.target.value,
                            "message_responder_provider_daily_unresponded"
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="modal_speciality">
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", flexdirection: "column" }}>
                      <label style={{ marginRight: 5, color: "red" }}>*</label>
                      <label>@responder provider - new chat notification</label>
                    </div>
                    <Form.Item
                      name="message_responder_provider_new_chat_notification"
                      className="display"
                      rules={[
                        {
                          required: true,
                          message: "Please enter message",
                        },
                        // {
                        //   pattern: /^[a-zA-Z -]*$/,
                        //   message: "Please enter valid content",
                        // },
                      ]}
                    >
                      <TextArea
                        id="message_responder_provider_new_chat_notification"
                        className="text-select"
                        placeholder="Enter message"
                        onChange={(event) =>
                          this.handleChange(
                            event.target.value,
                            "message_responder_provider_new_chat_notification"
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="modal_speciality">
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", flexdirection: "column" }}>
                      <label style={{ marginRight: 5, color: "red" }}>*</label>
                      <label>@submitter provider - daily unresponded</label>
                    </div>
                    <Form.Item
                      name="message_submitter_provider_daily_unresponded"
                      className="display"
                      rules={[
                        {
                          required: true,
                          message: "Please enter message",
                        },
                        // {
                        //   pattern: /^[a-zA-Z -]*$/,
                        //   message: "Please enter valid content",
                        // },
                      ]}
                    >
                      <TextArea
                        id="message_submitter_provider_daily_unresponded"
                        className="text-select"
                        placeholder="Enter message"
                        onChange={(event) =>
                          this.handleChange(
                            event.target.value,
                            "message_submitter_provider_daily_unresponded"
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="modal_speciality">
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", flexdirection: "column" }}>
                      <label style={{ marginRight: 5, color: "red" }}>*</label>
                      <label>@submitter provider - new chat notification</label>
                    </div>
                    <Form.Item
                      name="message_submitter_provider_new_chat_notification"
                      className="display"
                      rules={[
                        {
                          required: true,
                          message: "Please enter message",
                        },
                        // {
                        //   pattern: /^[a-zA-Z -]*$/,
                        //   message: "Please enter valid content",
                        // },
                      ]}
                    >
                      <TextArea
                        id="message_submitter_provider_new_chat_notification"
                        className="text-select"
                        placeholder="Enter message"
                        onChange={(event) =>
                          this.handleChange(
                            event.target.value,
                            "message_submitter_provider_new_chat_notification"
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          </Modal>

          {/* edit close code modal */}
          <Modal
            title="Edit Message"
            centered
            visible={this.state.visible}
            okButtonProps={{ style: { display: "none" } }}
            onCancel={() =>
              this.setState({
                visible: !this.state.visible,
              })
            }
            footer={[
              <div className="ant-modal-body-footer">
                <Button
                  key="back"
                  onClick={() =>
                    this.setState({
                      visible: !this.state.visible,
                      timeframeName: "",
                    })
                  }
                >
                  Return
                </Button>

                <Button
                  htmlType="submit"
                  key="submit"
                  type="primary"
                  onClick={() => this.updateSave()}
                >
                  Update
                </Button>
              </div>,
            ]}
          >
            {/* <div className="row-4">
              <div className="modal_speciality">
                <Input
                  className={"text-input" + " " + this.state.ErrorInputClass}
                  id=""
                  placeholder="Time Frame"
                  required
                  type="text"
                  value={this.state.time_frame_name}
                  onChange={(e) =>
                    this.updateChange(e.target.value, "timeframe")
                  }
                />
              </div>
            </div> */}

            <div className="modal_speciality">
              <div style={{ width: "100%", marginRight: 0 }}>
                <div style={{ display: "flex", flexdirection: "column" }}>
                  <label style={{ marginRight: 5, color: "red" }}>*</label>
                  <label>Organization</label>
                </div>
                {/* <Form.Item
                  name="Organization"
                  className="display"
                  rules={[
                    {
                      required: true,
                      message: "Please select Organization",
                    },
                  ]}
                > */}
                <Select
                  //mode="multiple"
                  className="text-select"
                  //mode="tags"
                  value={this.state.rowOrgId}
                  placeholder="Choose Organization"
                  onChange={(e) => this.handleChange(e, "update_org_id")}
                >
                  {listOrg}
                </Select>
                {/* </Form.Item> */}
              </div>
            </div>

            <div className="row-4">
              <div className="modal_speciality">
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", flexdirection: "column" }}>
                    <label style={{ marginRight: 5, color: "red" }}>*</label>
                    <label>Phone Number</label>
                  </div>
                  {/* <Form.Item
                    name="Mobile_Number"
                    className="display"
                    rules={[
                      // {
                      //   required: true,
                      //   message: "Please enter Mobile number",
                      // },
                      {
                        pattern:
                          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,

                        message: "Please enter a valid Phone number",
                      },
                    ]}
                  > */}
                  <Input
                    className="text-input"
                    edit
                    placeholder="Enter mobile number"
                    value={this.state.rowPhoneNumber}
                    onChange={(e) =>
                      this.handleChange(e.target.value, "update_phone_number")
                    }
                  />
                  {/* </Form.Item> */}
                </div>
              </div>

              <div className="modal_speciality">
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", flexdirection: "column" }}>
                    <label style={{ marginRight: 5, color: "red" }}>*</label>
                    <label>Email</label>
                  </div>
                  {/* <Form.Item
                    name="emailId"
                    className="display"
                    rules={[
                      // {
                      //   required: true,
                      //   message: "Please enter Mobile number",
                      // },
                      {
                        pattern:
                          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

                        message: "Please enter a valid Email ID",
                      },
                    ]}
                  > */}
                  <Input
                    className="text-input"
                    placeholder="Enter Email ID"
                    value={this.state.rowEmailId}
                    onChange={(e) =>
                      this.handleChange(e.target.value, "update_email_id")
                    }
                  />
                  {/* </Form.Item> */}
                </div>
              </div>

              {/* //hola4 */}
              <div className="modal_speciality">
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", flexdirection: "column" }}>
                    <label style={{ marginRight: 5, color: "red" }}>*</label>
                    <label>@consumer - welcome SMS</label>
                  </div>
                  {/* <Form.Item
                    name="notification_content"
                    className="display"
                    rules={[
                      {
                        required: true,
                        message: "Please enter message",
                      },
                      // {
                      //   pattern: /^[a-zA-Z -]*$/,
                      //   message: "Please enter valid content",
                      // },
                    ]}
                  > */}
                  <TextArea
                    id="notification_content"
                    className="text-select"
                    placeholder="Enter message"
                    value={this.state.rowConsumerWelcomeSms}
                    onChange={(event) =>
                      this.handleChange(
                        event.target.value,
                        "update_message_consumer_welcome_sms"
                      )
                    }
                  />
                  {/* </Form.Item> */}
                </div>
              </div>
              <div className="modal_speciality">
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", flexdirection: "column" }}>
                    <label style={{ marginRight: 5, color: "red" }}>*</label>
                    <label>@consumer - in person visit recommended</label>
                  </div>
                  {/* <Form.Item
                    name="notification_content"
                    className="display"
                    rules={[
                      {
                        required: true,
                        message: "Please enter message",
                      },
                      // {
                      //   pattern: /^[a-zA-Z -]*$/,
                      //   message: "Please enter valid content",
                      // },
                    ]}
                  > */}
                  <TextArea
                    id="notification_content"
                    className="text-select"
                    placeholder="Enter message"
                    value={this.state.rowConsumerInPersonVisit}
                    onChange={(event) =>
                      this.handleChange(
                        event.target.value,
                        "update_consumer_in_person_visit_recommended"
                      )
                    }
                  />
                  {/* </Form.Item> */}
                </div>
              </div>
              <div className="modal_speciality">
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", flexdirection: "column" }}>
                    <label style={{ marginRight: 5, color: "red" }}>*</label>
                    <label>@consumer - virtual visit recommended</label>
                  </div>
                  {/* <Form.Item
                    name="notification_content"
                    className="display"
                    rules={[
                      {
                        required: true,
                        message: "Please enter message",
                      },
                      // {
                      //   pattern: /^[a-zA-Z -]*$/,
                      //   message: "Please enter valid content",
                      // },
                    ]}
                  > */}
                  <TextArea
                    id="notification_content"
                    className="text-select"
                    placeholder="Enter message"
                    value={this.state.rowConsumerVirtualVisit}
                    onChange={(event) =>
                      this.handleChange(
                        event.target.value,
                        "update_message_consumer_virtual_visit_recommended"
                      )
                    }
                  />
                  {/* </Form.Item> */}
                </div>
              </div>
              <div className="modal_speciality">
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", flexdirection: "column" }}>
                    <label style={{ marginRight: 5, color: "red" }}>*</label>
                    <label>@consumer - consultation complete</label>
                  </div>
                  {/* <Form.Item
                    name="notification_content"
                    className="display"
                    rules={[
                      {
                        required: true,
                        message: "Please enter message",
                      },
                      // {
                      //   pattern: /^[a-zA-Z -]*$/,
                      //   message: "Please enter valid content",
                      // },
                    ]}
                  > */}
                  <TextArea
                    id="notification_content"
                    className="text-select"
                    placeholder="Enter message"
                    value={this.state.rowConsumerConsultationComplete}
                    onChange={(event) =>
                      this.handleChange(
                        event.target.value,
                        "update_message_consumer_consultation_complete"
                      )
                    }
                  />
                  {/* </Form.Item> */}
                </div>
              </div>
              <div className="modal_speciality">
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", flexdirection: "column" }}>
                    <label style={{ marginRight: 5, color: "red" }}>*</label>
                    <label>@responder provider - daily unresponded</label>
                  </div>
                  {/* <Form.Item
                    name="notification_content"
                    className="display"
                    rules={[
                      {
                        required: true,
                        message: "Please enter message",
                      },
                      // {
                      //   pattern: /^[a-zA-Z -]*$/,
                      //   message: "Please enter valid content",
                      // },
                    ]}
                  > */}
                  <TextArea
                    id="notification_content"
                    className="text-select"
                    placeholder="Enter message"
                    value={this.state.rowResponderProviderDailyUnresponded}
                    onChange={(event) =>
                      this.handleChange(
                        event.target.value,
                        "update_message_responder_provider_daily_unresponded"
                      )
                    }
                  />
                  {/* </Form.Item> */}
                </div>
              </div>
              <div className="modal_speciality">
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", flexdirection: "column" }}>
                    <label style={{ marginRight: 5, color: "red" }}>*</label>
                    <label>@responder provider - new chat notification</label>
                  </div>
                  {/* <Form.Item
                    name="notification_content"
                    className="display"
                    rules={[
                      {
                        required: true,
                        message: "Please enter message",
                      },
                      // {
                      //   pattern: /^[a-zA-Z -]*$/,
                      //   message: "Please enter valid content",
                      // },
                    ]}
                  > */}
                  <TextArea
                    id="notification_content"
                    className="text-select"
                    placeholder="Enter message"
                    value={this.state.rowResponderProviderNewChatNotification}
                    onChange={(event) =>
                      this.handleChange(
                        event.target.value,
                        "update_message_responder_provider_new_chat_notification"
                      )
                    }
                  />
                  {/* </Form.Item> */}
                </div>
              </div>
              <div className="modal_speciality">
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", flexdirection: "column" }}>
                    <label style={{ marginRight: 5, color: "red" }}>*</label>
                    <label>@submitter provider - daily unresponded</label>
                  </div>
                  {/* <Form.Item
                    name="notification_content"
                    className="display"
                    rules={[
                      {
                        required: true,
                        message: "Please enter message",
                      },
                      // {
                      //   pattern: /^[a-zA-Z -]*$/,
                      //   message: "Please enter valid content",
                      // },
                    ]}
                  > */}
                  <TextArea
                    id="notification_content"
                    className="text-select"
                    placeholder="Enter message"
                    value={this.state.rowSubmitterProviderDailyUnresponded}
                    onChange={(event) =>
                      this.handleChange(
                        event.target.value,
                        "update_message_submitter_provider_daily_unresponded"
                      )
                    }
                  />
                  {/* </Form.Item> */}
                </div>
              </div>
              <div className="modal_speciality">
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", flexdirection: "column" }}>
                    <label style={{ marginRight: 5, color: "red" }}>*</label>
                    <label>@submitter provider - new chat notification</label>
                  </div>
                  {/* <Form.Item
                    name="notification_content"
                    className="display"
                    rules={[
                      {
                        required: true,
                        message: "Please enter message",
                      },
                      // {
                      //   pattern: /^[a-zA-Z -]*$/,
                      //   message: "Please enter valid content",
                      // },
                    ]}
                  > */}
                  <TextArea
                    id="notification_content"
                    className="text-select"
                    placeholder="Enter message"
                    value={this.state.rowSubmitterProviderNewChatNotification}
                    onChange={(event) =>
                      this.handleChange(
                        event.target.value,
                        "update_message_submitter_provider_new_chat_notification"
                      )
                    }
                  />
                  {/* </Form.Item> */}
                </div>
              </div>
            </div>
          </Modal>
        </Row>
      </>
    );
  }
}
