import requestServer from "../request-server";
import CONFIG from "../config";

var internals = {};
// internals.login = function (email, password) {
internals.getallChat = function (data) {
  return requestServer(
    "/chat/session_for_admin",
    data,
    "POST",
    {},
    null,
    null,
    true,
    CONFIG.ADMIN_DOMAIN_NAME
  );
};

internals.filter = function (data) {
  return requestServer(
    "/chat/filter_session_for_admin",
    data,
    "POST",
    {},
    null,
    null,
    true,
    CONFIG.ADMIN_DOMAIN_NAME
  );
};

internals.getChats = function (data) {
  return requestServer(
    "/chat/get_chats",
    data,
    "POST",
    {},
    null,
    null,
    true,
    CONFIG.ADMIN_DOMAIN_NAME
  );
};

internals.getChatData = function (data) {
  return requestServer(
    "/chat/chat_datas",
    data,
    "POST",
    {},
    null,
    null,
    true,
    CONFIG.ADMIN_DOMAIN_NAME
  );
};

internals.downloadCsv = function (data) {
  return requestServer(
    "/chat/download_csv",
    data,
    "POST",
    {},
    null,
    null,
    true,
    CONFIG.ADMIN_DOMAIN_NAME
  );
};

export default internals;
