import React, { Component } from "react";
import {
  Row,
  Col,
  Input,
  Modal,
  Button,
  message,
  Table,
  Form,
  Tooltip,
  Select,
} from "antd";
import { EditOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import "./AfyaPrompts.css";
import { Link } from "react-router-dom";
import UserContext from "../../../UserContext";
import ApiService from "../../../services/api-services";
import TextArea from "antd/lib/input/TextArea";
//import Form from 'antd/lib/form/Form';
const FormItem = Form.Item;
const { Search } = Input;
var searchItem = [];
const { Option } = Select;
const { confirm } = Modal;
// const Add = 'Add'

export default class dashboard extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    var formData = {};
    this.state = {
      token: sessionStorage.getItem("token"),
      formData: formData,
      closecodeFullyLoaded: false,
      isFetchSpeciality: false,
      isLoading: false,
      questionName: "",
      answerName: "",
      type: "",
      visible: false,
      answerModalVisible: false,
      answersVisible: false,
      editQuestionModalVisible: false,
      editAnswerModalVisible: false,
      qtn_id: "",
      ans_id: "",
      org_id: "",
      question_id: "",
      order: "",
      question_name: "",
      answer_name: "",
      count: 0,
      answerData: [],
      inputs: [],
      answers: [],
      orgData: [],
      SpeclData: [],
      // value: 0,
      // codeData: [],
    };
  }

  // signOut() {
  //     const { setLoggin } = this.context
  //     setLoggin('false');
  //     this.props.history.push('/login')
  //     sessionStorage.setItem('isLoggedIn', false);
  // }
  componentDidMount() {
    if (this.state.token === null || this.state.token === "") {
      this.props.history.push("/login");
      window.location.reload(true);
    }
    this.fetchQuestions();
    this.fetchOrg();
    this.fetchSpeciality();
  }

  fetchQuestions = () => {
    let body = {
      section: "questions",
      action: "list",
      val: "",
      qtn_id: "",
      ans_id: "",
      type: "",
      org_id: this.state.org_id,
      spec_id: this.state.spec_id,
    };
    ApiService.adddetails
      .qAndA(body)
      .then((data) => {
        //console.log(data)
        this.setState({ isFetchSpeciality: true });
        if (data.status === 200) {
          let resData = Array.from(data.data);
          let newData = [];
          console.log(resData);

          for (let i = 0; i < resData.length; i++) {
            newData.push({
              id: resData[i]["id"],
              question: resData[i]["qtn"],
              type: resData[i]["type"],
              order: resData[i]["order"],
              createdAt: resData[i]["createdAt"],
              updatedAt: resData[i]["updatedAt"],
            });
          }
          this.setState({
            closecodeFullyLoaded: true,
            codeData: newData,
            isLoading: true,
          });
        }
      })
      .catch((error) => {
        this.setState({ isFetchSpeciality: false });
      });
  };

  showModal = (item, name) => {
    this.setState((prev) => {
      return {
        question_id: item,
        answersVisible: !prev.answersVisible,
      };
    });
    this.fetchAnswers(item);
  };
  showAnswerModal = (item, name) => {
    this.setState((prev) => {
      return {
        qtn_id: item,
        answerModalVisible: !prev.answerModalVisible,
      };
    });
  };
  showEditQuestion = (item, name, order, type) => {
    //alert(order);
    this.setState((prev) => {
      return {
        qtn_id: item,
        question_name: name,
        order: order,
        type: type,
        editQuestionModalVisible: !prev.editQuestionModalVisible,
      };
    });
  };

  showEditAnswer = (item, name) => {
    this.setState((prev) => {
      return {
        ans_id: item,
        answer_name: name,
        editAnswerModalVisible: !prev.editAnswerModalVisible,
      };
    });
  };

  handleChange = (e, field) => {
    if (field === "question") {
      this.setState({ questionName: e }, function () {});
    } else if (field === "answer") {
      this.setState({ answerName: e }, function () {});
    }
  };

  handleType = (value) => {
    console.log(value);
    this.setState((prev) => {
      return {
        type: value,
      };
    });
    console.log(this.state.type, "tty");
  };

  updateChange = (e, field) => {
    if (field === "question") {
      this.setState({ question_name: e }, function () {});
    } else if (field === "answer") {
      this.setState({ answer_name: e }, function () {});
    }
  };

  fetchAnswers = (item) => {
    const { question_id } = this.state;
    let body = {
      section: "answers",
      action: "list",
      val: "",
      qtn_id: item,
      ans_id: "",
      type: "",
    };
    ApiService.adddetails
      .qAndA(body)
      .then((data) => {
        //console.log(data)
        this.setState({ isFetchSpeciality: true });
        if (data.status === 200) {
          let resData = Array.from(data.data);
          let newData = [];
          console.log(resData, "answer");

          for (let i = 0; i < resData.length; i++) {
            newData.push({
              id: resData[i]["id"],
              answer: resData[i]["ans"],
              status: resData[i]["status"],
              createdAt: resData[i]["createdAt"],
              updatedAt: resData[i]["updatedAt"],
            });
          }
          this.setState({
            closecodeFullyLoaded: true,
            answerData: newData,
            isLoading: true,
          });
        }
      })
      .catch((error) => {
        this.setState({ isFetchSpeciality: false });
      });
  };

  fetchOrg = () => {
    ApiService.adddetails.getOrgList().then((data) => {
      if (data.status === 200) {
        //  console.log("tagOrg",data)
        this.setState({ orgData: data.data });
      }
    });
  };
  fetchSpeciality = () => {
    let body = {
      dob: "",
    };
    ApiService.adddetails.getSplType(body).then((data) => {
      if (data.status === 200) {
        const newData =
          (data.data && data.data.filter((x) => x.status !== 0)) || [];
        this.setState({
          SpeclData: newData,
        });
      }
    });
  };

  handleSelect = (e, field) => {
    const { formData } = this.state;
    if (field === "org_id") {
      console.log(e);

      this.setState({ org_id: e });
    } else if (field === "spec_id") {
      console.log(e);

      this.setState({ spec_id: e });
    } else if (field === "org") {
      console.log(e);

      this.setState({ org_id: e.value });
    } else {
      this.setState(
        {
          formData: { ...formData, [field]: e.target.value },
        },
        function () {}
      );
    }
  };

  doSave() {
    const { questionName, count, type, spec_id, org_id } = this.state;
    this.setState((prevState) => {
      return { count: prevState.count + 1 };
    });
    // let name = this.state.questionName;
    // let str = "/^[a-zA-Z ]*$/";
    // let patt = RegExp(/^[a-zA-Z0-9_@./#&+? ]*$/).test(name);
    // console.log(patt, "patt");
    // if (name === "" || patt === false) {
    //   message.error("Please Enter valid details ");
    //   return;
    // }
    let body = {
      section: "questions",
      action: "add",
      val:
        this.state.questionName.charAt(0).toUpperCase() +
        this.state.questionName.slice(1),
      qtn_id: "",
      ans_id: "",
      order: this.state.count,
      type: this.state.type,
      spec_id: this.state.spec_id,
      org_id: this.state.org_id,
    };
    console.log(body, "codes");
    // debugger
    ApiService.adddetails
      .qAndA(body)
      .then((data) => {
        this.setState({ isLoading: false });
        if (data.status === 200) {
          message.success("Successfully added the prompt question");
          this.setState({ setModalVisible: false });
          this.setState({
            modalVisible: false,
            questionName: "",
          });
          this.fetchQuestions();
          this.props.history.push("/afyaPrompts");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        // if (error.data.status === 702) {
        //   message.error("close code already exist");
        // }
      });
  }

  doSaveAnswer() {
    // alert('dosave')

    const { answerName, qtn_id, answers } = this.state;
    console.log(this.state.answers, "ans");
    // let name = this.state.answerName;
    // let str = "/^[a-zA-Z ]*$/";
    // let patt = RegExp(/^[a-zA-Z0-9 ]*$/).test(name);
    // console.log(patt, "patt");
    // if (name === "" || patt === false) {
    //   message.error("Please Enter valid details ");
    //   return;
    // }
    let body = {
      section: "answers",
      action: "add",
      val:
        this.state.answerName.charAt(0).toUpperCase() +
        this.state.answerName.slice(1),
      qtn_id: this.state.qtn_id,
      ans_id: "",
      order: 1,
      type: "",
    };
    console.log(body, "codes");
    // debugger
    ApiService.adddetails
      .qAndA(body)
      .then((data) => {
        this.setState({ isLoading: false });
        if (data.status === 200) {
          message.success("Successfully added the prompt answer");
          this.setState({ setModalVisible: false });
          this.setState({
            answerModalVisible: false,
            answerName: "",
          });
          this.fetchQuestions();
          this.props.history.push("/afyaPrompts");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        // if (error.data.status === 702) {
        //   message.error("close code already exist");
        // }
      });
  }

  showDeleteConfirm(qtn_id) {
    var that = this;

    confirm({
      className: "popup-margin ",
      title: "Are you sure want to" + " " + "delete" + " " + "this Question?",
      //   content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      height: "158px",
      onOk() {
        that.deleteQuestion(qtn_id);
      },
      onCancel() {},
    });
  }

  showDeleteAnswerConfirm(ans_id) {
    var that = this;

    confirm({
      className: "popup-margin ",
      title: "Are you sure want to" + " " + "delete" + " " + "this Answer?",
      //   content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      height: "158px",
      onOk() {
        that.deleteAnswer(ans_id);
      },
      onCancel() {},
    });
  }

  deleteQuestion(qtn_id) {
    let body = {
      section: "questions",
      action: "delete",
      qtn_id: qtn_id,
    };
    console.log(body, "codes");
    // debugger
    ApiService.adddetails
      .qAndA(body)
      .then((data) => {
        this.setState({ isLoading: false });
        if (data.status === 200) {
          message.success("Successfully deleted the prompt question");
          this.setState({ setModalVisible: false });
          this.fetchQuestions();
          this.props.history.push("/afyaPrompts");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  }

  deleteAnswer(ans_id) {
    let body = {
      section: "answers",
      action: "delete",
      ans_id: ans_id,
    };
    console.log(body, "codes");
    // debugger
    ApiService.adddetails
      .qAndA(body)
      .then((data) => {
        this.setState({ isLoading: false });
        if (data.status === 200) {
          message.success("Successfully deleted the answer");
          this.setState({ setModalVisible: false });
          this.fetchQuestions();
          this.props.history.push("/afyaPrompts");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  }

  updateQuestion() {
    // alert('dosave')

    const { question_name, order, qtn_id, type, org_id, spec_id } = this.state;
    // let name = this.state.question_name;
    // let str = "/^[a-zA-Z ]*$/";
    // let patt = RegExp(/^[a-zA-Z0-9_@./#&+? ]*$/).test(name);
    // console.log(patt, "patt");
    // if (name === "" || patt === false) {
    //   message.error("Please Enter valid details ");
    //   return;
    // }
    let body = {
      section: "questions",
      action: "edit",
      val:
        this.state.question_name.charAt(0).toUpperCase() +
        this.state.question_name.slice(1),
      qtn_id: this.state.qtn_id,
      ans_id: "",
      order: this.state.order,
      type: this.state.type,
      org_id: this.state.org_id,
      spec_id: this.state.spec_id,
    };
    console.log(body, "codes");
    // debugger
    ApiService.adddetails
      .qAndA(body)
      .then((data) => {
        this.setState({ isLoading: false });
        if (data.status === 200) {
          message.success("Successfully updated the prompt question");
          this.setState({ setModalVisible: false });
          this.setState({
            editQuestionModalVisible: false,
            question_name: "",
          });
          this.fetchQuestions();
          this.props.history.push("/afyaPrompts");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        // if (error.data.status === 702) {
        //   message.error("close code already exist");
        // }
      });
  }

  updateAnswer() {
    // alert('dosave')

    const { answer_name, order, ans_id } = this.state;
    // let name = this.state.answer_name;
    // let str = "/^[a-zA-Z0-9 ]*$/";
    // let patt = RegExp(/^[a-zA-Z0-9 ]*$/).test(name);
    // console.log(patt, "patt");
    // if (name === "" || patt === false) {
    //   message.error("Please Enter valid details ");
    //   return;
    // }
    let body = {
      section: "answers",
      action: "edit",
      val:
        this.state.answer_name.charAt(0).toUpperCase() +
        this.state.answer_name.slice(1),
      qtn_id: "",
      ans_id: this.state.ans_id,
      type: "",
    };
    console.log(body, "codes");
    // debugger
    ApiService.adddetails
      .qAndA(body)
      .then((data) => {
        this.setState({ isLoading: false });
        if (data.status === 200) {
          message.success("Successfully updated the prompt answer");
          this.setState({ setModalVisible: false });
          this.setState({
            editAnswerModalVisible: false,
            answersVisible: false,
            answer_name: "",
          });
          this.fetchQuestions();
          this.props.history.push("/afyaPrompts");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        // if (error.data.status === 702) {
        //   message.error("close code already exist");
        // }
      });
  }

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  setModalVisible = (args) => this.setState({ modalVisible: args });

  addClick = (ev) => {
    this.setState((prev) => ({ inputs: [...prev.inputs, ""] }));
  };

  handleAnswer = (field, value, i) => {
    const { inputs, answers } = this.state;
    let inputVal = value;
    let inputField = field;
    let roles = this.state.tagRole;

    if (inputField == "answer") {
      this.setState((prevState) => ({
        answers: [...prevState.answers, inputVal],
      }));
    }

    console.log(this.state.answers, "yttyrtrt");
    return;
  };

  renderfields = () => {
    const { inputs, answers } = this.state;
    // alert(item)
    return (
      <div className="labelone">
        {
          // this.state.tagRole.map(item => {
          this.state.inputs.map((item, i) => {
            console.log(this.state.inputs.length, "valueeee");
            // if(item.isVissible=== true){
            return (
              <div key={i}>
                <Form>
                  <Form.Item
                    label={"Answer : "}
                    style={{
                      display: "contents",
                      position: "static",
                      padding: "20px",
                      marginTop: "20px",
                    }}
                    name={i + "answer"}
                    required="true"
                    rules={[
                      // {
                      //   required: true,
                      //   message: "Please enter title",
                      // },
                      {
                        // pattern: /^[a-zA-Z_@./#&+- ]*$/,
                        pattern: /^[a-zA-Z0-9._^%$#!~@,-]*$/,
                        message: "Please enter a valid answer",
                      },
                    ]}
                  >
                    <Input
                      ref={this.formRef}
                      className="label-input"
                      placeholder=""
                      type="text"
                      value={item.ans || ""}
                      onBlur={(e) =>
                        this.handleAnswer("answer", e.target.value, i)
                      }
                    />
                    {/* <input
                      //key={item.role_id}
                      ref={this.formRef}
                      className="label-input"
                      placeholder=""
                      type="text"
                      onBlur={(e) =>
                        this.handleAnswer("answer", e.target.value, i)
                      }
                      //onChange={this.handleChange1.bind(this, item)}
                      value={item.ans || ""}
                    /> */}
                  </Form.Item>
                </Form>
              </div>
            );

            // }
          })

          // })
        }
      </div>
    );
  };

  Tablecolumns = () => [
    {
      title: "Prompt Questions",
      dataIndex: "question",
      // render: (question) => <>{question}</>,
      render: (url, record) => {
        return (
          <div
            style={{ cursor: "pointer", color: "#1890ff" }}
            onClick={() =>
              this.showEditQuestion(
                record["id"],
                record["question"],
                record["order"],
                record["type"]
              )
            }
          >
            {record["question"]}
          </div>
        );
      },
      width: "80%",
    },
    {
      title: "Action",
      key: "action",
      width: "20%",
      render: (url, record) => {
        return (
          <span>
            <li id="table-action">
              {record["type"] !== "YES_OR_NO" &&
              record["type"] !== "NORMAL" &&
              record["type"] !== "ATTACHMENT" ? (
                <Tooltip title="Edit Answers">
                  <EditOutlined
                    id="table-edit"
                    style={{ margin: 14 }}
                    onClick={() =>
                      this.showModal(record["id"], record["question"])
                    }
                  ></EditOutlined>
                </Tooltip>
              ) : (
                <span></span>
              )}
              {record["type"] !== "YES_OR_NO" &&
              record["type"] !== "NORMAL" &&
              record["type"] !== "ATTACHMENT" ? (
                <Tooltip title="Add Answer">
                  <PlusOutlined
                    id="table-edit"
                    style={{ margin: 14 }}
                    onClick={() =>
                      this.showAnswerModal(record["id"], record["question"])
                    }
                  ></PlusOutlined>
                </Tooltip>
              ) : (
                <span style={{ marginLeft: "5rem" }}></span>
              )}
              <Tooltip title="Delete Question">
                <DeleteOutlined
                  id="table-edit"
                  style={{ margin: 14 }}
                  onClick={() => this.showDeleteConfirm(record["id"])}
                ></DeleteOutlined>
              </Tooltip>
            </li>
          </span>
        );
      },
    },
  ];

  Tablecolumns1 = () => [
    {
      title: "Answers",
      dataIndex: "answer",
      render: (answer) => <>{answer}</>,
      width: "80%",
    },
    {
      title: "Action",
      key: "action",
      width: "20%",
      render: (url, record) => {
        return (
          <span>
            <div id="table-action">
              <Tooltip title="Edit Answer">
                <EditOutlined
                  id="table-edit"
                  style={{ margin: 14 }}
                  onClick={() =>
                    this.showEditAnswer(record["id"], record["answer"])
                  }
                ></EditOutlined>
              </Tooltip>
              <Tooltip title="Delete">
                <DeleteOutlined
                  id="table-edit"
                  style={{ margin: 14 }}
                  onClick={() => this.showDeleteAnswerConfirm(record["id"])}
                ></DeleteOutlined>
              </Tooltip>
            </div>
          </span>
        );
      },
    },
  ];

  render() {
    const { codeData, answerData, orgData, SpeclData } = this.state;
    //console.log(codeData,'cc')
    // if (codeData) {
    //   let orgTag = codeData.sort(function (a, b) {
    //     return a.question.localeCompare(b.question);
    //   });
    // }
    // if (answerData) {
    //   let orgTag = answerData.sort(function (a, b) {
    //     return a.answer.localeCompare(b.answer);
    //   });
    // }
    if (orgData) {
      let orgTag = orgData.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
    }
    if (SpeclData) {
      let orgTag = SpeclData.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
    }

    let listOrg =
      orgData.length > 0 &&
      orgData.map((items, i) => {
        return (
          <option key={items.org_id} value={items.org_id}>
            {items.name}
          </option>
        );
      }, this);

    let listOrgTag =
      orgData.length > 0 &&
      orgData.map((items, i) => {
        return (
          <option key={items.org_id} value={items.org_id}>
            {items.name}
          </option>
        );
      }, this);

    let listSpec =
      SpeclData.length > 0 &&
      SpeclData.map((items, i) => {
        return (
          <option key={items.spec_id} value={items.spec_id}>
            {items.name}
          </option>
        );
      }, this);

    let listSpecTag =
      SpeclData.length > 0 &&
      SpeclData.map((items, i) => {
        return (
          <option key={items.spec_id} value={items.spec_id}>
            {items.name}
          </option>
        );
      }, this);

    return (
      <>
        <Row>
          <Col span={8} className="flexcenter">
            <h3 className="heading-font">AFYA PROMPTS</h3>
          </Col>
          <div className="closecode-row">
            <Link to={{ pathname: "" }}>
              <Button
                className="add-btn"
                id=""
                onClick={() => this.setModalVisible(true)}
              >
                Add Question
              </Button>
            </Link>
          </div>
        </Row>
        <Row>
          <div style={{ width: "65%" }}>
            <Form>
              <Select
                showSearch
                labelInValue
                style={{ width: "45%" }}
                className="text-select"
                placeholder="Select Organization"
                optionFilterProp="children"
                onChange={(e) => this.handleSelect(e, "org")}
              >
                {listOrgTag}
              </Select>

              <span style={{ paddingRight: "10%" }}></span>

              <Select
                showSearch
                style={{ width: "45%" }}
                className="text-select"
                placeholder="Select Specialty"
                onChange={(e) => this.handleSelect(e, "spec_id")}
              >
                {listSpecTag}
              </Select>

              <Button
                className="filter-btn"
                id=""
                type="primary"
                htmlType="submit"
                onClick={() => this.fetchQuestions()}
                style={{ display: "flex", float: "left" }}
              >
                Submit
              </Button>
            </Form>
          </div>
        </Row>

        <Row>
          <Col span={24}>
            <div class="table-scroll" style={{ paddingTop: "1%" }}>
              <Table
                columns={this.Tablecolumns()}
                //rowKey={record => record.id}
                dataSource={this.state.codeData}
                pagination={this.state.pagination}
                // loading={!this.state.isLoading}
                //  onChange={this.handleTableChange}
              />
            </div>
          </Col>
          <Modal
            title="Add Question"
            centered
            visible={this.state.modalVisible}
            okButtonProps={{ style: { display: "none" } }}
            onCancel={() =>
              this.setState({
                modalVisible: !this.state.modalVisible,
                questionName: "",
              })
            }
            footer={[
              <div className="ant-modal-body-footer">
                <Button
                  key="back"
                  onClick={() =>
                    this.setState({
                      modalVisible: !this.state.modalVisible,
                      questionName: "",
                    })
                  }
                >
                  Return
                </Button>

                <Button
                  htmlType="submit"
                  key="submit"
                  type="primary"
                  onClick={() => this.doSave()}
                >
                  Submit
                </Button>
              </div>,
            ]}
          >
            <div className="row-4">
              <div className="modal_speciality">
                <Input
                  className={"text-input" + " " + this.state.ErrorInputClass}
                  id=""
                  placeholder="prompt question"
                  required
                  type="text"
                  value={this.state.questionName}
                  onChange={(e) =>
                    this.handleChange(e.target.value, "question")
                  }
                />
              </div>
              <div style={{ paddingTop: "10px" }}>
                <Select
                  className="text-select"
                  //mode="tags"
                  placeholder="Choose type"
                  onChange={this.handleType}
                >
                  <Option value="SINGLE_SELECT">SINGLE_SELECT</Option>
                  <Option value="MULTI_SELECT">MULTI_SELECT</Option>
                  <Option value="YES_OR_NO">YES/NO</Option>
                  <Option value="NORMAL">NORMAL</Option>
                  <Option value="ATTACHMENT">ATTACHMENT</Option>
                </Select>
              </div>
              <div style={{ paddingTop: "10px" }}>
                <Select
                  showSearch
                  style={{ width: "45%" }}
                  className="text-select"
                  placeholder="Organization"
                  onChange={(e) => this.handleSelect(e, "org_id")}
                >
                  {listOrg}
                </Select>
                <span style={{ paddingRight: "10%" }}></span>
                <Select
                  showSearch
                  style={{ width: "45%" }}
                  className="text-select"
                  placeholder="Specialty"
                  onChange={(e) => this.handleSelect(e, "spec_id")}
                >
                  {listSpec}
                </Select>
              </div>
            </div>
          </Modal>

          <Modal
            title="Edit Question"
            centered
            visible={this.state.editQuestionModalVisible}
            okButtonProps={{ style: { display: "none" } }}
            onCancel={() =>
              this.setState({
                editQuestionModalVisible: !this.state.editQuestionModalVisible,
                question_name: "",
              })
            }
            footer={[
              <div className="ant-modal-body-footer">
                <Button
                  key="back"
                  onClick={() =>
                    this.setState({
                      editQuestionModalVisible:
                        !this.state.editQuestionModalVisible,
                      question_name: "",
                    })
                  }
                >
                  Return
                </Button>

                <Button
                  htmlType="submit"
                  key="submit"
                  type="primary"
                  onClick={() => this.updateQuestion()}
                >
                  Submit
                </Button>
              </div>,
            ]}
          >
            <div className="row-4">
              <div className="modal_speciality">
                <Input
                  className={"text-input" + " " + this.state.ErrorInputClass}
                  id=""
                  placeholder="prompt question"
                  required
                  type="text"
                  value={this.state.question_name}
                  onChange={(e) =>
                    this.updateChange(e.target.value, "question")
                  }
                />
              </div>
              <div style={{ paddingTop: "10px" }}>
                <Select
                  className="text-select"
                  //mode="tags"
                  value={this.state.type}
                  placeholder="Choose type"
                  onChange={this.handleType}
                >
                  <Option value="SINGLE_SELECT">SINGLE_SELECT</Option>
                  <Option value="MULTI_SELECT">MULTI_SELECT</Option>
                  <Option value="YES_OR_NO">YES/NO</Option>
                  <Option value="NORMAL">NORMAL</Option>
                  <Option value="ATTACHMENT">ATTACHMENT</Option>
                </Select>
              </div>
            </div>
          </Modal>

          <Modal
            title="Add Answer"
            centered
            visible={this.state.answerModalVisible}
            okButtonProps={{ style: { display: "none" } }}
            onCancel={() =>
              this.setState({
                answerModalVisible: !this.state.answerModalVisible,
                answerName: "",
              })
            }
            footer={[
              <div className="ant-modal-body-footer">
                <Button
                  key="back"
                  onClick={() =>
                    this.setState({
                      answerModalVisible: !this.state.answerModalVisible,
                      answerName: "",
                    })
                  }
                >
                  Return
                </Button>
                ,
                <Button
                  htmlType="submit"
                  key="submit"
                  type="primary"
                  onClick={() => this.doSaveAnswer()}
                >
                  Submit
                </Button>
                ,
              </div>,
            ]}
          >
            <div className="row-4">
              <div className="modal_speciality">
                <Input
                  className={"text-input" + " " + this.state.ErrorInputClass}
                  id=""
                  placeholder="prompt answer"
                  required
                  type="text"
                  value={this.state.answerName}
                  onChange={(e) => this.handleChange(e.target.value, "answer")}
                />
              </div>
              {/* <Form>
                {this.state.answerModalVisible === true && this.renderfields()}
                <Form.Item>
                  <Button
                    type="primary"
                    //icon={<PlusOutlined />}
                    onClick={this.addClick.bind(this)}
                  >
                    Add Answer
                  </Button>
                </Form.Item>
              </Form> */}
            </div>
          </Modal>

          <Modal
            title="Answers"
            centered
            visible={this.state.answersVisible}
            okButtonProps={{ style: { display: "none" } }}
            onCancel={() =>
              this.setState({
                answersVisible: !this.state.answersVisible,
                answerName: "",
              })
            }
            footer={[
              <div className="ant-modal-body-footer">
                <Button
                  key="back"
                  onClick={() =>
                    this.setState({
                      answersVisible: !this.state.answersVisible,
                      answerName: "",
                    })
                  }
                >
                  Return
                </Button>
                ,
              </div>,
            ]}
          >
            <div className="row-4">
              <Col span={24}>
                <div class="table-scroll">
                  <Table
                    columns={this.Tablecolumns1()}
                    rowKey={(record) => record.id}
                    dataSource={this.state.answerData}
                    pagination={this.state.pagination}
                    loading={!this.state.isLoading}
                    //  onChange={this.handleTableChange}
                  />
                </div>
              </Col>
            </div>
          </Modal>

          {/* edit close code modal */}
          <Modal
            title="Edit Answer"
            centered
            visible={this.state.editAnswerModalVisible}
            okButtonProps={{ style: { display: "none" } }}
            onCancel={() =>
              this.setState({
                editAnswerModalVisible: !this.state.editAnswerModalVisible,
                answer_name: "",
              })
            }
            footer={[
              <div className="ant-modal-body-footer">
                <Button
                  key="back"
                  onClick={() =>
                    this.setState({
                      editAnswerModalVisible:
                        !this.state.editAnswerModalVisible,
                      answer_name: "",
                    })
                  }
                >
                  Return
                </Button>

                <Button
                  htmlType="submit"
                  key="submit"
                  type="primary"
                  onClick={() => this.updateAnswer()}
                >
                  Submit
                </Button>
              </div>,
            ]}
          >
            <div className="row-4">
              <div className="modal_speciality">
                <Input
                  className={"text-input" + " " + this.state.ErrorInputClass}
                  id=""
                  placeholder="prompt answer"
                  required
                  type="text"
                  value={this.state.answer_name}
                  onChange={(e) => this.updateChange(e.target.value, "answer")}
                />
              </div>
            </div>
          </Modal>
        </Row>
      </>
    );
  }
}
