import React, { Component } from "react";
import {
  Row,
  Col,
  Input,
  Modal,
  Button,
  message,
  Table,
  Form,
  Tooltip,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import "./closecode.css";
import { Link } from "react-router-dom";
import UserContext from "../../../UserContext";
import ApiService from "../../../services/api-services";
import TextArea from "antd/lib/input/TextArea";
//import Form from 'antd/lib/form/Form';
const FormItem = Form.Item;
const { Search } = Input;
var searchItem = [];
// const Add = 'Add'

export default class dashboard extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    var formData = {};
    this.state = {
      token: sessionStorage.getItem("token"),
      formData: formData,
      closecodeFullyLoaded: false,
      isFetchSpeciality: false,
      isLoading: false,
      closecodeName: "",
      description: "",
      visible: false,
      close_code_id: "",
      close_code_name: "",
      close_code_desc: "",
      // value: 0,
      // codeData: [],
    };
  }

  // signOut() {
  //     const { setLoggin } = this.context
  //     setLoggin('false');
  //     this.props.history.push('/login')
  //     sessionStorage.setItem('isLoggedIn', false);
  // }
  componentDidMount() {
    if (this.state.token === null || this.state.token === "") {
      this.props.history.push("/login");
      window.location.reload(true);
    }
    this.fetchCloseCode();
  }

  fetchCloseCode = () => {
    ApiService.adddetails
      .closeCodes()
      .then((data) => {
        //console.log(data)
        this.setState({ isFetchSpeciality: true });
        if (data.status === 200) {
          let resData = Array.from(data.data);
          let newData = [];
          console.log(resData);

          for (let i = 0; i < resData.length; i++) {
            newData.push({
              id: resData[i]["id"],
              close_code: resData[i]["close_code"],
              description: resData[i]["description"],
              logo_url: resData[i]["logo_url"],
              createdAt: resData[i]["createdAt"],
              updatedAt: resData[i]["updatedAt"],
            });
          }
          this.setState({
            closecodeFullyLoaded: true,
            codeData: newData,
            isLoading: true,
          });
        }
      })
      .catch((error) => {
        this.setState({ isFetchSpeciality: false });
      });
  };

  showModal = (item, name, desc) => {
    this.setState((prev) => {
      return {
        close_code_id: item,
        close_code_name: name,
        close_code_desc: desc,
        visible: !prev.visible,
      };
    });
  };
  // showMessage() {
  //     message.warn('Coming Soon...');
  // }

  // onChange(checked) {
  //     console.log(`switch to ${checked}`);
  //     let codeData = this.state.codeData;
  //     if(checked) {
  //         this.setState({
  //             codeData: {
  //                 ...codeData,
  //                 status: checked.target.value ? 1 : 0
  //             }
  //         })
  //         return
  //     }
  // }

  handleChange = (e, field) => {
    if (field === "closecode") {
      this.setState({ closecodeName: e }, function () {});
    } else if (field === "desc") {
      this.setState({ description: e }, function () {});
    }
  };

  updateChange = (e, field) => {
    if (field === "closecode") {
      this.setState({ close_code_name: e }, function () {});
    } else if (field === "desc") {
      this.setState({ close_code_desc: e }, function () {});
    }
  };

  doSave() {
    // alert('dosave')

    const { closecodeName, description } = this.state;
    let name = this.state.closecodeName;
    let desc = this.state.description;
    let str = "/^[a-zA-Z ]*$/";
    let patt = RegExp(/^[a-zA-Z ]*$/).test(name);
    let patt1 = RegExp(/^[a-zA-Z ]*$/).test(desc);
    console.log(patt, "patt");
    if (name === "" || patt === false || desc === "" || patt1 === false) {
      message.error("Please Enter valid details ");
      return;
    }
    let body = {
      close_code:
        this.state.closecodeName.charAt(0).toUpperCase() +
        this.state.closecodeName.slice(1),
      description: this.state.description,
    };
    console.log(body, "codes");
    // debugger
    ApiService.adddetails
      .addclosecode(body)
      .then((data) => {
        this.setState({ isLoading: false });
        if (data.status === 200) {
          message.success("Successfully added the Close Code");
          this.setState({ setModalVisible: false });
          this.setState({
            modalVisible: false,
            closecodeName: "",
            description: "",
          });
          this.fetchCloseCode();
          this.props.history.push("/CloseCode");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        if (error.data.status === 702) {
          message.error("close code already exist");
        }
      });
  }

  updateSave() {
    // alert('dosave')

    const { close_code_name, close_code_desc, close_code_id } = this.state;
    let name = this.state.close_code_name;
    let desc = this.state.close_code_desc;
    let str = "/^[a-zA-Z ]*$/";
    let patt = RegExp(/^[a-zA-Z ]*$/).test(name);
    let patt1 = RegExp(/^[a-zA-Z ]*$/).test(desc);
    console.log(patt, "patt");
    if (name === "" || patt === false || desc === "" || patt1 === false) {
      message.error("Please Enter valid details ");
      return;
    }
    let body = {
      close_code_id: this.state.close_code_id,
      name:
        this.state.close_code_name.charAt(0).toUpperCase() +
        this.state.close_code_name.slice(1),
      description: this.state.close_code_desc,
    };
    console.log(body, "codes");
    // debugger
    ApiService.adddetails
      .editclosecode(body)
      .then((data) => {
        this.setState({ isLoading: false });
        if (data.status === 200) {
          message.success("Successfully updated the Close Code");
          this.setState({ visible: false });
          this.fetchCloseCode();
          this.props.history.push("/CloseCode");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        if (error.data.status === 702) {
          message.error("close code already exist");
        }
      });
  }

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  setModalVisible = (args) => this.setState({ modalVisible: args });

  Tablecolumns = () => [
    {
      title: "Close Code",
      dataIndex: "close_code",
      render: (close_code) => <>{close_code}</>,
      width: "90%",
    },
    // {
    //     title: 'Date Added',
    //     dataIndex: '',
    //     width: '25%',

    // },
    // {
    //     title: 'Added By',
    //     dataIndex: '',
    //     width: '25%',

    // },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (url, record) => {
        return (
          <span>
            <div id="table-action">
              <Tooltip title="Edit">
                <EditOutlined
                  id="table-edit"
                  style={{ margin: 14 }}
                  onClick={() =>
                    this.showModal(
                      record["id"],
                      record["close_code"],
                      record["description"]
                    )
                  }
                ></EditOutlined>
              </Tooltip>
            </div>
          </span>
        );
      },
    },
  ];

  render() {
    const { codeData } = this.state;
    //console.log(codeData,'cc')
    if (codeData) {
      let orgTag = codeData.sort(function (a, b) {
        return a.close_code.localeCompare(b.close_code);
      });
    }

    return (
      <>
        <Row>
          <Col span={8} className="flexcenter">
            <h3 className="heading-font">CLOSE CODE</h3>
          </Col>
        </Row>
        <Row>
          <div className="search-close-code">
            <Search
              size="large"
              placeholder="Search close code"
              onSearch={(value) => console.log(value)}
              onKeyUp={(value) => {
                // console.log(value.target.value)
                ApiService.general
                  .search_close_code_permission({
                    type: "close_code",
                    key: value.target.value,
                  })
                  .then((res) => {
                    console.log(res);
                    if (res.data === null && value.target.value === "") {
                      this.props.history.push("/CloseCode");
                      console.log("hiias");
                    } else {
                      this.setState({ codeData: res.data, isLoading: true });
                      this.Tablecolumns();
                    }
                    // this.setView(res.data)
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            />
          </div>
          <div className="closecode-row">
            <Link to={{ pathname: "" }}>
              <Button
                className="adduser-btn"
                id=""
                onClick={() => this.setModalVisible(true)}
              >
                Add Closecode
              </Button>
            </Link>
          </div>
        </Row>

        <Row>
          <Col span={24}>
            <div class="table-scroll">
              <Table
                columns={this.Tablecolumns()}
                //rowKey={record => record.id}
                dataSource={this.state.codeData}
                pagination={this.state.pagination}
                loading={!this.state.isLoading}
                //  onChange={this.handleTableChange}
              />
            </div>
          </Col>
          <Modal
            title="Add CloseCode"
            centered
            visible={this.state.modalVisible}
            okButtonProps={{ style: { display: "none" } }}
            onCancel={() =>
              this.setState({
                modalVisible: !this.state.modalVisible,
                closecodeName: "",
                description: "",
              })
            }
            footer={[
              <div className="ant-modal-body-footer">
                <Button
                  key="back"
                  onClick={() =>
                    this.setState({
                      modalVisible: !this.state.modalVisible,
                      closecodeName: "",
                      description: "",
                    })
                  }
                >
                  Return
                </Button>

                <Button
                  htmlType="submit"
                  key="submit"
                  type="primary"
                  onClick={() => this.doSave()}
                >
                  Submit
                </Button>
              </div>,
            ]}
          >
            <div className="row-4">
              <div className="modal_speciality">
                <Input
                  className={"text-input" + " " + this.state.ErrorInputClass}
                  id=""
                  placeholder="Close Code"
                  required
                  type="text"
                  value={this.state.closecodeName}
                  onChange={(e) =>
                    this.handleChange(e.target.value, "closecode")
                  }
                />

                {/* <div>{this.state.ModalError ? (
                                    <>
                                        <div>{this.state.ErrorText}</div>
                                    </>
                                ) : null} </div> */}
                {/* <Button className="addSpeciality_button" type="primary" shape="round" size={"large"} onClick={() => this.handleApiCal('Addclosecode', null)}>{Add} </Button> */}
              </div>
            </div>
            <div className="row-4">
              <div className="modal_speciality">
                <TextArea
                  className={"text-input" + " " + this.state.ErrorInputClass}
                  id="description"
                  placeholder="Description"
                  required
                  value={this.state.description}
                  onChange={(e) => this.handleChange(e.target.value, "desc")}
                />
              </div>
            </div>
          </Modal>

          {/* edit close code modal */}
          <Modal
            title="Edit CloseCode"
            centered
            visible={this.state.visible}
            okButtonProps={{ style: { display: "none" } }}
            onCancel={() =>
              this.setState({
                visible: !this.state.visible,
              })
            }
            footer={[
              <div className="ant-modal-body-footer">
                <Button
                  key="back"
                  onClick={() =>
                    this.setState({
                      visible: !this.state.visible,
                      closecodeName: "",
                      description: "",
                    })
                  }
                >
                  Return
                </Button>

                <Button
                  htmlType="submit"
                  key="submit"
                  type="primary"
                  onClick={() => this.updateSave()}
                >
                  Submit
                </Button>
              </div>,
            ]}
          >
            <div className="row-4">
              <div className="modal_speciality">
                <Input
                  className={"text-input" + " " + this.state.ErrorInputClass}
                  id=""
                  placeholder="Close Code"
                  required
                  type="text"
                  value={this.state.close_code_name}
                  onChange={(e) =>
                    this.updateChange(e.target.value, "closecode")
                  }
                />
              </div>
            </div>
            <div className="row-4">
              <div className="modal_speciality">
                <TextArea
                  className={"text-input" + " " + this.state.ErrorInputClass}
                  id="description"
                  placeholder="Description"
                  required
                  value={this.state.close_code_desc}
                  onChange={(e) => this.updateChange(e.target.value, "desc")}
                />
              </div>
            </div>
          </Modal>
        </Row>
      </>
    );
  }
}
