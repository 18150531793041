import React from 'react'
import { Input, Form } from 'antd';

export default ({ handleChange }) => {
    return <>
        <Form.Item
            name="first_name"
            label="First Name"
            className="display"
            rules={[
                {
                    required: true,
                    message: 'Please enter your First Name'
                },
                {
                    pattern: /^[a-zA-Z -]*$/,
                    message: 'Please enter a valid Name'

                },
            ]}
        >

            <Input className='text-input'
                placeholder="First Name"
                onChange={(e) => handleChange(e.target.value, 'first_name')}
            />
        </Form.Item>


        <Form.Item
            name="last_name"
            label="Last Name"
            className="display"
            rules={[
                {
                    required: true,
                    message: 'Please enter your Last Name'
                },
                {
                    pattern: /^[a-zA-Z -]*$/,
                    message: 'Please enter a valid Name'

                },
            ]}
        >
            <Input className='text-input'
                placeholder="Last Name"
                onChange={(e) => handleChange(e.target.value, 'last_name')}

            />

        </Form.Item>
    </>
}