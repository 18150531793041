import React, { Component } from 'react';
import './PatientReplies.css'
import { Table, message, Row, Col, Modal } from 'antd';
import ApiService from '../../../services/api-services';
import moment from 'moment';

export default class patientReplies extends Component {

	constructor(props) {
		super(props);
		this.state = {
			dataSource: [],
			isLoading: false,
			chatVisible: false,
			chat: [],
			session_id: "",
		};
	}

	TableColumns = () => [
		{
			title: 'From',
			dataIndex: 'from',
			key: 'from',
			width: 300
		},
		{
			title: 'Message',
			dataIndex: 'message',
			key: 'message',
			width: 300
		},
		{
			title: 'Chat Session',
			key: 'sessions',
			dataIndex: 'sessions',
			render: (_, { sessions }) => (
				<>
					{sessions.map((session, key) => {
						return (
							<Row key={key}><a onClick={() => this.showChat(session)}>{session}</a></Row>
						);
					})}
				</>
			),
		},
		{
			title: 'Date',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (date) => moment(date).format('MM-DD-YYYY'),
			width: 300
		}
	];
	componentDidMount() {
		this.setState({ isLoading: true });
		ApiService.general.get_patient_replies().then((response) => {
			this.setState({ dataSource: response.data });
			this.setState({ isLoading: false });
		}).catch((err) => {
			console.log(err);
			this.setState({ isLoading: false });
			message.error(<h3>Error while getting patient replies</h3>)
		});
	}

	showChat = (session_id) => {
		// alert(session_id)
		this.setState({ chatVisible: true });
		this.setState({
			session_id: session_id,
		});
		this.fetchChat(session_id);
	};

	fetchChat = (session_id) => {
		const body = {
			session_id: session_id,
		};
		ApiService.Chatlist.getChats(body).then((data) => {
			if (data.status === 200) {
				if (data.data != null) {
					const resData = Array.from(data.data);
					const newData = [];
					for (let i = 0; i < resData.length; i++) {
						newData.push({
							id: resData[i]["id"],
							from_id: resData[i]["from_id"],
							from_name: resData[i]["from_name"],
							session_id: resData[i]["session_id"],
							datatype: resData[i]["datatype"],
							message: resData[i]["message"],
							speciality: resData[i]["speciality"],
							time: resData[i]["time"],
							createdAt: resData[i]["createdAt"],
							// org_id: resData[i]["org_id"],
							// org_type: resData[i]["org_type"]
						});
					}
					this.setState({ chat: newData });
				} else {
					this.setState({ chatVisible: false });
				}
			}
		});
	};

	render() {
		return (
			<>
				<div>
					<Row>
						<Col span={8} className="flexcenter">
							<h3 className='heading-font'>PATIENT REPLIES</h3>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<div className="table-scroll">
								<Table
									// rowkey="id"
									rowKey={row => row.id}
									dataSource={this.state.dataSource}
									columns={this.TableColumns()}
									loading={this.state.isLoading} />
							</div>
						</Col>
					</Row>
				</div>

				<Modal
					className=""
					title="Chat Dialogue"
					centered
					visible={this.state.chatVisible}
					okButtonProps={{ style: { display: "none" } }}
					onCancel={() =>
						this.setState({
							chatVisible: !this.state.chatVisible,
							specialityName: "",
						})
					}
				>
					<div className="chat-scroll">
						<div className="reverse">
							{this.state.chat.length > 0 &&
								this.state.chat.map((item, key) => {
									return (
										<div key={key}>
											<span style={{ fontWeight: "bold" }}>
												{item.from_name} :
											</span>{" "}
											{item.message} (
											<span style={{ fontStyle: "italic" }}>
												{item.createdAt}
											</span>
											)
										</div>
									);
								})}
						</div>
					</div>
				</Modal>
			</>
		)
	}
}