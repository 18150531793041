import React, { Component, lazy, Suspense } from 'react';
import {
    Row, Col,
    Button, Input, Layout, message,
    Form, Upload, Select, Checkbox,
    Tooltip, Popover, Modal, List, notification, Radio,
} from 'antd';
import { Container } from 'reactstrap';
import { Link, NavLink, Redirect } from 'react-router-dom';
import 'antd/dist/antd.css';
import '../Admin/admin.css'
import ApiService from '../../services/api-services'
import CONFIG from "../../services/config"
import UserContext from '../../UserContext';
const FormItem = Form.Item;
const { Option } = Select;
const items = [];

export default class AddAdmin extends Component {
    static contextType = UserContext

    loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

    constructor(props) {

        super(props);
        var org_id, type, formData = {};
        if (this.props.history.location.state !== undefined) {
            formData = {
                user_id:this.props.history.location.state.user_id,
                first_name: this.props.history.location.state.first_name,
                last_name: this.props.history.location.state.last_name,
                email: this.props.history.location.state.email,
                password: this.props.history.location.state.password,
                org_id: this.props.history.location.state.org_id,
                organization:this.props.history.location.state.organization,
                type:this.props.history.location.state.type,

            }

       //  this.formType = this.props.history.location.state.type
    } else {
        formData = {
            type: 'add',    

        }
    }
        this.state = {
            token: sessionStorage.getItem('token'),
            formData: formData,
            Orglist : [],
            rolelist: [],
            buttonName: '',
            formType: type,

        }
    }

    componentDidMount() {
        // console.log(this.state.formData,'user')
        // console.log(this.props.history.location.state.type,'11')
        const isLoggedIn = sessionStorage.getItem('isLoggedIn')
        if(this.state.token === null || this.state.token === '') {
            this.props.history.push('/login')
            window.location.reload(true)
        }
        if (isLoggedIn === 'false') {

            this.props.history.push('/login')
        }

       // this.loadInitalFiles()

        // console.log("abc",this.state.formData.type)

        this.getOrganization();
        this.getRoles();
        if (this.state.formData.type === 'edit') {

            this.setState({ buttonName: 'Update' });
        } else if (this.state.formData.type === 'delete') {
            this.setState({ buttonName: 'Delete', isDisabled: true });
        } else {
            this.setState({ buttonName: 'Save' });
        }

        message.config({ top: 150, duration: 2 })


    }


    validateForm() {
        // alert('asda')
        const data = this.state.formData;
        console.log('f', data);
        var regexp = new RegExp(/^[a-zA-Z0-9 ]+$/);

        if (data === '') {
            // message.warn("Please fill the details");
        }
        else {
            if (data.name === '') {
                message.warn("Please fill the details");
            }


            else {

                if (this.state.buttonName === 'Update') {
                    this.updateSave();
                } else if (this.state.buttonName === 'Save') {
                    //alert('save')
                    this.doSave(); //change this


                } else {
                    // this.deleteCompany();
                }

            }
        }
    }

    getOrganization() {
        ApiService.adddetails.getOrgList().then(data => {
            if (data.status === 200) {
                const newData = data.data.filter(item => {
                    if (item.name && item.org_id) {
                        if(item.status!==0){
                            return item
                        }
                      
                    }
                })
                this.setState({ Orglist: newData })
            }
        });
    }

    getRoles() {
        ApiService.adddetails.getRoleType().then(data => {
            if (data.status === 200) {
             //  console.log("tagOrg",data)
             let roleData = data.data.response.filter((item => item.role_name === 'Admin' || item.role_name === 'Session Admin'))
                this.setState({ rolelist: roleData })
            }
        });
    }

    handleChange = (e,field) => {
        console.log(e)
        const { formData } = this.state;

        if(field === 'first_name') {
            console.log(e)
            this.setState({
                formData: {...formData, [field]: e}
            });
            return
        } else if (field === 'last_name') {
            console.log(e)

            this.setState({

                formData: { ...formData, [field]: e }
            }, function () {

            });
        }  else if (field === 'email') {
            console.log(e)

            this.setState({

                formData: { ...formData, [field]: e }
            }, function () {

            });
        }  else if (field === 'password') {
            console.log(e)

            this.setState({

                formData: { ...formData, [field]: e }
            }, function () {

            });
        }  else if (field === 'org_id') {
            console.log(e)  

            this.setState({

                formData: { ...formData, [field]: e }
            }, function () {

            });
        }
        else if (field === 'role_id') {
            console.log(e)  

            this.setState({

                formData: { ...formData, [field]: e }
            }, function () {

            });
        }  else {
            this.setState({

                formData: { ...formData, [field]: e.target.value }
            }, function () {

            });

        } 
    }

    doSave() {
        //const {formData} = this.state;
       // console.log("firstname",formData)
        let body = {
            "first_name": this.state.formData.first_name.charAt(0).toUpperCase() + this.state.formData.first_name.slice(1),
            "last_name": this.state.formData.last_name.charAt(0).toUpperCase() + this.state.formData.last_name.slice(1),
            "email": this.state.formData.email,
            "password": this.state.formData.password,
            "org_id": this.state.formData.org_id,
            "role_id": this.state.formData.role_id,

        }
        console.log(body,'111')
        ApiService.adddetails.addAdmin(body).then(data => {
            this.setState({ isLoading: false })
            if (data.status === 200) {
                message.success('Successfully added the admin')
                this.props.history.push('/admin-list')

            }

        })
            .catch(error => {
                this.setState({ isLoading: false })

                if (error.data.status === 702) {
                    message.error('Email ID already exist');
                }

            });
    }

    updateSave() {
        this.setState({ isLoading: true })

        let body = {
            "user_id": this.state.formData.user_id,
            "first_name": this.state.formData.first_name.charAt(0).toUpperCase() + this.state.formData.first_name.slice(1),
            "last_name": this.state.formData.last_name.charAt(0).toUpperCase() + this.state.formData.last_name.slice(1),
            "email": this.state.formData.email,
            "password": this.state.formData.password,
            "org_id": this.state.formData.org_id,

        }
        console.log(body,'1111')

        ApiService.adddetails.editAdmin(body).then(data => {
            // alert("edit api")
           // console.log(data,'444')
            this.setState({ isLoading: false })
            if (data.status === 200) {
                message.success('Successfully updated the admin');
                this.props.history.push('/admin-list')
            } else if (data.statusCode === 500) {
                message.error('Company already exist');
            } else if (data.statusCode === 401) {
                this.props.history.push('/login')
            }
        })
            .catch(error => {
                this.setState({ isLoading: false })

                if (error.data.statusCode === 500) {
                    message.error('Company already exist');
                }

            });
    }



    render() { 
        
         const {Orglist, rolelist, formData} = this.state;
         console.log(this.state.formData, 'formdata1')
        let orgTag = Orglist.sort(function (a, b) {
            return a.name.localeCompare(b.name);

        });

        let roleTag = rolelist.sort(function (a, b) {
            return a.role_name.localeCompare(b.role_name);

        });

        let listOrg = orgTag.length > 0
        && orgTag.map((items, i) => {
            return (
                <option key={items.org_id} value={items.org_id}>{items.name}</option>

            )
        }, this);

        let listRole = roleTag.length > 0
        && roleTag.map((items, i) => {
            return (
                <option key={items.role_id} value={items.role_id}>{items.role_name}</option>

            )
        }, this);
        return (  
            <Container>
           {/* <div className='row-1'><h3 className='heading-font'>ADD ADMIN</h3></div> */}
            <Form
                autoComplete="off"
                onFinish={() => this.validateForm()}
                initialValues={{
                    first_name: this.state.formData.first_name,
                    last_name: this.state.formData.last_name,
                    email: this.state.formData.email,
                    password: this.state.formData.password,
                    Organization: this.state.formData.organization,
                    admintype: this.state.formData.role_id

                }}
            >
                   <FormItem
                            label='First Name'
                            name='first_name'
                            className="display"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a Name'
                                },
                                {
                                    pattern: /^[a-zA-Z -]*$/,
                                    message: 'Please enter a valid Name'

                                },
                            ]}

                        >


                            <Input className='text-input'
                                id=""
                                placeholder='First Name'
                                onChange={(e) => this.handleChange( e.target.value,'first_name')}
                            //onChange={() => this.onFinish()}
                            />
                           
                        </FormItem>
                    
                        <FormItem
                            label= 'Last Name'
                            name= 'last_name'
                            className="display"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a Name'
                                },
                                {
                                    pattern: /^[a-zA-Z -]*$/,
                                    message: 'Please enter a valid Name'

                                },
                            ]}

                        >


                            <Input className='text-input'
                                id=""
                                placeholder='Last Name'
                                 onChange={(e) => this.handleChange(e.target.value,'last_name')}
                            //onChange={() => this.onFinish()}
                            />
                           
                        </FormItem>
                        <Form.Item
                            label='Email Id'
                            name='email'
                            className="display"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter email'
                                },
                                {
                                   // pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                   //pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-]+.[a-zA-Z0-9-]+$/,
                                   pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: 'Please enter a valid email id'

                                },

                            ]}
                        >
                            <Input className='text-input'
                                id="" 
                                placeholder='Email Id'
                                // pattern="\d" //pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                 onChange={(e) => this.handleChange( e.target.value,'email')}
                            />
                        </Form.Item>
    
                        <Form.Item
                            label='Password'
                            name='password'
                            className="display"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter the password'
                                },
                                {
                                    // pattern: /^[A-Za-z]\w{7,14}$/,
                                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                                    message: 'Please enter a strong password (min 6 characters which contain special characters,characters, numeric digits  )'

                                },
                            ]}
                        >

                            <Input.Password className='text-input'
                                placeholder="Password"
                                onChange={(e) => this.handleChange(e.target.value,'password')}
                            />
                        </Form.Item>
                        <Form.Item
                                    label='Organization'
                                    name="Organization"
                                    className="display"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select Organization'
                                        },


                                    ]}
                                >
                                    <Select showSearch
                                        className='text-select'
                                        //mode="tags"
                                        placeholder="Please select Organization"
                                        onChange={(e) => this.handleChange(e,'org_id')}
                                    >
                                   {listOrg}
                                      
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label='Admin Type'
                                    name="adminType"
                                    className="display"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select type of admin'
                                        },


                                    ]}
                                >
                                    <Select showSearch
                                        className='text-select'
                                        placeholder="Please select type"
                                        onChange={(e) => this.handleChange(e,'role_id')}
                                    >
                                         {listRole}
                                      
                                    </Select>
                                </Form.Item>

               
                <Row className='button-padding'>
                {this.state.buttonName !== '' && (
                                <Button
                                    className='btn-type' id=''
                                    loading={this.state.isLoading} type='primary'
                                    htmlType="submit"
                                >
                                    {this.state.buttonName}
                                </Button>
                            )}
                        <Link to={{ pathname: '/admin-list' }}>
                   <Button className='btn-type' id='' type='primary'
                         >
                        Cancel
                    </Button>
                    </Link>

                </Row>


            </Form>

        </Container>

        );
    }
}