import React, { Component } from "react";
import { Route, Switch, BrowserRouter, NavLink } from "react-router-dom";
import "./App.css";
import Admin from "./views/Admin/admin";
import AdminManagement from "./views/Admin/AdminManagement/AdminManagement";
import AddDoctor from "./views/Admin/AddDoctor";
import AddOrganization from "./views/Admin/AddOrganization";
import AddAdmin from "./views/Admin/AddAdmin";
import AddStaffResponder from "./views/Admin/AddStaffResponder";
import AddConsumer from "./views/Admin/AddConsumer";
import DoctorList from "./views/Admin/DoctorList";
import OrganizationList from "./views/Admin/OrganizationList";
import ChatList from "./views/Admin/Chat/ChatContainer";
import Speciality from "./views/Admin/Speciality";
import PromoCode from "./views/Admin/PromoCode/PromoCode";
import Dashboard from "./views/Admin/Dashboard/dashboard";
import CloseCode from "./views/Admin/CloseCodes/CloseCode";
import UserDetails from "./views/Admin/UserDetails/UserDetails";
import TimeFrame from "./views/Admin/TimeFrame/TimeFrame";
import AfyaPrompts from "./views/Admin/AfyaPrompts/AfyaPrompts";
import Responder from "./views/Admin/Responder/Responder";
import ParticipantPermission from "./views/Admin/ParticipantPermission/ParticipantPermission";
import Staff from "./views/Admin/Staff";
import StaffResponder from "./views/Admin/StaffResponder/StaffResponder";
import Password from "./views/Admin/Password/Password";
// import AfyaPlan from './views/Admin/AfyaPlan/AfyaPlan'
import SubGroups from "./views/Admin/SubGroups/SubGroup";
import InAppNotification from "./views/Admin/InAppNotification/InAppNotification";
import ConfigureMessages from "./views/Admin/ConfigureMessages/ConfigureMessages";

import AfyaPlan from "./views/Admin/AfyaPlanB/index";
//import Consumer from './views/Admin/Consumer/ViewConsumer'
import Consumer from "./views/Admin/Consumer/Consumer";
import ApiService from "./services/api-services";
import login from "./assets/img/afyaNewLogo.png";
import { Layout, Button, message } from "antd";
import Menu from "./menus";

import { UserProvider } from "./UserContext";
import PatientReplies from "./views/Admin/PatientReplies/PatientReplies";
import AiAccess from "./views/Admin/AiAccess/AiAccess";
import OrgNotificationSettings from "./views/Admin/NotificationSettings/OrgNotificationSettings";
import UserNotificationSettings from "./views/Admin/NotificationSettings/UserNotificationSettings";
import firebase from "./services/firebase";

const { Content, Footer, Header, Sider } = Layout;

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

const Login = React.lazy(() => import("./views/Pages/Login"));

class App extends Component {
  constructor(props) {
    localStorage.clear();
    super(props);
    this.loggedIn = sessionStorage.getItem("isLoggedIn");
    this.state = {
      loggedId: sessionStorage.getItem("isLoggedIn"),
      isLoading: false,
    };
  }

  setLoggin = (val) => {
    if (val === "true") {
      sessionStorage.setItem("isLoggedIn", true);
      this.setState({ isLoading: true });
      window.location.reload(true);
    } else {
      sessionStorage.setItem("isLoggedIn", false);
    }
    this.setState({ loggedId: val });
  };

  getLogin = () => {
    return new Promise((res, rej) => {
      setTimeout(() => {
        res(this.state.loggedId);
      }, 1000);
    });
  };

  signOut() {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.performAppLogout();
        })
        .catch((error) => {
          console.error("Error signing out from Firebase:", error);
          message.error("F101-Something went wrong during Sign out");
        });
    } else {
      this.performAppLogout();
    }
  }

  performAppLogout() {
    ApiService.general
      .Logout({ id: sessionStorage.getItem("user_id") })
      .then(() => {
        this.setLoggin(false);
        this.props.history.push("/login");
        sessionStorage.clear();
        window.location.reload(true);
        this.setState({ isLoading: true });
      })
      .catch((err) => {
        console.error(err);
        message.error("Something went wrong during logout");
      });
  }

  showMessage = () => {
    message.warn("Coming Soon...");
  };

  render() {
    const { setLoggin, getLogin } = this;
    const { loggedId } = this.state;
    console.log(loggedId);

    return this.state.isLoading ? (
      <div fallback={loading()}></div>
    ) : (
      <UserProvider value={{ loggedId, setLoggin, getLogin }}>
        <BrowserRouter>
          <Layout>
            <Sider
              className="leftSidebar"
              width="280px"
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                height: "100vh",
                position: "fixed",
                left: 0,
              }}
            >
              {this.state.loggedId !== "true" ? (
                <>
                  <div className="login-text-sec">
                    <div
                      className="container"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingTop: "5%",
                      }}
                    >
                      <img
                        className="login-logo-sec"
                        // style={{
                        //   backgroundColor: "white",
                        //   // padding: "10%",
                        //   borderRadius: "15px",
                        //   maxWidth: "60%",
                        //   maxHeight: "60%",
                        // }}
                        src={login}
                      ></img>
                      <div>
                        <p className="login-left-text">
                          To put access to healthcare in the hands of Every
                          person in the world
                        </p>
                        <p className="login-left-sub-text">
                          Afya is your gateway to quickly, accurately and
                          conviniently diagnose
                        </p>
                      </div>
                    </div>
                  </div>{" "}
                </>
              ) : (
                <div className="SideMenu" style={{}}>
                  <div
                    className="container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      paddingTop: "5%",
                    }}
                  >
                    <img
                      className="signedin-logo-sec"
                      // style={{
                      //   backgroundColor: "white",
                      //   padding: "5%",
                      //   marginTop: "3%",
                      //   maxWidth: "80%",
                      //   maxHeight: "80%",
                      // }}
                      src={login}
                    ></img>
                  </div>
                  <Menu />
                </div>
              )}
            </Sider>
            <Layout className="site-layout" style={{ marginLeft: 280 }}>
              {this.props.history.location.pathname !== "/login" &&
                this.props.history.location.pathname !== "/" && (
                  <Header className="headers">
                    <div className="header-div">
                      {/* <div className="header-bell">
                            <BellFilled style={{ color: '#0252a1' }} />
                        </div> */}

                      <div className="header-user">
                        {/* <Avatar shape="square" size="large" icon={<UserOutlined />} style={{ borderRadius: '30%' }} /> */}
                        <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                          Welcome {sessionStorage.getItem("first_name")}{" "}
                          {sessionStorage.getItem("last_name")}
                        </span>
                      </div>

                      <div>
                        <Button
                          id="button-logout"
                          type="primary"
                          ghost
                          size="default"
                          onClick={() => this.signOut()}
                        >
                          Logout
                        </Button>
                      </div>
                    </div>
                  </Header>
                )}
              <Content
                className="mainContent"
                style={{ margin: "24px 16px 0", overflow: "initial" }}
              >
                <div
                  className="site-layout-background"
                  style={{ padding: 24, textAlign: "center" }}
                >
                  <React.Suspense fallback={loading()}>
                    <Switch>
                      {/* <Route exact path="/" name="test" render={props => <Test {...props} />} /> */}
                      <Route
                        exact
                        path="/"
                        name="Login Page"
                        render={(props) => <Login {...props} />}
                      />
                      <Route
                        exact
                        path="/login"
                        name="Login Page"
                        render={(props) => <Login {...props} />}
                      />
                      <Route
                        exact
                        path="/Dashboard"
                        name="Dashboard"
                        render={(props) => <Dashboard {...props} />}
                      />
                      <Route
                        exact
                        path="/CloseCode"
                        name="CloseCode"
                        render={(props) => <CloseCode {...props} />}
                      />
                      <Route
                        exact
                        path="/Responder"
                        name="Responder"
                        render={(props) => <Responder {...props} />}
                      />
                      <Route
                        exact
                        path="/ParticipantPermission"
                        name="ParticipantPermission"
                        render={(props) => <ParticipantPermission {...props} />}
                      />
                      <Route
                        exact
                        path="/chat-list"
                        name="ChatList"
                        render={(props) => <ChatList {...props} />}
                      />
                      <Route
                        exact
                        path="/speciality"
                        name="Speciality"
                        render={(props) => <Speciality {...props} />}
                      />
                      <Route
                        exact
                        path="/timeframe"
                        name="Timeframe"
                        render={(props) => <TimeFrame {...props} />}
                      />
                      <Route
                        exact
                        path="/PromoCode"
                        name="PromoCode"
                        render={(props) => <PromoCode {...props} />}
                      />
                      <Route
                        exact
                        path="/admin"
                        name="Admin"
                        render={(props) => <Admin {...props} />}
                      />
                      <Route
                        exact
                        path="/StaffResponder"
                        name="StaffResponder"
                        render={(props) => <StaffResponder {...props} />}
                      />
                      <Route
                        exact
                        path="/Password"
                        name="Password"
                        render={(props) => <Password {...props} />}
                      />
                      <Route
                        exact
                        path="/afyaPrompts"
                        name="afyaPrompts"
                        render={(props) => <AfyaPrompts {...props} />}
                      />
                      {/* <Route exact path="/AfyaPlan" name="Admin" render={props => <AfyaPlan {...props} />} /> */}
                      <Route
                        exact
                        path="/AfyaPlan"
                        name="Admin"
                        render={(props) => <AfyaPlan {...props} />}
                      />
                      <Route
                        exact
                        path="/SubGroups"
                        name="Admin"
                        render={(props) => <SubGroups {...props} />}
                      />
                      <Route
                        exact
                        path="/in-app-notification"
                        name="Admin"
                        render={(props) => <InAppNotification {...props} />}
                      />

                      <Route
                        exact
                        path="/configure-messages"
                        name="Admin"
                        render={(props) => <ConfigureMessages {...props} />}
                      />
                      <Route
                        exact
                        path="/ai-access"
                        name="Admin"
                        render={(props) => <AiAccess {...props} />}
                      />
                      <Route
                        exact
                        path="/org-notification-settings"
                        name="Admin"
                        render={(props) => (
                          <OrgNotificationSettings {...props} />
                        )}
                      />
                      <Route
                        exact
                        path="/user-notification-settings"
                        name="Admin"
                        render={(props) => (
                          <UserNotificationSettings {...props} />
                        )}
                      />
                      <Route
                        exact
                        path="/admin-list"
                        name="AdminManagement"
                        render={(props) => <AdminManagement {...props} />}
                      />
                      <Route
                        exact
                        path="/add-staff"
                        name="AddStaff"
                        render={(props) => <Staff {...props} />}
                      />
                      <Route
                        exact
                        path="/consumer-list"
                        name="Consumer"
                        render={(props) => <Consumer {...props} />}
                      />
                      <Route
                        exact
                        path="/user-details"
                        name="UserDetails"
                        render={(props) => <UserDetails {...props} />}
                      />
                      <Route
                        exact
                        path="/add-user"
                        name="AddDoctor"
                        render={(props) => <AddDoctor {...props} />}
                      />
                      <Route
                        exact
                        path="/add-orgn"
                        name="AddOrganization"
                        render={(props) => <AddOrganization {...props} />}
                      />
                      <Route
                        exact
                        path="/add-admin"
                        name="AddAdmin"
                        render={(props) => <AddAdmin {...props} />}
                      />
                      <Route
                        exact
                        path="/add-staffResponder"
                        name="AddStaffResponder"
                        render={(props) => <AddStaffResponder {...props} />}
                      />
                      <Route
                        exact
                        path="/add-consumer"
                        name="AddConsumer"
                        render={(props) => <AddConsumer {...props} />}
                      />
                      <Route
                        exact
                        path="/User-list"
                        name="DoctorList"
                        render={(props) => <DoctorList {...props} />}
                      />
                      <Route
                        exact
                        path="/organization-list"
                        name=" OrganizationList"
                        render={(props) => <OrganizationList {...props} />}
                      />
                      <Route
                        exact
                        path="/PatientReplies"
                        name="PatientReplies"
                        render={(props) => <PatientReplies {...props} />}
                      />
                    </Switch>
                  </React.Suspense>
                </div>
              </Content>
              <Footer style={{ textAlign: "center" }}></Footer>
            </Layout>
          </Layout>
        </BrowserRouter>
      </UserProvider>
    );
  }
}

export default App;
