import React, { Component, lazy, Suspense } from "react";
import {
  Row,
  Col,
  Avatar,
  Input,
  Button,
  Tooltip,
  Select,
  Table,
  Form,
  message,
  Modal,
} from "antd";
import { UserOutlined, BellFilled, EditOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import { Link, NavLink, Redirect } from "react-router-dom";
import moment from "moment";
import "./Password.css";
import { Container } from "reactstrap";
import CONFIG from "../../../services/config";
import UserContext from "../../../UserContext";
import ReactFileReader from "react-file-reader";
import ApiService from "../../../services/api-services";
const { Search } = Input;
var searchItem = [];
var currentDate = moment().format("MM-DD-YYYY");
const FormItem = Form.Item;

export default class dashboard extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    var user_id,
      type,
      formData = {};
    if (this.props.location.state != undefined) {
      formData = {
        user_id: this.props.location.state.user_id,
        type: this.props.location.state.type,
        first_name: this.props.location.state.first_name,
      };
    }
    this.state = {
      formData: formData,
      adminFullyLoaded: false,
      isFetchSpeciality: false,
      isLoading: false,
      min_password_length: "",
      visible: true,
      minPassword: "",
      id: "",
      token: sessionStorage.getItem("token"),
    };
  }

  componentDidMount() {
    if (this.state.token === null || this.state.token === "") {
      this.props.history.push("/login");
      window.location.reload(true);
    }
    ApiService.adddetails.getPasswordLength().then((data) => {
      console.log(data.data["id"]);
      if (data.status === 200) {
        this.setState({
          minPassword: data.data["min_password_length"],
          id: data.data["id"],
        });
      }
    });
  }

  PasswordLength = () => {
    const { minPassword, id } = this.state;
    console.log(this.state.minPassword, "pwd");
    if (this.state.minPassword === undefined) {
      this.addPasswordLength();
    } else {
      this.updatePasswordLength();
    }
  };

  addPasswordLength = () => {
    let patt = RegExp(/^[0-9]*$/).test(this.state.min_password_length);
    if (patt === false) {
      message.error("Please enter the proper length");
      return;
    }
    if (this.state.min_password_length < "13") {
      message.error("the password length should be minimum of 13 characters");
      return;
    }
    let body = {
      min_password_length: parseInt(this.state.min_password_length),
    };
    ApiService.adddetails.addPasswordLength(body).then((data) => {
      // console.log(data,'res')
      // this.setState({ isFetchSpeciality: true, isLoading: true })
      if (data.status === 200) {
        message.success("successfully added");
        this.props.history.push("/dashboard");
      }
    });
  };

  updatePasswordLength = () => {
    let patt = RegExp(/^[0-9]*$/).test(this.state.min_password_length);
    if (patt === false) {
      message.error("Please enter the proper length");
      return;
    }
    if (this.state.min_password_length < "13") {
      message.error("the password length should be minimum of 13 characters");
      return;
    }
    let body = {
      id: this.state.id,
      min_password_length: parseInt(this.state.min_password_length),
    };
    ApiService.adddetails.updatePasswordLength(body).then((data) => {
      if (data.status === 200) {
        message.success("successfully added");
        this.props.history.push("/dashboard");
      }
    });
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
    this.props.history.push("/dashboard");
  };

  handleChange = (field, e) => {
    const { formData } = this.state;
    if (field === "min_password_length") {
      console.log(e);

      this.setState({ min_password_length: e });
    } else {
      this.setState(
        {
          formData: { ...formData, [field]: e.target.value },
        },
        function () {}
      );
    }
  };

  render() {
    const { visible, minPassword } = this.state;
    console.log(this.state.minPassword, "min");

    return (
      <>
        <Modal
          className="staff_modal"
          // wrapClassName="staff_modal_wrap"
          centered
          visible={visible}
          title={<div>Password</div>}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <div>
              <Button key="back" onClick={this.handleCancel}>
                Return
              </Button>
              <Button
                htmlType="submit"
                key="submit"
                type="primary"
                onClick={() => this.PasswordLength()}
              >
                Submit
              </Button>
            </div>,
          ]}
        >
          <div>
            <FormItem
              name="min_password_length"
              label="Passwod Length"
              className="display"
              rules={[
                {
                  required: false,
                  message: "Please enter a length",
                },
                {
                  pattern: /^[0-9]*$/,

                  message: "Please enter a valid password length",
                },
              ]}
            >
              <Input
                className="text-input"
                id=""
                placeholder="length"
                value={this.state.minPassword}
                onChange={(e) =>
                  this.handleChange("min_password_length", e.target.value)
                }
              />
            </FormItem>
          </div>
        </Modal>
      </>
    );
  }
}
