import authAPIs from './APIs/auth-apis';
import generalAPIs from './APIs/general-apis';
import adddetailAPIs from './APIs/adddetails-apis';
import notificationsAPIs from './APIs/notifications-apis';
import viewallchatAPIs from './APIs/chatlist-api';
import searchAPIs from './APIs/search-api';


var apis = {
  auth: authAPIs,
  general: generalAPIs,
  notifications: notificationsAPIs,
  adddetails: adddetailAPIs,
  Chatlist: viewallchatAPIs,
  Search: searchAPIs,
}

export default apis;
