import React, { useState } from "react";
import { Input, Form, Button } from "antd";

export default ({ type, handleChange }) => {
  const [newPassword, setnewPass] = useState(false);
  return type === "add" ? (
    <>
      <Form.Item
        name="password"
        label="Password"
        className="display"
        rules={[
          {
            required: true,
            message: "Please enter the password",
          },
          {
            // pattern: /^[A-Za-z]\w{7,14}$/,
            //pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            pattern:
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\(\)_\+\-=\[\]\{\};:'",<>\./?\\|`~])(?=.{8,})/,
            message:
              "Please enter a strong password (min 6 characters which contains atleast 1 special character,1 upper case, 1 lowecase and 1 numeric digit )",
          },
        ]}
      >
        <Input.Password
          className="text-input"
          placeholder="Password"
          onChange={(e) => handleChange(e.target.value, "password")}
        />
      </Form.Item>
    </>
  ) : type === "edit" ? (
    <>
      {newPassword && (
        <Form.Item
          name="password"
          label="Password"
          className="display"
          rules={[
            {
              required: true,
              message: "Please enter the password",
            },
            {
              // pattern: /^[A-Za-z]\w{7,14}$/,
              pattern:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
              message:
                "Please enter a strong password (min 6 characters which contains atleast 1 special character,1 upper case, 1 lowecase and 1 numeric digit )",
            },
          ]}
        >
          <Input.Password
            className="text-input"
            placeholder="Password"
            onChange={(e) => handleChange(e.target.value, "password")}
          />
        </Form.Item>
      )}
      {/* <Form.Item>
                <Button type="primary"
                    shape="round"
                    onClick={() => setnewPass(d => !d)}
                    size="large" >
                    {!newPassword ? <>Update Password</> : (<>
                        Cancel
                   </>)}
                </Button>

            </Form.Item> */}
    </>
  ) : null;
};
