import requestServer from '../request-server';
import CONFIG from '../config'


var internals = {};

internals.search = function (data) {
    return requestServer('/search/chats', data, 'POST', {}, null, null, true, CONFIG.ADMIN_DOMAIN_NAME);
}




export default internals;