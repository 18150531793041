import React, { useState, Component } from "react";
import {
  Row,
  Col,
  Avatar,
  Input,
  Menu,
  Modal,
  Checkbox,
  Button,
  Divider,
  Tooltip,
  message,
  Select,
  Table,
  Form,
  Dropdown,
  Spin,
} from "antd";
import { Pagination } from "antd";
// import { Option } from "antd/lib/mentions";

import { CopyOutlined } from "@ant-design/icons";
import {
  UserOutlined,
  SearchOutlined,
  SlidersOutlined,
  AlertFilled,
  DeleteOutlined,
  UserAddOutlined,
  CloudDownloadOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import moment from "moment-timezone";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ApiService from "../../../services/api-services";
//import sortArray from 'sort-array'
// import arraySort from 'array-sort'
import "antd/dist/antd.css";
import "./chatstyle.css";
import UserContext from "../../../UserContext";
import TextArea from "antd/lib/input/TextArea";
//import TextArea from 'antd/lib/input/TextArea';
// import ChatItems from './ChatItem'
dayjs.extend(relativeTime);
const { Option } = Select;
const { Search } = Input;
const Clear = "Clear";
const Filter = "Filter";
//const ChatLists = 'Chat Lists'
const sort_by = "Org,User,Date,Specialty,Age of response,Close code";
const filter_by = "Unanswered Chats,Unread Chats,Live Chats";

const sortItems = sort_by.split(",");
const filterItems = filter_by.split(",");

// export function filterAndSortTableData(records, columns) {
// return records
// .filter(record => {
// for (let col of columns) {
// const { filteredValue, onFilter } = col;
// if (onFilter && filteredValue && filteredValue.length > 0) {
// if (filteredValue.filter(val => onFilter(val, record)).length === 0)
// return false;
// }
// }
// return true;
// })
// .sort((a, b) => {
// const sort = (col) => {
// const { sorter, sortOrder } = col;
// if (sorter && sorter instanceof Function && !isBool(sortOrder)) {
// return sorter(a, b, sortOrder);
// }
// return 0;
// };
// const sortColumn = columns.find(c => !!c.sortOrder);
// if (sortColumn)
// return sort(sortColumn);
// const defaultSortColumn = columns.find(c => !!c.defaultSortOrder);
// if (defaultSortColumn)
// return sort(defaultSortColumn);
// return 0;
// }).map((record, idx) => {
// const finalRecord = {};
// for (const col of columns) {
// const propName = col.dataIndex || col.key;
// if (propName) {
// const text = record[propName];
// if (col.render) {
// finalRecord[propName] = col.render(text, record, idx);
// }
// else {
// finalRecord[propName] = text;
// }
// }
// }
// return finalRecord;
// });
// }
// function isBool(x) {
// return typeof x === 'boolean';
// }

function myDate(s) {
  return new Date(s);
}

function getOrg(usrid) {
  return new Promise((res, rej) => {
    ApiService.general
      .ProvideOrg({ provider: usrid })
      .then((list) => {
        if (typeof list == "object" && Object.keys(list.data).length === 0) {
          res([]);
          return;
        }
        let respData = list.data;
        if (respData.length < 1) {
          res([]);
          return;
        }
        let data = list.data.map((item) => ({
          usrId: usrid,
          orgName: item.name,
          orgId: item.org_id,
        }));
        // localStorage.setItem(usrid, JSON.stringify(data))
        res(data);
      })
      .catch((error) => rej(error));
  });
}
export default class dashboard extends Component {
  static contextType = UserContext;
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.token = sessionStorage.getItem("token");
    console.log(this.token, "token");
    this.state = {
      rowSessionId: "",
      rowVideoLink: "",
      token: sessionStorage.getItem("token"),
      bagecount: 0,
      filtermenuVisible: false,
      sortmenuVisible: false,
      isFetchChatList: false,
      ChatSuccessFullyLoaded: false,
      loaded: false,
      generatedLink: "hello from korial",
      ChatData: [],
      chat: [],
      session_id: "",
      providerId: "",
      chatProviderId: "",
      providername: "",
      addProviderName: "",
      user_id: "",
      user_name: "",
      first_name: "",
      role: "",
      last_name: "",
      specId: "",
      sessionId: "",
      agoraId: "",
      chatAgoraId: "",
      ChatDataCopy: [],
      DateSortAsc: true,
      modalVisible: false,
      chatVisible: false,
      openGenerate: false,
      providerVisible: false,
      DelproviderVisible: false,
      AddproviderVisible: false,
      message1: false,
      modaltitle: "",
      modalContent: null,
      SortedSpeciality: "",
      specialityItem: [],
      pagination: {},
      closecode: [],
      csvData: [],
      providerDetails: [],
      chatProviderDetails: [],
      addProviderDetails: [],
      currentTableData: [],
      sortedInfo: null,
      generateNewLinkLoading: false,
      Filter: {
        "Unanswered Chats": false,
        "Unread Chats": false,
        "Live Chats": false,
      },
      fillterProps: {
        sort_recent_opened: "",
        filter_live: "",
        filter_close: "",
        filter_unread: "",
        filter_unanswered: "",
        speciality: "",
        org_id: "",
      },
      searchText: "",
      searchedColumn: "",
      //server-pagination
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 10,
      paginationFlag: false,
      //server-pagination-end
      //pagination column filter
      filterSearch: {},
      filterSearchVisibility: {
        chat_id: false,
        org_name: false,
        med_grp_name: false,
        sub_name: false,
        res_name: false,
        spec: false,
        patient_name: false,
        close_code: false,
      },
    };
  }
  handleTableChange = (pagination, filters, sorter, extra) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      currentTableData: extra.currentDataSource,
    });
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };
  fetch = (params = {}) => {
    console.log("params:", params);
    const pagination = { ...this.state.pagination };

    this.setState({ loading: true });
    pagination.total = 200;
    this.setState({
      loading: false,
      pagination,
    });
  };

  // handleReset = (clearFilters) => {
  //   clearFilters();
  //   this.setState({ searchText: "" });
  // };
  handleReset = async (column_key) => {
    await this.setState({
      filterSearch: {
        ...this.state.filterSearch,
        [column_key]: "",
      },
    });
    // if (this.state.ChatData.length <= 0) {
    //   this.fetchData();
    // }
    this.fetchData();
    //needs some logic here
  };

  handleFilterDropdownVisibleChange = (column_key) => {
    // if (visible) {
    //   setTimeout(() => this.searchInput?.select());
    // } else if (!visible) {
    //   console.log("he he ");
    // }

    this.setState({
      filterSearchVisibility: {
        ...this.state.filterSearchVisibility,
        [column_key]: !this.state.filterSearchVisibility[column_key],
      },
    });
  };
  getColumnSearchProps = (column_key, field) => {
    // const dataIndex = column_key == null ? "" : column_key;
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            placeholder={`Search ${field ? field : ""}`}
            value={this.state.filterSearch[column_key]}
            onChange={(e) =>
              // setSelectedKeys(e.target.value ? [e.target.value] : [])
              this.setState({
                filterSearch: {
                  ...this.state.filterSearch,
                  [column_key]: e.target.value,
                },
              })
            }
            onPressEnter={async () => {
              //this.setState({ currentPage: 1 });
              await this.setState({ currentPage: 1 });
              await this.handleFilterDropdownVisibleChange(column_key);
              await this.fetchData();
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={async () => {
              //this.setState({ currentPage: 1 });
              await this.setState({ currentPage: 1 });
              await this.handleFilterDropdownVisibleChange(column_key);
              await this.fetchData();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              this.handleReset(column_key);
              this.handleFilterDropdownVisibleChange(column_key);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center ",
          }}
          onClick={() => this.handleFilterDropdownVisibleChange(column_key)}
        >
          <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        </div>
      ),
      // onFilter: (value, record) =>
      //   record[dataIndex]
      //     .toString()
      //     .toLowerCase()
      // //     .includes(value.toLowerCase()),
      // onFilterDropdownVisibleChange: (visible) => {
      //   if (visible) {
      //     setTimeout(() => this.searchInput.select());
      //   }
      // },
      // onFilterDropdownVisibleChange:
      //   this.handleFilterDropdownVisibleChange(column_key),
      // render: (item) =>
      //   field === "name" ? (
      //     <>
      //       {/* {this.renderProfile({ url: item['ProviderImg'] })} */}
      //       {item["ProviderName"]}
      //     </>
      //   ) : (
      //     item
      //   ),
    };
  };

  //  downloadcsv = () => {
  //         let data = this.state.currentTableData
  //         if (data.length < 0) {
  //         data = this.state.ChatData
  //         }

  //  }

  loadSpecialityMaster = () => {
    let body = {
      dob: "",
    };

    ApiService.adddetails
      .getSplType(body)
      .then((data) => {
        if (data.status === 200) {
          if (data.data) {
            const newData =
              (data.data && data.data.filter((x) => x.status !== 0)) || [];
            this.setState({ specialityItem: newData });
          }
        }
      })
      .catch((err) => console.log(err));
  };

  loadorgChats = (id) => {
    let body = {
      sort_recent_opened: "",
      filter_live: "",
      filter_close: "",
      filter_unread: "",
      filter_unanswered: "",
      specialty: "",
      org_id: sessionStorage.getItem("org_id"),
    };

    ApiService.Chatlist.filter(body)
      .then((data) => {
        this.setState({ isFetchChatList: true });
        if (data.status === 200) {
          // this.setState({ ChatSuccessFullyLoaded: true, ChatData: data.data, ChatDataCopy: data.data })
          let resData = Array.from(data.data);
          let userId = [];
          let coll = {};
          let newData = [];
          console.log(resData, "org");

          for (let i = 0; i < resData.length; i++) {
            newData.push({
              ChatSessionId: resData[i]["id"],
              ResponderProviderId: resData[i]["owner2.user_id"],
              ResponderProviderName:
                resData[i]["owner2.first_name"] +
                " " +
                resData[i]["owner2.last_name"],
              SubmitterProviderName:
                resData[i]["owner1.first_name"] +
                " " +
                resData[i]["owner1.last_name"],
              ResponderProviderNpi: resData[i]["owner2.npi"],
              SubmitterProviderNpi: resData[i]["owner1.npi"],
              UserId: resData[i]["owner2.user_id"],
              ConsumerName:
                resData[i]["patient.first_name"] +
                " " +
                resData[i]["patient.last_name"],
              InsuranceNumber: resData[i]["patient.card_val"],
              ConsumerMPI: resData[i]["patient.MPI"],
              Speciality: resData[i]["spec_master.name"],
              SpecialityId: resData[i]["specialty"],
              ChiefComplaint: resData[i]["chief_complaint"] || "No Data",
              CreateAt: resData[i]["createdAt"],
              ProviderImg: resData[i]["image_url"],
              closeCodeId: resData[i]["close_code.id"],
              closeCode: resData[i]["close_code.close_code"],
              orgId: resData[i]["org_id"],
              chatId:
                resData[i]["afya_id"] === null
                  ? "No DATA"
                  : resData[i]["afya_id"],
              orgsName:
                resData[i]["organization.name"] === null
                  ? "No DATA"
                  : resData[i]["organization.name"],
              organization: resData[i]["organization.name"],
              medicalGroup:
                resData[i]["med_grp.name"] === null
                  ? "NO DATA"
                  : resData[i]["med_grp.name"],
              icd: resData[i]["ICD"],
              createdAt: resData[i]["createdAt"],
              updatedAt: resData[i]["updatedAt"],
              ConsumerDob: resData[i]["patient.dob"],
              Create: resData[i]["owner1.createdAt"],
              cpt: resData[i]["CPT"],
            });

            if (!userId.includes(resData[i]["owner2.user_id"])) {
              userId.push(resData[i]["owner2.user_id"]);
              coll[resData[i]["owner2.user_id"]] = {};
            }
          }

          this.setState({
            ChatSuccessFullyLoaded: true,
            ChatData: newData,
            ChatDataCopy: newData,
          });
          console.log(this.state.ChatData, "body");
          let keys = Object.keys(coll);
          let promizList = [];
          for (let i = 0; i < keys.length; i++) {
            let promiz = getOrg(keys[i]);
            promizList.push(promiz);
          }
          Promise.all(promizList)
            .then((res) => {
              let data = res.filter((x) => x.length !== 0);
              let closeCode = Array.from(this.state.closecode);

              for (let i = 0; i < resData.length; i++) {
                let drd = data.find(
                  (x) => x[0]["usrId"] === resData[i]["owner2.user_id"]
                );
                let clscd = closeCode.find(
                  (x) => x["id"] === resData[i]["close_code.id"]
                );

                if (drd) {
                  newData[i]["org_id"] = drd[0].orgId;
                  newData[i]["org_name"] = drd[0].orgName;
                } else {
                  newData[i]["org_id"] = "";
                  newData[i]["org_name"] = "";
                }
                if (clscd) {
                  newData[i]["close_code"] = clscd[0]["close_code"];
                } else {
                  newData[i]["close_code"] = "";
                }
              }

              this.setState({ ChatData: newData });
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((error) => {
        this.setState({ isFetchChatList: false });
      });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page }, this.fetchData);
  };
  loadChats = () => {
    let body = {
      page: this.state.currentPage,
      limit: this.state.itemsPerPage,
      chat_id: this.state.filterSearch.chat_id,
      org_name: this.state.filterSearch.org_name,
      med_grp_name: this.state.filterSearch.med_grp_name,
      sub_name: this.state.filterSearch.sub_name,
      res_name: this.state.filterSearch.res_name,
      spec: this.state.filterSearch.spec,
      patient_name: this.state.filterSearch.patient_name,
      close_code: this.state.filterSearch.close_code,
    };

    ApiService.Chatlist.getallChat(body)
      .then(async (data) => {
        this.setState({ isFetchChatList: true });
        if (data.status === 200) {
          // this.setState({ ChatSuccessFullyLoaded: true, ChatData: data.data, ChatDataCopy: data.data })
          let resData = Array.from(data.data.rows);
          this.setState({ totalItems: data.data.count });
          let userId = [];
          let coll = {};
          let newData = [];
          for (let i = 0; i < resData.length; i++) {
            newData.push({
              ChatSessionId: resData[i]["id"],
              ResponderProviderId: resData[i]["owner2.user_id"],
              ResponderProviderName:
                resData[i]["owner2.first_name"] +
                " " +
                resData[i]["owner2.last_name"],
              id: resData[i]["id"],
              video_call_link: resData[i]["video_call_link"],
              SubmitterProviderName:
                resData[i]["owner1.first_name"] +
                " " +
                resData[i]["owner1.last_name"],
              ResponderProviderNpi: resData[i]["owner2.npi"],
              SubmitterProviderNpi: resData[i]["owner1.npi"],
              UserId: resData[i]["owner2.user_id"],
              ConsumerName:
                resData[i]["patient.first_name"] +
                " " +
                resData[i]["patient.last_name"],
              InsuranceNumber: resData[i]["patient.card_val"],
              ConsumerMPI: resData[i]["patient.MPI"],
              Speciality: resData[i]["spec_master.name"],
              SpecialityId: resData[i]["specialty"],
              ChiefComplaint: resData[i]["clinical_question"] || "No Data",
              CreateAt: resData[i]["createdAt"],
              ProviderImg: resData[i]["image_url"],
              closeCodeId: resData[i]["close_code.id"],
              closeCode: resData[i]["close_code.close_code"] || "No Data",
              orgId: resData[i]["org_id"],
              orgsName:
                resData[i]["organization.name"] === null
                  ? "No DATA"
                  : resData[i]["organization.name"],
              organization: resData[i]["organization.name"],
              medicalGroup:
                resData[i]["med_grp.name"] === null
                  ? "NO DATA"
                  : resData[i]["med_grp.name"],
              icd: resData[i]["ICD"],
              createdAt: moment
                .tz(resData[i]["createdAt"], "America/Los_Angeles")
                .format("MM-DD-YYYY h:mm:ss A"),
              updatedAt:
                resData[i]["responder_responce_times"].length === 0 ||
                resData[i]["responder_responce_times"] === null
                  ? "NO DATA"
                  : moment
                      .tz(resData[i]["updatedTime"], "America/Los_Angeles")
                      .format("MM-DD-YYYY h:mm:ss A"),
              chatId:
                resData[i]["afya_id"] === null
                  ? "No DATA"
                  : resData[i]["afya_id"],
              ConsumerDob: resData[i]["patient.dob"],
              Create: resData[i]["owner1.createdAt"],
              cpt: resData[i]["CPT"],
              firstRespondAt:
                resData[i]["responder_responce_times"].length === 0 ||
                resData[i]["responder_responce_times"] === null
                  ? "NO DATA"
                  : moment
                      .tz(
                        resData[i]["responder_responce_times"][0]["timestamp"],
                        "America/Los_Angeles"
                      )
                      .format("MM-DD-YYYY h:mm:ss A"),
            });

            if (!userId.includes(resData[i]["owner2.user_id"])) {
              userId.push(resData[i]["owner2.user_id"]);
              coll[resData[i]["owner2.user_id"]] = {};
            }
          }

          this.setState({
            ChatData: newData,
            ChatDataCopy: newData,
          });
          console.log(this.state.ChatData["firstRespondAt"], "body");
          let keys = Object.keys(coll);
          let promizList = [];
          for (let i = 0; i < keys.length; i++) {
            let promiz = getOrg(keys[i]);
            promizList.push(promiz);
          }
          Promise.all(promizList)
            .then((res) => {
              let data = res.filter((x) => x.length !== 0);
              let closeCode = Array.from(this.state.closecode);

              for (let i = 0; i < resData.length; i++) {
                let drd = data.find(
                  (x) => x[0]["usrId"] === resData[i]["owner2.user_id"]
                );
                let clscd = closeCode.find(
                  (x) => x["id"] === resData[i]["close_code.id"]
                );

                if (drd) {
                  newData[i]["org_id"] = drd[0].orgId;
                  newData[i]["org_name"] = drd[0].orgName;
                } else {
                  newData[i]["org_id"] = "";
                  newData[i]["org_name"] = "";
                }
                if (clscd) {
                  newData[i]["close_code"] = clscd[0]["close_code"];
                } else {
                  newData[i]["close_code"] = "";
                }
              }

              this.setState({ ChatData: newData });
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((error) => {
        this.setState({ isFetchChatList: false });
      });
  };

  loadCloseCode = async () => {
    ApiService.adddetails
      .closeCodes()
      .then((res) => {
        if (res.status !== 200) {
          message.error("Server responded error for Close Code");
          return;
        }

        if (res.data.length < 0) {
          message.error("Close Code Data not found");
        }

        this.setState({ closecode: res.data });
      })
      .catch();
  };
  componentDidMount() {
    // if (this.state.token === null || this.state.token === "") {
    //   this.props.history.push("/login");
    //   window.location.reload(true);
    // }
    // sessionStorage.getItem("rolename") === "Super Admin" && this.loadChats();

    // sessionStorage.getItem("rolename") === "Admin" &&
    //   this.loadorgChats(sessionStorage.getItem("org_id"));

    // sessionStorage.getItem("rolename") === "Session Admin" &&
    //   this.loadorgChats(sessionStorage.getItem("org_id"));

    // this.loadCloseCode();
    // this.csvdownload();

    // const isLoggedIn = sessionStorage.getItem("isLoggedIn");

    // if (isLoggedIn === "false") {
    //   this.props.history.push("/login");
    // }
    this.fetchData();
  }

  fetchData = async () => {
    if (this.state.token === null || this.state.token === "") {
      this.props.history.push("/login");
      window.location.reload(true);
    }
    this.setState({ ChatSuccessFullyLoaded: false });
    const isLoggedIn = sessionStorage.getItem("isLoggedIn");

    if (isLoggedIn === "false") {
      this.props.history.push("/login");
    }

    const promises = [
      sessionStorage.getItem("rolename") === "Super Admin" && this.loadChats(),
      (sessionStorage.getItem("rolename") === "Admin" ||
        sessionStorage.getItem("rolename") === "Session Admin") &&
        this.loadorgChats(sessionStorage.getItem("org_id")),
      this.loadCloseCode(),
      this.loadSpecialityMaster(),
    ];

    try {
      await Promise.all(promises); // Wait for all API requests to complete
      sessionStorage.removeItem("userProfile"); // For uploading image

      // Set ChatSuccessFullyLoaded to true when everything is successfully loaded
      // this.setState({ ChatSuccessFullyLoaded: true });
    } catch (error) {
      message.error("Server Error, Something went wrong");
      console.error(error);
    }
    setTimeout(() => {
      this.setState({ ChatSuccessFullyLoaded: true });
    }, 1500);
  };

  handleSizeChange = (current, size) => {
    //console.log("woop");
    //console.log(`Page: ${current}, Size: ${size}`);
    this.setState({ itemsPerPage: size, currentPage: 1 }, this.fetchData);

    // Perform any action based on the new page size
    // For example, fetch data with the new page size
  };
  selectProvider = (e, itemagoraid, field) => {
    const user_id = e;
    const provider = this.state.providerDetails.find(
      (item) => item.user_id === user_id
    ); //to fetch the details based on user_id selected from an option
    console.log(provider["agora_id"], "eee");
    if (field === "provider") {
      // alert(e);
      this.setState({
        providerId: e,
      });
      this.setState({
        agoraId: provider["agora_id"],
      });
    }
  };

  selectChatProvider = (e, itemagoraid, field) => {
    if (field === "provider") {
      this.setState({
        chatProviderId: e,
      });
      this.setState({
        chatAgoraId: itemagoraid,
      });
    }
  };

  manualassign = (user_id, spec_id, session_id, agora_id) => {
    console.log(user_id, spec_id, session_id, agora_id, "specccc");
    let body = {
      speciality: spec_id,
      provider_id: user_id,
      session_id: session_id,
      agora_id: agora_id,
    };
    ApiService.adddetails.manualAssign(body).then((data) => {
      if (data.status === 200) {
        console.log(data.data["owner2.first_name"], "ddddsd");
        // for (let i = 0; i < resData.length; i++) {
        this.setState({
          providername:
            data.data["owner2.first_name"] + data.data["owner2.last_name"],
        });
        // }
        this.setState({
          providerVisible: false,
        });
        message.success("successfully reassigned");
        sessionStorage.getItem("rolename") === "Super Admin" &&
          this.loadChats();

        sessionStorage.getItem("rolename") === "Admin" &&
          this.loadorgChats(sessionStorage.getItem("org_id"));

        sessionStorage.getItem("rolename") === "Session Admin" &&
          this.loadorgChats(sessionStorage.getItem("org_id"));
        this.chatDatas(user_id, spec_id, session_id);
        this.csvdownload();
        // this.loadChats()
        // window.location.reload(true)
      }
    });
  };

  chatDatas = (user_id, spec_id, session_id) => {
    console.log(user_id, spec_id, session_id, "cccc");
    this.setState({
      first_name:
        sessionStorage.getItem("first_name") +
        sessionStorage.getItem("last_name"),
      role: sessionStorage.getItem("role_name"),
      last_name: sessionStorage.getItem("last_name"),
    });
    let body = {
      speciality: spec_id,
      from_id: sessionStorage.getItem("user_id"),
      session_id: session_id,
      from_name: sessionStorage.getItem("first_name"),
      datatype: "NOTES",
      message:
        this.state.first_name +
        " " +
        "has added" +
        " " +
        " Dr." +
        this.state.providername +
        " " +
        "to the chat",
      link: "",
      img_url: "",
    };
    console.log(body, "bbgg");
    ApiService.Chatlist.getChatData(body).then((data) => {
      if (data.status === 200) {
        this.setState({
          providerVisible: false,
        });
        sessionStorage.getItem("rolename") === "Super Admin" &&
          this.loadChats();

        sessionStorage.getItem("rolename") === "Admin" &&
          this.loadorgChats(sessionStorage.getItem("org_id"));

        sessionStorage.getItem("rolename") === " Session Admin" &&
          this.loadorgChats(sessionStorage.getItem("org_id"));
        this.csvdownload();
      }
    });
  };

  Banner = (user_id, spec_id, session_id) => {
    console.log(user_id, spec_id, session_id, "cccc");
    this.setState({
      first_name:
        sessionStorage.getItem("first_name") +
        sessionStorage.getItem("last_name"),
      role: sessionStorage.getItem("role_name"),
      last_name: sessionStorage.getItem("last_name"),
    });
    let body = {
      speciality: spec_id,
      from_id: sessionStorage.getItem("user_id"),
      session_id: session_id,
      from_name: sessionStorage.getItem("first_name"),
      datatype: "NOTES",
      message:
        " Dr." +
        this.state.providername +
        " " +
        "has been removed" +
        " " +
        "from the chat by" +
        " " +
        this.state.first_name,

      link: "",
      img_url: "",
    };
    console.log(body, "bbgg");
    ApiService.Chatlist.getChatData(body).then((data) => {
      if (data.status === 200) {
        this.setState({
          providerVisible: false,
        });
        sessionStorage.getItem("rolename") === "Super Admin" &&
          this.loadChats();

        sessionStorage.getItem("rolename") === "Admin" &&
          this.loadorgChats(sessionStorage.getItem("org_id"));

        sessionStorage.getItem("rolename") === " Session Admin" &&
          this.loadorgChats(sessionStorage.getItem("org_id"));
        this.csvdownload();
      }
    });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  openChat = (session_id) => {
    // alert(session_id)
    this.setState({ chatVisible: true });
    this.setState({
      session_id: session_id,
    });
    this.chatdialogue(session_id);
  };

  openGenerateLink = (session_id, video_call_link) => {
    // alert(session_id)
    this.setState({ rowSessionId: session_id, rowVideoLink: video_call_link });
    this.setState({ openGenerate: true });

    // this.setState({
    //   session_id: session_id,
    // });
  };

  showProvider = (params) => {
    console.log(params, "ppppp");
    this.autoAssign(params);
    this.setState({
      providerVisible: true,
      sessionId: params.session_id,
      specId: params.specialty,
    });
    console.log(this.state.specId, "ppp");
  };

  showChatProvider = (params) => {
    console.log(params);
    this.sessionDetails(params);
    this.setState({
      DelproviderVisible: true,
      sessionId: params.session_id,
    });
    // console.log(params,'ppp')
  };

  addChatProvider = (params) => {
    console.log(params, "orgggg");
    this.addUserDetails(params);
    this.setState({
      AddproviderVisible: true,
      sessionId: params.session_id,
    });
    // console.log(params,'ppp')
  };

  handleCancel1 = () => {
    this.setState({ DelproviderVisible: false });
  };

  handleCancel = () => {
    this.setState({ providerVisible: false });
  };
  generateLinkCall = (session_id) => {
    this.setState({ generateNewLinkLoading: true });
    let body = {
      session_id: session_id,
    };
    ApiService.adddetails
      .addVideoCallLink(body)
      .then((data) => {
        //this.setState({ generatedLink: data.d });
        if (data.status === 200) {
          this.setState({
            rowVideoLink: data.data.video_call_link,
            generateNewLinkLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ generateNewLinkLoading: false });
      });
  };

  chatdialogue = (session_id) => {
    let body = {
      session_id: session_id,
    };
    ApiService.Chatlist.getChats(body).then((data) => {
      console.log(data, "gg");
      if (data.status === 200) {
        if (data.data != null) {
          let resData = Array.from(data.data);
          let newData = [];
          for (let i = 0; i < resData.length; i++) {
            newData.push({
              id: resData[i]["id"],
              from_id: resData[i]["from_id"],
              from_name: resData[i]["from_name"],
              session_id: resData[i]["session_id"],
              datatype: resData[i]["datatype"],
              message: resData[i]["message"],
              speciality: resData[i]["speciality"],
              time: resData[i]["time"],
              createdAt: moment
                .tz(resData[i]["createdAt"], "America/Los_Angeles")
                .format("MM-DD-YYYY h:mm:ss A"), //local() method is used to convert IST to UTC or reverse
              // org_id: resData[i]["org_id"],
              // org_type: resData[i]["org_type"]
            });
          }
          this.setState({ chat: newData });
        } else {
          this.setState({ chatVisible: false });
        }
      }
      console.log(this.state.chat, "gt");
    });
  };

  Tablecolumns = () => [
    {
      title: "Chat Id",
      dataIndex: "chatId",
      ...this.getColumnSearchProps("chat_id", "Chat Id"),
      filterDropdownVisible: this.state.filterSearchVisibility.chat_id,
      width: 100,
    },
    {
      title: "Video Link",
      dataIndex: [],
      //sorter: true,
      width: 100,
      // sorter: (a, b) => a.SubmitterProviderName.localeCompare(b.SubmitterProviderName),
      //...this.getColumnSearchProps("SubmitterProviderName", "name"),
      render: (item) => {
        return (
          <div>
            <a
              onClick={() =>
                this.openGenerateLink(item["id"], item["video_call_link"])
              }
            >
              {"Generate"}
            </a>
          </div>
        );
      },
    },

    {
      title: "Organization",
      dataIndex: "orgsName",
      // sorter: true,
      width: 100,
      // sorter: (a, b) => a.organization.localeCompare(b.organization),
      ...this.getColumnSearchProps("org_name", "Organization"),
      filterDropdownVisible: this.state.filterSearchVisibility.org_name,
    },

    {
      title: "Medical Group",
      dataIndex: "medicalGroup",
      // sorter: true,
      // sorter: (a, b) => a.closeCode - b.closeCode,
      width: 100,
      ...this.getColumnSearchProps("med_grp_name", "Medical Group"),
      filterDropdownVisible: this.state.filterSearchVisibility.med_grp_name,
    },

    {
      title: "Submitter Provider Name",
      dataIndex: [],
      //sorter: true,
      width: 100,
      // sorter: (a, b) => a.SubmitterProviderName.localeCompare(b.SubmitterProviderName),
      ...this.getColumnSearchProps("sub_name", "Name"),
      filterDropdownVisible: this.state.filterSearchVisibility.sub_name,
      render: (url, record) => {
        return (
          <div>
            <a onClick={() => this.openChat(record["ChatSessionId"])}>
              {record["SubmitterProviderName"]}
            </a>
          </div>
        );
      },
    },

    {
      title: "Responder Provider Name",
      dataIndex: "ResponderProviderName",
      // sorter: true,
      width: 100,
      // sorter: (a, b) => a.ResponderProviderName.localeCompare(b.ResponderProviderName),
      ...this.getColumnSearchProps("res_name", "Name"),
      filterDropdownVisible: this.state.filterSearchVisibility.res_name,
      render: (url, record) => {
        return (
          <div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => this.openChat(record["ChatSessionId"])}
            >
              {record["ResponderProviderName"]}
            </div>
          </div>
        );
      },
    },

    {
      title: "Specialty",
      dataIndex: "Speciality",
      // filters: this.state.specialityItem.map(x => ({ text: x['name'], value: x['name'] })),
      // onFilter: (value, record) => record['Speciality'].indexOf(value) === 0,
      // sorter: true,
      // sorter: (a, b) => a.Speciality.localeCompare(b.Speciality),
      ...this.getColumnSearchProps("spec", "Specialty"),
      filterDropdownVisible: this.state.filterSearchVisibility.spec,
      width: 100,
    },

    {
      title: "Consumer Name",
      dataIndex: "ConsumerName",
      // sorter: true,
      // sorter: (a, b) => a.ConsumerName.localeCompare(b.ConsumerName),
      width: 100,
      ...this.getColumnSearchProps("patient_name", "Consumer Name"),
      filterDropdownVisible: this.state.filterSearchVisibility.patient_name,
    },

    {
      title: "Close Code",
      dataIndex: "closeCode",
      // sorter: true,
      // sorter: (a, b) => a.closeCode - b.closeCode,
      width: 100,
      ...this.getColumnSearchProps("close_code", "Close Code"),
      filterDropdownVisible: this.state.filterSearchVisibility.close_code,
    },

    // {
    //   title: "Chief Complaint",
    //   dataIndex: "ChiefComplaint",
    //   width: "20%",
    //   ...this.getColumnSearchProps("ChiefComplaint"),
    //   // ...this.getColumnSearchProps('ChiefComplaint'),
    //   // sorter: true,
    //   // sorter: (a, b) => a.ConsumerName.localeCompare(b.ConsumerName),
    // },
    {
      title: "Created At",
      dataIndex: "createdAt",
      sorter: (a, b) => myDate(a.createdAt) - myDate(b.createdAt),
      // render: (name) => dayjs().to(dayjs(name)),
      width: 100,
    },

    {
      title: " First Responded At",
      dataIndex: "firstRespondAt",
      sorter: (a, b) => myDate(a.firstRespondAt) - myDate(b.firstRespondAt),
      //render: (name) => dayjs().to(dayjs(name)),
      width: 100,
    },

    {
      title: " Responded At",
      dataIndex: "updatedAt",
      sorter: (a, b) => myDate(a.updatedAt) - myDate(b.updatedAt),
      //render: (name) => dayjs().to(dayjs(name)),
      width: 100,
    },

    {
      title: "Action",
      key: "action",
      //sorter: true,
      //filters: [],
      //onFilter: () => { },
      render: (item) => (
        // <Popconfirm
        // title="Are you sure on this task?"
        // onConfirm={() => this.autoAssign({ speciality: item['SpecialityId'], user_id: item['UserId'], session_id: item['ChatSessionId'], org_id: item['orgId'], type: "Admin" })}
        // onCancel={() => null}
        // okText="Yes"
        // cancelText="No"
        // >
        // <span>

        // <a style={{ marginRight: 16 }} >Reassign</a>
        // </span>
        // </Popconfirm>
        <a
          onClick={() =>
            this.showProvider({
              specialty: item["SpecialityId"],
              user_id: item["UserId"],
              session_id: item["ChatSessionId"],
              org_id: item["orgId"],
              type: "admin",
            })
          }
        >
          {" "}
          Reassign{" "}
        </a>
      ),
    },
    {
      title: "Add/Remove",
      key: "delete",
      render: (item) => (
        <div>
          <Tooltip title="Add Responder to a chat session">
            <UserAddOutlined
              onClick={() =>
                this.addChatProvider({
                  session_id: item["ChatSessionId"],
                  org_id: item["orgId"],
                })
              }
            ></UserAddOutlined>
          </Tooltip>
          <span style={{ paddingLeft: "30px" }}> </span>
          <Tooltip title="Remove user from a chat session">
            <DeleteOutlined
              onClick={() =>
                this.showChatProvider({
                  session_id: item["ChatSessionId"],
                })
              }
            ></DeleteOutlined>
          </Tooltip>
        </div>
      ),
    },
  ];

  removeSessionUser = (action, user_id, session_id) => {
    if (user_id === null || user_id === "") {
      message.error("Please choose the provider");
    } else {
      let body = {
        action: action,
        user_id: user_id,
        session_id: session_id,
      };
      ApiService.adddetails
        .addremoveSessionUser(body)
        .then((data) => {
          if (data.status == 200) {
            console.log(data.data, "gggg");
            this.setState({
              DelproviderVisible: false,
            });
            this.setState({
              providername: data.data["first_name"] + data.data["last_name"],
            });
            message.success("Successfully user removed from a chat session");
            this.formRef.current.resetFields();
            sessionStorage.getItem("rolename") === "Super Admin" &&
              this.loadChats();

            sessionStorage.getItem("rolename") === "Admin" &&
              this.loadorgChats(sessionStorage.getItem("org_id"));

            sessionStorage.getItem("rolename") === "Session Admin" &&
              this.loadorgChats(sessionStorage.getItem("org_id"));
            this.Banner(user_id, "", session_id);
          } else {
            message.error("Server responded with error" + data.status);
          }
        })
        .catch((err) => message.error("No users available at the moment"));
    }
  };

  addSessionUser = (action, user_id, session_id) => {
    if (user_id === null || user_id === "") {
      message.error("Please choose the provider");
    } else {
      let body = {
        action: action,
        user_id: user_id,
        session_id: session_id,
      };
      ApiService.adddetails
        .addremoveSessionUser(body)
        .then((data) => {
          console.log(data.data, "sssjshjhj");
          if (data.status == 200) {
            this.setState({
              AddproviderVisible: false,
            });
            this.setState({
              providername: data.data["first_name"] + data.data["last_name"],
            });
            message.success(
              "Successfully added the responder to a chat session"
            );
            sessionStorage.getItem("rolename") === "Super Admin" &&
              this.loadChats();

            sessionStorage.getItem("rolename") === "Admin" &&
              this.loadorgChats(sessionStorage.getItem("org_id"));

            sessionStorage.getItem("rolename") === "Session Admin" &&
              this.loadorgChats(sessionStorage.getItem("org_id"));
            this.chatDatas(user_id, "", session_id);
          } else {
            message.error("Server responded with error" + data.status);
          }
        })
        .catch((err) => message.error("No users available at the moment"));
    }
  };

  sessionDetails = (params) => {
    this.setState({
      message1: false,
    });
    ApiService.adddetails
      .sessionDetails(params)
      .then((data) => {
        console.log(data.data, "session");
        if (data.status == 200) {
          if (data.data != null) {
            let resData = Array.from(data.data["member_details"]);
            let newData = [];
            for (let i = 0; i < resData.length; i++) {
              console.log(resData.length, "ress");
              if (data.data["member_details"].length === 3) {
                this.setState({
                  //DelproviderVisible: false,
                  message1: true,
                });
                //   message.warn("No providers available at the moment", 1.5);
              }
              if (
                data.data["member_details"][i]["user_id"] !==
                  data.data["owner1.user_id"] &&
                data.data["member_details"][i]["user_id"] !==
                  data.data["owner2.user_id"] &&
                data.data["member_details"][i]["user_id"] !==
                  data.data["patient.user_id"]
              ) {
                newData.push({
                  user_id: resData[i]["user_id"],
                  first_name: resData[i]["first_name"],
                  last_name: resData[i]["last_name"],
                  agora_id: resData[i]["agora_id"],
                });
                this.setState({
                  message1: false,
                });
              }
              // else {
              //   this.setState({
              //     message1: true,
              //   });
              // }
            }
            let chatProviderTag = newData.sort(function (a, b) {
              return a.first_name.localeCompare(b.first_name);
            });
            this.setState({
              chatProviderDetails: chatProviderTag,
            });
          }
          console.log(this.state.chatProviderDetails, "ll");
        } else {
          message.error("Server responded with error" + data.status);
        }
      })
      .catch((err) => message.error("No users available at the moment"));
  };

  addUserDetails = (params) => {
    this.setState({
      loaded: true,
    });
    let body = {
      org_id: params["org_id"],
    };
    console.log(body, "booody");
    ApiService.adddetails
      .addUserDetails(body)
      .then((data) => {
        if (data.status == 200) {
          this.setState({
            loaded: false,
          });
          if (data.data != null) {
            let resData = Array.from(data.data);
            let newData = [];
            for (let i = 0; i < resData.length; i++) {
              newData.push({
                user_id: resData[i]["user_id"],
                first_name: resData[i]["first_name"],
                last_name: resData[i]["last_name"],
                agora_id: resData[i]["agora_id"],
              });
            }
            let addProviderTag = newData.sort(function (a, b) {
              return a.first_name.localeCompare(b.first_name);
            });
            this.setState({
              addProviderDetails: addProviderTag,
            });
          }
          console.log(this.state.addProviderDetails, "ll");
        } else {
          message.error("Server responded with error" + data.status);
        }
      })
      .catch((err) => message.error("No users available at the moment"));
  };

  autoAssign = (params) => {
    ApiService.adddetails
      .autoAssign(params)
      .then((data) => {
        console.log(data);
        if (data.status == 200) {
          if (data.data != null) {
            let resData = Array.from(data.data);
            let newData = [];
            for (let i = 0; i < resData.length; i++) {
              newData.push({
                user_id: resData[i]["user_id"],
                first_name: resData[i]["first_name"],
                last_name: resData[i]["last_name"],
                agora_id: resData[i]["agora_id"],
                spec_id: resData[i]["spec_id"],
                // org_id: resData[i]["org_id"],
                // org_type: resData[i]["org_type"]
              });
            }
            let providerTag = newData.sort(function (a, b) {
              return a.first_name.localeCompare(b.first_name);
            });
            this.setState({
              providerDetails: providerTag,
            });
          }
          console.log(this.state.providerDetails, "ll");
          //this.showProvider()

          // message.success('Successfully Reassigned')
          // this.loadChats()
        } else {
          message.error("Server responded with error" + data.status);
        }
      })
      .catch((err) => message.error("No users available at the moment"));
  };

  ChartContents = () => {
    return <div> Contents</div>;
  };

  renderProfile({ url, usrid }) {
    if (url) {
      return (
        <Avatar
          size={40}
          className="chatItem_avatar"
          shape="square"
          src={url}
        />
      );
    }

    return (
      <Avatar
        size={40}
        shape="square"
        icon={<UserOutlined />}
        className="chatItem_avatar"
      />
    );
  }

  SelectonBlur = (e) => {
    this.setState({ modalVisible: false });
  };

  SelectonChange = (val) => {
    console.log("settting");
    let data = Array.from(this.state.ChatDataCopy);
    let newData = data.filter((x) => x["spec_master.name"] === val);
    this.setState({
      ChatData: newData,
      SortedSpeciality: val,
      modalVisible: false,
    });
  };

  selectSpeciality = (data) => {
    return (
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder="Select a person"
        optionFilterProp="children"
        onChange={this.SelectonChange}
        onFocus={this.SelectonFocus}
        onBlur={this.SelectonBlur}
        onSearch={this.SelectonSearch}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {data &&
          data.map((item) => <Option value={item.name}>{item.name}</Option>)}
      </Select>
    );
  };

  onChange = async (fields, item) => {
    // event.preventDefault()
    console.log(fields, item);
    const fillterPropsCopy = Object.assign({}, this.state.fillterProps);
    const fillterCopy = Object.assign({}, this.state.Filter);

    switch (fields) {
      case "Filter":
        let itemIndex = filterItems.indexOf(item);

        if (itemIndex !== -1) {
          switch (item) {
            case filterItems[0]:
              console.log("inside 1");
              if (fillterPropsCopy["filter_unanswered"] === "") {
                fillterPropsCopy["filter_unanswered"] = "true";
                fillterPropsCopy["filter_unread"] = "";
                fillterPropsCopy["filter_live"] = "";
                fillterCopy["Unanswered Chats"] = true;
                fillterCopy["Unread Chats"] = false;
                fillterCopy["Live Chats"] = false;
              } else {
                fillterPropsCopy["filter_unanswered"] = "";
                fillterCopy["Unanswered Chats"] = false;
              }
              this.setState({
                fillterProps: fillterPropsCopy,
                Filter: fillterCopy,
              });

              break;
            case filterItems[1]:
              console.log("inside 2");
              if (fillterPropsCopy["filter_unread"] === "") {
                fillterPropsCopy["filter_unread"] = "true";
                fillterPropsCopy["filter_live"] = "";
                fillterPropsCopy["filter_unanswered"] = "";
                fillterCopy["Unanswered Chats"] = false;
                fillterCopy["Unread Chats"] = true;
                fillterCopy["Live Chats"] = false;
              } else {
                fillterCopy["Unread Chats"] = false;
                fillterPropsCopy["filter_unread"] = "";
              }
              this.setState({
                fillterProps: fillterPropsCopy,
                Filter: fillterCopy,
              });

              break;
            case filterItems[2]:
              console.log("inside 3");
              if (fillterPropsCopy["filter_live"] === "") {
                fillterPropsCopy["filter_live"] = "true";
                fillterPropsCopy["filter_unread"] = "";
                fillterPropsCopy["filter_unanswered"] = "";

                fillterCopy["Unanswered Chats"] = false;
                fillterCopy["Unread Chats"] = false;
                fillterCopy["Live Chats"] = true;
              } else {
                fillterPropsCopy["filter_live"] = "";
                fillterCopy["Live Chats"] = false;
              }
              this.setState({
                fillterProps: fillterPropsCopy,
                Filter: fillterCopy,
              });

              break;
            default:
              break;
          }
        }

        break;
      case "Filter-clear":
        Object.keys(fillterPropsCopy).map((e) => (fillterPropsCopy[e] = ""));
        Object.keys(fillterCopy).map((e) => (fillterCopy[e] = false));

        this.setState({ fillterProps: fillterPropsCopy, Filter: fillterCopy });
        break;
      case "Filter-call":
        this.setState({
          filtermenuVisible: false,
          ChatSuccessFullyLoaded: false,
        });

        ApiService.Chatlist.filter(JSON.stringify(fillterPropsCopy))
          .then((data) => {
            if (
              typeof data == "object" &&
              Object.keys(data.data).length === 0
            ) {
              message.info("No data for the selected combination.");
              return;
            }
            let resData = Array.from(data.data);
            let userId = [];
            let coll = {};
            let newData = [];

            if (data.status === 200) {
              for (let i = 0; i < resData.length; i++) {
                newData.push({
                  ChatSessionId: resData[i]["id"],
                  ResponderProviderId: resData[i]["owner2.user_id"],
                  ResponderProviderName:
                    resData[i]["owner2.first_name"] +
                    " " +
                    resData[i]["owner2.last_name"],
                  id: resData[i]["id"],

                  SubmitterProviderName:
                    resData[i]["owner1.first_name"] +
                    " " +
                    resData[i]["owner1.last_name"],
                  ResponderProviderNpi: resData[i]["owner2.npi"],
                  SubmitterProviderNpi: resData[i]["owner1.npi"],
                  UserId: resData[i]["owner2.user_id"],
                  ConsumerName:
                    resData[i]["patient.first_name"] +
                    " " +
                    resData[i]["patient.last_name"],
                  InsuranceNumber: resData[i]["patient.card_val"],
                  ConsumerMPI: resData[i]["patient.MPI"],
                  Speciality: resData[i]["spec_master.name"],
                  SpecialityId: resData[i]["specialty"],
                  ChiefComplaint: resData[i]["chief_complaint"] || "No Data",
                  CreateAt: resData[i]["createdAt"],
                  ProviderImg: resData[i]["image_url"],
                  closeCodeId: resData[i]["close_code.id"],
                  closeCode: resData[i]["close_code.close_code"],
                  orgId: resData[i]["org_id"],
                  organization: resData[i]["organization.name"],
                  icd: resData[i]["ICD"],
                  createdAt: resData[i]["createdAt"],
                  updatedAt: resData[i]["updatedAt"],
                  ConsumerDob: resData[i]["patient.dob"],
                  Create: resData[i]["owner1.createdAt"],
                  cpt: resData[i]["CPT"],
                });

                if (!userId.includes(resData[i]["owner2.user_id"])) {
                  userId.push(resData[i]["owner2.user_id"]);
                  coll[resData[i]["owner2.user_id"]] = {};
                }
              }
            }
            console.log("setting filter");
            this.setState({
              ChatData: newData,
              filtermenuVisible: false,
              ChatSuccessFullyLoaded: true,
            });
          })
          .catch((error) => {});
        break;

      /* Sorting functionality is removed */
      // case 'Sort':
      // console.log('Sorting')
      // let data = Array.from(this.state.ChatData)
      // let newData = []

      // if (item === 'Org') {
      // newData = data.filter(item => item['org_id'] !== '')
      // alert(newData)
      // }
      // if (item === 'User') {
      // newData = data.filter(item => item['org_id'] !== '')
      // }

      // if (item === 'Date') {
      // const dateSort = this.state.DateSortAsc
      // let data = Array.from(this.state.ChatDataCopy)
      // function compareDate(a, b) {

      // console.log(dateSort)
      // return dateSort ? myDate(b.createdAt) - myDate(a.createdAt) : myDate(a.createdAt) - myDate(b.createdAt)
      // }
      // this.setState({ DateSortAsc: !dateSort })

      // newData = data.sort(compareDate)

      // }

      // if (item === 'Specialty') {
      // this.setState({
      // modalVisible: true,
      // modaltitle: 'Select Speciality',
      // modalContent: () => this.selectSpeciality(this.state.specialityItem)
      // })

      // let data = Array.from(this.state.ChatDataCopy)
      // let sortedSpeciality = this.state.SortedSpeciality
      // console.log(sortedSpeciality,'we')
      // console.log(this.state, "sortedSpeciality")

      // function compareSpeciality(a, b) {
      // }
      // newData = sortArray(data, {
      // by: 'speciality',
      // order: 'desc',
      // computed: {
      // speciality: item => item.speciality === sortedSpeciality

      // }
      // })
      // }
      // if (item === 'Age of response') {
      // newData = data.filter(item => item['org_id'] !== '')

      // }
      // if (item === 'Close code') {
      // // newData = data.filter(item => item['org_id'] !== '')
      // this.setState({
      // modalVisible: true,
      // modaltitle: 'Select Speciality',
      // modalContent: () => this.selectClosecode(this.state.closecode)
      // })
      // let data = Array.from(this.state.ChatDataCopy)
      // let SortedCloseCode = this.state.SortedCloseCode
      // console.log(SortedCloseCode,'wewewe')
      // console.log(this.state, "SortedCloseCode")

      // function compareSpeciality(a, b) {
      // }
      // newData = sortArray(data, {
      // by: 'closeCode',
      // order: 'desc',
      // computed: {
      // closeCode: item => item.closeCode === SortedCloseCode

      // }
      // })

      // }
      // this.setState({ ChatData: newData })
      // break;

      default:
        break;
    }
  };

  showMessage = () => {
    message.warn("Coming soon");
  };

  handlefiltermenuVisibleChange = () => {
    this.setState({ filtermenuVisible: !this.state.filtermenuVisible });
  };

  handleSortmenuVisibleChange = () => {
    this.setState({ sortmenuVisible: !this.state.sortmenuVisible });
  };

  callDaildigest = () => {
    ApiService.adddetails
      .Daildigest()
      .then((e) => {
        if (e.status === 200) {
          message.info("Successfully Sent");
        } else {
          message.error("Server responded with error code" + e.status);
        }
      })
      .catch((e) =>
        message.error("Server responded with error code" + e.data.status)
      );
  };

  csvdownload = async () => {
    if (sessionStorage.getItem("rolename") === "Admin") {
      let body = {
        org_id: sessionStorage.getItem("org_id"),
      };
      await ApiService.Chatlist.downloadCsv(body).then((data) => {
        if (data.status === 200) {
          this.setState({
            csvData: data.data,
          });
        }
        if (
          data?.data?.length > 0 &&
          this.state.ChatSuccessFullyLoaded === false
        ) {
          this.csvLink.link.click();
        }
        this.setState({ ChatSuccessFullyLoaded: true });
      });
    }
    if (sessionStorage.getItem("rolename") === "Super Admin") {
      let body = {
        org_id: "",
      };
      await ApiService.Chatlist.downloadCsv(body).then((data) => {
        if (data.status === 200) {
          this.setState({
            csvData: data.data,
          });
        }
        if (
          data?.data?.length > 0 &&
          this.state.ChatSuccessFullyLoaded === false
        ) {
          this.csvLink.link.click();
        }
        this.setState({ ChatSuccessFullyLoaded: true });
      });
    }
  };

  //  Csvheaders = [
  //  { label: "Chat Id", key: "ChatSessionId" },
  //  { label: "Organization", key: "organization" },
  //  { label: "Submitter Provider Name", key: "SubmitterProviderName" },
  //  { label: "Submitter Provider Npi", key: "SubmitterProviderNpi" },
  //  { label: "Responder Provider Name", key: "ResponderProviderName" },
  //  { label: "Responder Provider Npi", key: "ResponderProviderNpi" },
  //  { label: "ICD Code", key: "icd" },
  //  { label: "CPT Code", key: "cpt"},
  //  { label: "Provider Speciality", key: "Speciality" },
  //  { label: "Consumer Name", key: "ConsumerName" },
  //  { label: "Consumer DOB", key: "ConsumerDob" },
  //  { label: "Consumer Unique ID", key: "ConsumerMPI" },
  //  { label: "Consumer Insurance", key: "InsuranceNumber" },
  //  { label: "close Code", key: "closeCode" },
  //  { label: "Date of First closeCode", key: "createdAt" },
  //  { label: "Last close code", key: "" },
  //  { label: "Date of last close code", key: "" },
  //  { label: "Chief Complaint", key: "ChiefComplaint" },
  //  { label: "created At", key: "Create" },
  //  { label: "First Responded At", key: "CreateAt" },
  //  { label: "Last Responded At", key: "updatedAt" },
  //  ];

  render() {
    let itemagoraid = [];
    const {
      chat,
      ChatData,
      providerDetails,
      chatProviderDetails,
      addProviderDetails,
      loaded,
      message1,
    } = this.state;
    console.log(this.state.ChatData);
    // console.log(this.Tablecolumns())
    const menu = (Items, field, tag) => (
      <>
        {tag === "check" ? (
          <div style={{ paddingTop: "10px", background: "white" }} />
        ) : (
          ""
        )}

        <Menu>
          {Items.length &&
            Items.map((item) => (
              <Menu.Item key={item}>
                {tag === "check" ? (
                  //<Checkbox onChange={() => this.onChange(field, item)}>{item}</Checkbox>
                  <>
                    <Checkbox
                      checked={this.state[field][item]}
                      onChange={() => this.onChange(field, item)}
                    >
                      {item}
                    </Checkbox>
                  </>
                ) : (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href
                    onClick={() => this.onChange(field, item)}
                  >
                    {item}
                  </a>
                )}
              </Menu.Item>
            ))}
          {tag === "check" ? (
            <>
              <Divider style={{ margin: "10px 0" }} />

              <div className="filter_buttons_container">
                <Button
                  type="primary"
                  shape="round"
                  size={"default"}
                  onClick={() => this.onChange("Filter-clear", null)}
                >
                  {Clear}{" "}
                </Button>
                <Button
                  type="primary"
                  shape="round"
                  size={"default"}
                  onClick={() => this.onChange("Filter-call", null)}
                >
                  {Filter}
                </Button>
              </div>
            </>
          ) : null}
        </Menu>

        {tag === "check" ? (
          <div style={{ paddingBottom: "10px", background: "white" }} />
        ) : (
          ""
        )}
      </>
    );

    return (
      <>
        <Row>
          <Col span={8} className="flexcenter">
            <h3 className="heading-font">CHAT LISTS</h3>
          </Col>
          {/* <Col span={16}>
            <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            }}>
            <ul className="chatheaderMenu">
            <li className="bellNotification">
            <BellFilled style={{ color: '#0252a1' }} />
            </li>
            <li className="profile_button">

            <div className="headerPorifileDiv">
            <Avatar shape="square" size="large" icon={<UserOutlined />} style={{ borderRadius: '30%' }} />
            <span className="proifle_name">
            Welcome Sally Rivera
            </span>
            </div>
            </li>
            <li className="logoutbutton">
            <Button className="buttonOutline" ghost size="default">
            Logout
            </Button>
            </li>
            </ul>
            </div>
            </Col> */}
        </Row>
        <Row style={{ marginTop: "30px" }}>
          <Col span={16}>
            {/* <div className='search'>
                        <Tooltip title='Search Chief Complaint,Consumer Name'>
                        <Search
                            
                            placeholder="Search Chief Complaint,Consumer Name"
                            onSearch={value => console.log(value,'anup')}
                            onKeyUp={
                            value => {
                            ApiService.Search.search({ keyword: value.target.value })
                            .then(res => {
                            console.log(res,'res')
                            if(res.data === null && value.target.value === '') {
                            this.props.history.push('/chatlist') 
                            } else {
                            let resData = Array.from(res.data);
                            let userId = []
                            let coll = {}
                            let newData = []
                            for (let i = 0; i < resData.length; i++) {
                                newData.push({
                                    ChatSessionId: resData[i]['id'],
                                    ResponderProviderId: resData[i]['owner2.user_id'],
                                    ResponderProviderName: resData[i]['owner2.first_name'] + ' ' + resData[i]['owner2.last_name'],
                                    SubmitterProviderName: resData[i]['owner1.first_name'] + ' ' + resData[i]['owner1.last_name'],
                                    ResponderProviderNpi: resData[i]['owner2.npi'],
                                    SubmitterProviderNpi: resData[i]['owner1.npi'],
                                    UserId: resData[i]['owner2.user_id'],
                                    ConsumerName: resData[i]['patient.first_name']+ ' ' + resData[i]['patient.last_name'],
                                    InsuranceNumber: resData[i]['patient.card_val'],
                                    ConsumerMPI: resData[i]['patient.MPI'],
                                    Speciality: resData[i]['spec_master.name'],
                                    SpecialityId:resData[i]['speciality'],
                                    ChiefComplaint: resData[i]['chief_complaint'] || 'No Data',
                                    CreateAt: resData[i]['createdAt'],
                                    ProviderImg: resData[i]['image_url'],
                                    closeCodeId: resData[i]['close_code.id'],
                                    closeCode: resData[i]['close_code.close_code'],
                                    orgId: resData[i]['org_id'],
                                    organization: resData[i]['organization.name'],
                                    icd: resData[i]["ICD"],
                                    createdAt: resData[i]['createdAt'],
                                    updatedAt: resData[i]['updatedAt'],
                                    ConsumerDob: resData[i]['patient.dob'],
                                    Create: resData[i]['owner1.createdAt'],
                                    cpt: resData[i]['CPT']
                                })
                                
                                if (!userId.includes(resData[i]['owner2.user_id'])) {
                                    userId.push(resData[i]['owner2.user_id'])
                                    coll[resData[i]['owner2.user_id']] = {}
                                }

                            }
                            this.setState({ChatData: newData, ChatSuccessFullyLoaded: true})
                            }
                            })
                            .catch(err => {
                            console.log(err)
                            })
                            }
                            }
                        />
                        </Tooltip>
                    </div> */}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <ul className="filter_chat">
                <li className="searchFilter">
                  <Tooltip
                    title="Download Csv"
                    onClick={() => {
                      this.setState({ ChatSuccessFullyLoaded: false });
                      this.csvdownload();
                    }}
                  >
                    <Button shape="circle" icon={<CloudDownloadOutlined />} />
                    <CSVLink
                      data={this.state.csvData}
                      filename={"Chatdata.csv"}
                      // headers={this.Csvheaders}
                      className="btn btn-primary"
                      target="_blank"
                      ref={(r) => (this.csvLink = r)}
                    ></CSVLink>
                  </Tooltip>
                </li>
                <li className="searchFilter">
                  <Tooltip title="Bulk Alert">
                    {/* <Popconfirm
                                title="Are you sure on this task?"
                                onConfirm={e => this.callDaildigest()}
                                onCancel={() => null}
                                okText="Yes"
                                cancelText="No"
                                > */}
                    <Button
                      shape="circle"
                      icon={<AlertFilled />}
                      onClick={this.showMessage}
                    />
                    {/* </Popconfirm> */}
                  </Tooltip>
                </li>

                <li className="filter_button">
                  {/* <Dropdown
                                    onVisibleChange={this.handlefiltermenuVisibleChange}
                                    visible={this.state.filtermenuVisible}
                                    overlay={menu(filterItems, 'Filter', 'check')}
                                    trigger={['click']}> */}

                  <Tooltip title="Filter">
                    <Button
                      shape="circle"
                      icon={<SlidersOutlined />}
                      onClick={this.showMessage}
                    />
                  </Tooltip>
                  {/* </Dropdown> */}
                </li>
                <li className="sort_buton">
                  {/* <Dropdown
                            onVisibleChange={this.handleSortmenuVisibleChange}
                            visible={this.state.sortmenuVisible}
                            overlay={menu(sortItems, 'Sort', null)}
                            trigger={['click']}> */}

                  <Tooltip title="Sort">
                    <Button
                      shape="circle"
                      icon={<SortAscendingOutlined />}
                      onClick={this.showMessage}
                    />
                  </Tooltip>
                  {/* </Dropdown> */}
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div class="table-scroll">
              <Table
                columns={this.Tablecolumns()}
                rowKey={(record) => record.id}
                dataSource={this.state.ChatData}
                // pagination={this.state.pagination}
                // loading={!this.state.ChatSuccessFullyLoaded}
                pagination={this.state.paginationFlag}
                loading={!this.state.ChatSuccessFullyLoaded}
                // scroll={{ x: true }}
                style={{ whiteSpace: "nowrap" }}
                // onChange={this.handleTableChange}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 16,
              }}
            >
              {/* {!this.state.paginationFlag && ( */}
              <Pagination
                current={this.state.currentPage}
                total={this.state.totalItems}
                pageSize={this.state.itemsPerPage}
                onChange={this.handlePageChange}
                showSizeChanger
                onShowSizeChange={this.handleSizeChange}
              />
              {/* )} */}
            </div>
            {/* {this.props.data.map(e => console.log('img', e['chief_complaint']))}
                {

                this.props.data && this.props.data.map(e => <ChatItems
                first_name={e['owner2.first_name']}
                last_name={e['owner2.last_name']}
                updatedAt={e['updatedAt']}
                chief_complaint={e['chief_complaint']}
                image_url={e['owner2.image_url']}
                badge_count={(function (e, state) {
                let filterType = state.filterType;
                if (filterType === 'unanswered') {

                }
                else if (filterType === 'unread') {

                }
                return 0
                })(e, this.props.filterType)}
                />)
                } */}
            <Modal
              title={this.state.modaltitle}
              centered
              visible={this.state.modalVisible}
              okButtonProps={{ style: { display: "none" } }}
              onCancel={() =>
                this.setState({ modalVisible: !this.state.modalVisible })
              }
            >
              <div className="row-1">
                <div className="modal_speciality">
                  {typeof this.state.modalContent === "function" &&
                    this.state.modalContent()}
                </div>
              </div>
            </Modal>
            <Modal
              className=""
              title="Chat Dialogue"
              centered
              visible={this.state.chatVisible}
              okButtonProps={{ style: { display: "none" } }}
              onCancel={() =>
                this.setState({
                  chatVisible: !this.state.chatVisible,
                  specialityName: "",
                })
              }
            >
              <div className="chat-scroll">
                <div className="reverse">
                  {chat &&
                    chat.map((item, index) => {
                      console.log(this.state.chat, "iii");
                      return (
                        //,background: 'lavender', fontSize: '13px', padding: '3px 0px 3px 0px'
                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            {item.from_name} :
                          </span>{" "}
                          {item.message} (
                          <span style={{ fontStyle: "italic" }}>
                            {item.createdAt}
                          </span>
                          )
                        </div>
                      );
                    })}
                </div>
              </div>
            </Modal>

            <Modal
              className=""
              title="Generate Video Link"
              centered
              visible={this.state.openGenerate}
              okButtonProps={{ style: { display: "none" } }}
              onCancel={() =>
                this.setState({
                  openGenerate: !this.state.openGenerate,
                  rowSessionId: "",
                  rowVideoLink: "",
                  // specialityName: "",
                })
              }
            >
              {this.state.generateNewLinkLoading === true ? (
                <div className="loaderGenerateLink">
                  <Spin />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="footer-btn"
                    //style={{ maxWidth: "60%" }}
                    key="back"
                    type="primary"
                    onClick={() => {
                      this.generateLinkCall(this.state.rowSessionId);
                    }}
                  >
                    {this.state.rowVideoLink !== null
                      ? "Generate New Link"
                      : "Generate Link"}
                  </Button>

                  {this.state.rowVideoLink !== null && (
                    <Input
                      ref={this.textInput}
                      style={{
                        maxWidth: "100%",
                        marginTop: "15px",
                        height: "50px",
                        backgroundColor: "white",
                        border: "0.3px solid #40a9ff",
                        borderRadius: "5px",
                      }}
                      value={this.state.rowVideoLink}
                      suffix={
                        <CopyOutlined
                          className="copy-button"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              this.state.rowVideoLink
                            );
                            message.success("Link Coppied!");
                          }}
                        />
                      }
                    />
                  )}
                </div>
              )}
            </Modal>

            <div>
              <Modal
                title="REMOVE PROVIDER"
                centered
                visible={this.state.DelproviderVisible}
                okButtonProps={{ style: { display: "none" } }}
                footer={[
                  <div className="footer">
                    <Button
                      className="footer-btn"
                      key="back"
                      type="primary"
                      onClick={() =>
                        this.setState({
                          DelproviderVisible: false,
                        })
                      }
                    >
                      Return
                    </Button>

                    {message1 ? (
                      <span></span>
                    ) : (
                      <Button
                        htmlType="submit"
                        key="submit"
                        type="primary"
                        className="footer-btn"
                        onClick={() =>
                          this.removeSessionUser(
                            "remove",
                            this.state.chatProviderId,
                            this.state.sessionId
                          )
                        }
                      >
                        Submit
                      </Button>
                    )}
                  </div>,
                ]}
              >
                <div className="row-1">
                  {message1 ? (
                    <div
                      style={{
                        color: "#464242",
                        fontSize: "18px",
                        paddingBottom: "20%",
                        paddingLeft: "25%",
                      }}
                    >
                      New providers not yet added !!!
                    </div>
                  ) : (
                    <div
                      className=""
                      style={{ paddingBottom: "20%", paddingLeft: "10%" }}
                    >
                      <Form ref={this.formRef}>
                        {/* <Form.Item
                      label="Choose Provider"
                      rules={[
                        {
                          required: true,
                          message: "Please select the Provider",
                        },
                      ]}
                      style={{ fontWeight: "bold" }}
                    > */}
                        <Select
                          style={{ width: "500px" }}
                          placeholder="Select Provider"
                          onChange={(e) =>
                            this.selectChatProvider(e, itemagoraid, "provider")
                          }
                        >
                          {chatProviderDetails &&
                            chatProviderDetails.map((item, index) => {
                              itemagoraid = item.agora_id;
                              return (
                                <Option key={item.user_id} value={item.user_id}>
                                  {item.first_name}
                                  {item.last_name}
                                </Option>
                              );
                            })}
                        </Select>

                        {/* </Form.Item> */}
                      </Form>
                    </div>
                  )}
                </div>
              </Modal>
            </div>

            <Modal
              title="ADD PROVIDER"
              centered
              visible={this.state.AddproviderVisible}
              okButtonProps={{ style: { display: "none" } }}
              footer={[
                <div className="footer">
                  <Button
                    key="back"
                    type="primary"
                    className="footer-btn"
                    onClick={() =>
                      this.setState({
                        AddproviderVisible: false,
                      })
                    }
                  >
                    Return
                  </Button>
                  ,
                  <Button
                    htmlType="submit"
                    key="submit"
                    type="primary"
                    className="footer-btn"
                    onClick={() =>
                      this.addSessionUser(
                        "add",
                        this.state.chatProviderId,
                        this.state.sessionId
                      )
                    }
                  >
                    Submit
                  </Button>
                  ,
                </div>,
              ]}
            >
              <div className="row-1">
                <div
                  className=""
                  style={{ paddingBottom: "20%", paddingLeft: "10%" }}
                >
                  {/* <Form.Item
                    label="Choose Provider"
                    rules={[
                      {
                        required: true,
                        message: "Please select the Provider",
                      },
                    ]}
                    style={{
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  > */}
                  {loaded ? (
                    <div className="loader">
                      <Spin />
                    </div>
                  ) : (
                    <Select
                      style={{ width: "500px" }}
                      placeholder="Select Provider"
                      onChange={(e) =>
                        this.selectChatProvider(e, itemagoraid, "provider")
                      }
                    >
                      {addProviderDetails &&
                        addProviderDetails.map((item, index) => {
                          itemagoraid = item.agora_id;
                          return (
                            <Option key={item.user_id} value={item.user_id}>
                              {item.first_name}
                              {item.last_name}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                  {/* </Form.Item> */}
                </div>
              </div>
            </Modal>

            <Modal
              title="REASSIGN CHATS"
              centered
              visible={this.state.providerVisible}
              okButtonProps={{ style: { display: "none" } }}
              footer={[
                <div className="footer">
                  <Button
                    key="back"
                    type="primary"
                    className="footer-btn"
                    onClick={this.handleCancel}
                  >
                    Return
                  </Button>
                  ,
                  <Button
                    htmlType="submit"
                    key="submit"
                    type="primary"
                    className="footer-btn"
                    onClick={() =>
                      this.manualassign(
                        this.state.providerId,
                        this.state.specId,
                        this.state.sessionId,
                        this.state.agoraId
                      )
                    }
                  >
                    Submit
                  </Button>
                  ,
                </div>,
              ]}
            >
              <div className="row-1">
                <div
                  className=""
                  style={{ paddingBottom: "20%", paddingLeft: "10%" }}
                >
                  {/* {providerDetails &&
                                providerDetails.map((item, index) => {
                                    console.log(this.state.providerDetails,'iii')
                                    return (
                                        //,background: 'lavender', fontSize: '13px', padding: '3px 0px 3px 0px'
                                       <div style={{ width: 'auto'}}> */}
                  {/* <Form.Item
                    label="Choose Provider"
                    rules={[
                      {
                        required: true,
                        message: "Please select the Provider",
                      },
                    ]}
                    style={{ fontWeight: "bold" }}
                  > */}
                  <Select
                    style={{
                      width: "500px",
                    }}
                    placeholder="Select Provider"
                    onChange={(e) =>
                      this.selectProvider(e, itemagoraid, "provider")
                    }
                  >
                    {providerDetails &&
                      providerDetails.map((item, index) => {
                        //itemagoraid = item.agora_id;
                        return (
                          <Option key={item.user_id} value={item.user_id}>
                            {item.first_name}
                            {item.last_name}
                          </Option>
                        );
                      })}
                  </Select>
                  {/* </Form.Item> */}
                </div>
              </div>
            </Modal>
          </Col>
        </Row>
      </>
    );
  }
}

// <List
// itemLayout="vertical"
// size="default"
// dataSource={this.state.ChatData}
// renderItem={item => (
// <List.Item
// key={item['id']}

// extra={
// <>
// {/* <span>{item['updatedAt']} </span> */}
// <span>{dayjs().to(dayjs(item['createdAt']))}</span>
// <a key="list-loadmore-edit">edit</a>
// <a key="list-loadmore-more">more</a>

// </>
// }
// >
// <List.Item.Meta
// avatar={this.renderProfile({ url: item['owner2.image_url'], usrid: item['owner2.user_id'] })}
// title={<a className="chatitem_name" href="#">{item['owner2.first_name'] + ' ' + item['owner2.last_name']}</a>}
// description={item['chief_complaint'] || 'Not Provided'}
// />

// </List.Item>
// )}
// pagination={{
// onChange: page => {
// console.log(page);
// },
// pageSize: 5,
// }}
// footer={
// <div>

// </div>
// }
// />
