import { Button, Col, Pagination, Row, Switch, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import ApiService from "../../../services/api-services";
import confirm from "antd/lib/modal/confirm";
import Search from "antd/lib/input/Search";
import axios from "axios";
export default function UserNotificationSettings(props) {
  const [searchQuery, setSearchQuery] = useState("");

  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(20);
  const token = sessionStorage.getItem("token");
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const cancelToken = axios.CancelToken.source();
  useEffect(() => {
    if (token === null || token === "") {
      props.history.push("/login");
      window.location.reload(true);
    }
    loadUsers();
    if (!initialLoadComplete) {
      setInitialLoadComplete(true);
    }
  }, [pageNumber, pageLimit]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (initialLoadComplete) {
        loadUsers();
      }
    }, 300);
    return () => {
      cancelToken.cancel("Request aborted ");
      clearTimeout(timer);
    };
  }, [searchQuery]);

  const loadUsers = async () => {
    try {
      setLoading(true);
      let body = {
        key: searchQuery,
        page: pageNumber,
        limit: pageLimit,
      };
      const data = await ApiService.general.searchUserNotificationSettings(
        body,
        cancelToken
      );
      if (data?.status === 200) {
        setTotalItems(data?.data?.count);
        setUserData(data?.data?.rows);
      }
    } catch (error) {
      message.error("Something went wrong, try again");
    } finally {
      setLoading(false);
    }
  };

  const showConfirmation = (id, status, type) => {
    if (type === "phone") {
      confirm({
        className: "popup-margin ",
        title:
          "Are you sure you want to" +
          " " +
          `${
            status === 1
              ? "Disable Phone Notification"
              : "Enable Phone Notification"
          }` +
          " " +
          "",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        height: "158px",
        onOk() {
          updateNotificationStatus(id, status, type);
        },
        onCancel() {},
      });
    } else if (type === "email") {
      confirm({
        className: "popup-margin ",
        title:
          "Are you sure you want to" +
          " " +
          `${
            status === 1
              ? "Disable Email Notification"
              : "Enable Email Notification"
          }` +
          " " +
          "",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        height: "158px",
        onOk() {
          updateNotificationStatus(id, status, type);
        },
        onCancel() {},
      });
    }
  };

  const updateNotificationStatus = (id, status, type) => {
    let body;
    if (type === "phone") {
      body = {
        user_id: id,
        sms: status === 1 ? 0 : 1,
      };
    } else if (type === "email") {
      body = {
        user_id: id,
        email: status === 1 ? 0 : 1,
      };
    }

    ApiService.general
      .updateUserNotificationSettings(body)
      .then((data) => {
        if (data.status === 200) {
          message.success("Successfully Updated");
          loadUsers();
        }
      })
      .catch((error) => {
        message.error("Something went wrong");
      });
  };

  const handlePageChange = (page) => {
    setPageNumber(page);
  };
  const handleSizeChange = (current, size) => {
    setPageLimit(size);
    setPageNumber(1);
  };

  const Tablecolumns = () => [
    {
      title: "Name",
      width: 100,
      render: (url, record) => {
        return <div>{record["first_name"] + " " + record["last_name"]}</div>;
      },
    },

    {
      title: "Enable/Disable Email Notification",

      width: 100,
      render: (url, record) => {
        return (
          <div
            style={{
              alignItems: "center",
            }}
          >
            {record["email"] ? (
              <div style={{ display: "flex" }}>
                <Switch
                  style={{ marginRight: 10 }}
                  checked={
                    record["is_user_email_notification"] === 0 ? false : true
                  }
                  onClick={() =>
                    showConfirmation(
                      record["user_id"],
                      record["is_user_email_notification"],
                      "email"
                    )
                  }
                ></Switch>
                <div>{record["email"]}</div>
              </div>
            ) : (
              "No Data"
            )}
          </div>
        );
      },
    },

    {
      title: "Enable/Disable Phone Notification",
      width: 100,
      render: (url, record) => {
        return (
          <div
            style={{
              alignItems: "center",
            }}
          >
            {record["mobile"] ? (
              <div style={{ display: "flex" }}>
                <Switch
                  style={{ marginRight: 10 }}
                  checked={
                    record["is_user_mobile_notification"] === 0 ? false : true
                  }
                  onClick={() =>
                    showConfirmation(
                      record["user_id"],
                      record["is_user_mobile_notification"],
                      "phone"
                    )
                  }
                ></Switch>
                <div>{record["mobile"]}</div>
              </div>
            ) : (
              "No Data"
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Row>
        <Col span={12}>
          <h3 className="heading-font">User Notification Settings</h3>
        </Col>
      </Row>
      <Row>
        <div className="sub-bar">
          <Search
            placeholder="Search by name, email or phone number"
            value={searchQuery}
            onChange={(e) => {
              setPageNumber(1);
              setSearchQuery(e.target.value);
            }}
          />
        </div>
      </Row>
      <Row>
        <Col span={24}>
          <div class="table-scroll">
            <Table
              columns={Tablecolumns()}
              rowKey={(record) => record.id}
              dataSource={userData}
              pagination={false}
              loading={loading}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 16,
            }}
          >
            <Pagination
              current={pageNumber}
              total={totalItems}
              pageSize={pageLimit}
              onChange={handlePageChange}
              showSizeChanger
              onShowSizeChange={handleSizeChange}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}
