import React, { useRef, useState, useEffect } from "react";
import { Input, Form, message } from "antd";
import dayjs from "dayjs";
import "./../index.css";

const Date = ({ form, type, initialValues }) => {
  const date = useRef();
  const month = useRef();
  const year = useRef();

  const PrefixVal = (val, field) => {
    if (val === null && field !== "year") {
      return 0;
    } else if (val === null && field == "year") {
      return "";
    } else if (val !== null && field !== "year") {
      return val;
    } else if (val !== null && field === "year") {
      if (val) {
        let year = dayjs().format("YYYY");
        console.log(year);
        if (val > year) {
          return year;
        }
      }
      return "2020";
    }
    return;
  };

  useEffect(() => {
    [date, month, year].filter((r) => {
      console.log(r);
      if (r.current) {
        r.current.handleKeyDown = (e) => {
          if (e.key === "Tab") {
            if (
              form.getFieldValue(r.current.props.name) &&
              form.getFieldValue(r.current.props.name).length <
                r.current.props.maxLength
            ) {
              form.setFieldsValue({
                [r.current.props.name]:
                  PrefixVal(null, r.current.props.name) +
                  "" +
                  PrefixVal(
                    form.getFieldValue(r.current.props.name),
                    r.current.props.name
                  ),
              });
            }
          }
        };
        form.setFieldsValue({
          dob: `${form.getFieldValue("month")}-${form.getFieldValue(
            "date"
          )}-${form.getFieldValue("year")}`,
        });
        if (type === "edit") {
          if (Object.keys(initialValues).length > 0) {
            Object.keys(initialValues).map((key) => {
              if (key === "dob") {
                form.setFieldsValue({
                  month: initialValues[key].split("-")[0],
                  date: initialValues[key].split("-")[1],
                  year: initialValues[key].split("-")[2],
                });
              }
            });
          }
        }
      }
    });
  }, [form, month]);

  const validate = (...props) => {
    const promise = Promise;
    const year = form.getFieldValue("year");
    if (year == null) {
      return promise.resolve();
    }
    console.log(typeof year, year);
    if (typeof year != "number") {
      if (year.trim() === "") {
        return promise.resolve();
      }
      if (year.trim() && year.trim().length == 4) {
        let currentyear = dayjs().format("YYYY");
        if (Number(year) > currentyear) {
          return promise.reject("Please enter a valid year");
        }

        return promise.resolve();
      }
      return promise.reject("Please enter a valid year");
    } else {
      let currentyear = dayjs().format("YYYY");
      if (year > currentyear) {
        return promise.reject("Please enter a valid year");
      }
    }
    return promise.resolve();
  };

  const valideDob = async () => {
    form
      .validateFields(["month", "date", "year"])
      .then((e) => {
        form.setFieldsValue({
          dob: `${form.getFieldValue("month")}-${form.getFieldValue(
            "date"
          )}-${form.getFieldValue("year")}`,
        });
      })
      .catch((err) => {});
  };

  return (
    <div className="dob_grid">
      <Form.Item
        name="month"
        className="display"
        rules={[
          {
            message: "Please enter a valid month",
            pattern: /^1[0-2]$|^0[1-9]$/,
          },
        ]}
      >
        <Input
          name="month"
          className="text-input"
          placeholder="Month"
          type="number"
          ref={month}
          autoComplete="off"
          maxLength={2}
          onBlur={(e) => {
            if (e.target.value.trim() === "") {
              form.setFieldsValue({
                month: "",
              });
            } else if (
              String(e.target.value).length < 2 &&
              !String(e.target.value).startsWith("0")
            ) {
              form.setFieldsValue({
                month: "0" + e.target.value,
              });
            }
            valideDob();
          }}
          onChange={(e) => {
            if (String(e.target.value).length > 1) {
              if (e.target.value < 12) {
                date.current.focus();
              }
            }
          }}
        />
      </Form.Item>

      <Form.Item
        name="date"
        className="display"
        rules={[
          {
            required: false,
            message: "Please enter a valid date",
            pattern: /^1[0-3]$|1[0-9]$|^0[1-9]$|^2[0-9]$|^3[0-2]$/,
          },
        ]}
      >
        <Input
          className="text-input"
          name="date"
          type="number"
          placeholder="Date"
          ref={date}
          maxLength={2}
          autoComplete="off"
          onBlur={(e) => {
            if (e.target.value.trim() === "") {
              form.setFieldsValue({
                date: "",
              });
            } else if (
              String(e.target.value).length < 2 &&
              !String(e.target.value).startsWith("0")
            ) {
              form.setFieldsValue({
                date: "0" + e.target.value,
              });
            }
            valideDob();
          }}
          onChange={(e) => {
            if (String(e.target.value).length > 1) {
              if (e.target.value < 31) {
                year.current.focus();
              }
            }
          }}
        />
      </Form.Item>
      <Form.Item
        name="year"
        className="display"
        rules={[
          {
            validator: (e) => validate(e, "year"),
          },
        ]}
      >
        <Input
          className="text-input"
          type="number"
          name="year"
          maxLength={4}
          autoComplete="off"
          onChange={(e) => {
            if (e.target.value.trim() === "") {
              form.setFieldsValue({
                year: "",
              });
            }
            if (e.target.value > 3) {
              valideDob();
            }
          }}
          onBlur={(e) => {
            const newValue = e.target.value.trim(); // Remove leading and trailing spaces

            if (newValue === "") {
              form.setFieldsValue({
                year: "",
              });
            } else if (newValue > 3) {
              valideDob();
            }
          }}
          ref={year}
          placeholder="Year"
        />
      </Form.Item>
    </div>
  );
};

export default ({ form, type, initialValues }) => {
  return (
    <>
      <Form.Item
        label="DOB"
        name="dob"
        className="display"
        rules={[
          {
            required: false,
            message: "Please enter a DOB",
          },
        ]}
      >
        <Date form={form} type={type} initialValues={initialValues} />
      </Form.Item>
    </>
  );
};
