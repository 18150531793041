import React, { Component } from "react";
import {
  Row,
  Col,
  Input,
  Modal,
  Button,
  message,
  Table,
  Form,
  Tooltip,
  Switch,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  FullscreenExitOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import "./TimeFrame.css";
import { Link } from "react-router-dom";
import UserContext from "../../../UserContext";
import ApiService from "../../../services/api-services";
import TextArea from "antd/lib/input/TextArea";
//import Form from 'antd/lib/form/Form';
import ReactDragListView from "react-drag-listview";
const FormItem = Form.Item;
const { Search } = Input;
var searchItem = [];
// const Add = 'Add'
const { confirm } = Modal;
//const ReactDragListView = require("react-drag-listview");
//import { Draggable } from "react-drag-reorder";

export default class dashboard extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    var formData = {};
    this.state = {
      token: sessionStorage.getItem("token"),
      formData: formData,
      closecodeFullyLoaded: false,
      isFetchSpeciality: false,
      isLoading: false,
      timeframeName: "",
      visible: false,
      modalVisible: false,
      time_frame_id: "",
      time_frame_name: "",
      timeFrameData: [],
      // value: 0,
      // codeData: [],
    };
  }

  // signOut() {
  //     const { setLoggin } = this.context
  //     setLoggin('false');
  //     this.props.history.push('/login')
  //     sessionStorage.setItem('isLoggedIn', false);
  // }
  componentDidMount() {
    if (this.state.token === null || this.state.token === "") {
      this.props.history.push("/login");
      window.location.reload(true);
    }
    this.fetchTimeFrames();
  }

  fetchTimeFrames = () => {
    let body = {
      org_id: "",
    };
    ApiService.adddetails
      .timeFrames(body)
      .then((data) => {
        //console.log(data)
        this.setState({ isFetchSpeciality: true });
        if (data.status === 200) {
          let resData = Array.from(data.data);
          let newData = [];
          console.log(resData);

          for (let i = 0; i < resData.length; i++) {
            newData.push({
              id: resData[i]["id"],
              name: resData[i]["name"],
              status: resData[i]["status"],
            });
          }
          this.setState({
            closecodeFullyLoaded: true,
            timeFrameData: newData,
            isLoading: true,
          });
        }
      })
      .catch((error) => {
        this.setState({ isFetchSpeciality: false });
      });
  };

  showModal = (item, name) => {
    this.setState((prev) => {
      return {
        time_frame_id: item,
        time_frame_name: name,
        visible: !prev.visible,
      };
    });
  };

  setModalVisible = (args) => this.setState({ modalVisible: args });

  // showMessage() {
  //     message.warn('Coming Soon...');
  // }

  // onChange(checked) {
  //     console.log(`switch to ${checked}`);
  //     let codeData = this.state.codeData;
  //     if(checked) {
  //         this.setState({
  //             codeData: {
  //                 ...codeData,
  //                 status: checked.target.value ? 1 : 0
  //             }
  //         })
  //         return
  //     }
  // }

  handleChange = (e, field) => {
    if (field === "timeframe") {
      this.setState({ timeframeName: e }, function () {});
    }
  };

  updateChange = (e, field) => {
    if (field === "timeframe") {
      this.setState({ time_frame_name: e }, function () {});
    }
  };

  doSave() {
    // alert('dosave')

    const { timeframeName } = this.state;
    let name = this.state.timeframeName;
    let str = "/^[a-zA-Z ]*$/";
    let patt = RegExp(/^[a-zA-Z0-9 ]*$/).test(name);
    console.log(patt, "patt");
    if (name === "" || patt === false) {
      message.error("Please Enter valid details ");
      return;
    }
    let body = {
      type: "master_add",
      // tf_id: this.state.time_frame_id,
      name:
        this.state.timeframeName.charAt(0).toUpperCase() +
        this.state.timeframeName.slice(1),
    };
    console.log(body, "codes");
    // debugger
    ApiService.adddetails
      .edittimeframe(body)
      .then((data) => {
        this.setState({ isLoading: false });
        if (data.status === 200) {
          message.success("Successfully added the Time frame");
          this.setState({ modalVisible: false });
          this.fetchTimeFrames();
          this.props.history.push("/TimeFrame");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        // if (error.data.status === 702) {
        //   message.error("time frame already exist");
        // }
      });
  }

  updateSave() {
    // alert('dosave')

    const { time_frame_name, time_frame_id } = this.state;
    let name = this.state.time_frame_name;
    let str = "/^[a-zA-Z ]*$/";
    let patt = RegExp(/^[a-zA-Z0-9 ]*$/).test(name);
    console.log(patt, "patt");
    if (name === "" || patt === false) {
      message.error("Please Enter valid details ");
      return;
    }
    let body = {
      type: "master_edit",
      tf_id: this.state.time_frame_id,
      name:
        this.state.time_frame_name.charAt(0).toUpperCase() +
        this.state.time_frame_name.slice(1),
    };
    console.log(body, "codes");
    // debugger
    ApiService.adddetails
      .edittimeframe(body)
      .then((data) => {
        this.setState({ isLoading: false });
        if (data.status === 200) {
          message.success("Successfully updated the Time frame");
          this.setState({ visible: false });
          this.fetchTimeFrames();
          this.props.history.push("/TimeFrame");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        // if (error.data.status === 702) {
        //   message.error("time frame already exist");
        // }
      });
  }

  showDeleteConfirm(id, status) {
    var that = this;

    confirm({
      className: "popup-margin ",
      title:
        "Are you sure want to" +
        " " +
        `${status === 1 ? "Deactivate" : "Activate"}` +
        " " +
        "this timeframe?",
      //   content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      height: "158px",
      onOk() {
        that.deleteTimeframe(id, status);
      },
      onCancel() {},
    });
  }

  showDeleteModal(id) {
    var that = this;

    confirm({
      className: "popup-margin ",
      title: "Are you sure want to delete" + " " + "this timeframe?",
      //   content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      height: "158px",
      onOk() {
        that.deleteTimeframes(id);
      },
      onCancel() {},
    });
  }

  deleteTimeframe(id, status) {
    let body = {
      type: "master_delete",
      tf_id: id,
      status: status === 0 ? 1 : 0,
    };
    console.log(body, "codes");
    // debugger
    ApiService.adddetails
      .edittimeframe(body)
      .then((data) => {
        this.setState({ isLoading: false });
        if (data.status === 200) {
          // message.success("Successfully deleted the timeframe");
          this.setState({ visible: false });
          this.fetchTimeFrames();
          this.props.history.push("/TimeFrame");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  }

  deleteTimeframes(id) {
    let body = {
      type: "master_hard_delete",
      tf_id: id,
    };
    console.log(body, "codes");
    // debugger
    ApiService.adddetails
      .edittimeframe(body)
      .then((data) => {
        this.setState({ isLoading: false });
        if (data.status === 200) {
          // message.success("Successfully deleted the timeframe");
          this.setState({ visible: false });
          this.fetchTimeFrames();
          this.props.history.push("/TimeFrame");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  }

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  setModalVisible = (args) => this.setState({ modalVisible: args });

  Tablecolumns = () => [
    {
      title: "Time Frame",
      dataIndex: "name",
      render: (url, record) => {
        return (
          <span className={record.status == 0 ? "hide" : "show"}>
            {record["name"]}
          </span>
        );
      },
      width: "80%",
    },
    {
      title: "Action",
      key: "action",
      width: "20%",
      render: (url, record) => {
        return (
          <span>
            {
              <li id="table-action">
                {record.status === 1 || record.status === null ? (
                  <Tooltip title="Edit">
                    <EditOutlined
                      id="table-edit"
                      style={{ margin: 14 }}
                      onClick={() =>
                        this.showModal(record["id"], record["name"])
                      }
                    ></EditOutlined>
                  </Tooltip>
                ) : (
                  <EditOutlined style={{ margin: 14, opacity: 0.5 }} />
                )}
                <div
                  onClick={() =>
                    this.showDeleteConfirm(record["id"], record["status"])
                  }
                >
                  <Link
                    to={{
                      pathname: "TimeFrame",
                      state: { id: record["id"], type: "delete" },
                    }}
                  >
                    <Switch
                      style={{ margin: 10 }}
                      // loading={this.state.rowLoader}
                      checked={
                        record["status"] === 0 || record["status"] === null
                          ? false
                          : true
                      }
                    >
                      {}
                    </Switch>
                  </Link>
                </div>
                <Tooltip title="Delete">
                  <DeleteOutlined
                    id="table-edit"
                    style={{ margin: 14 }}
                    onClick={() => this.showDeleteModal(record["id"])}
                  ></DeleteOutlined>
                </Tooltip>
                <a className="drag-handle" href="#">
                  <FullscreenExitOutlined />
                </a>
              </li>
            }
          </span>
        );
      },
    },
  ];

  render() {
    const { timeFrameData, rearranges } = this.state;
    //console.log(codeData,'cc')
    // if (timeFrameData) {
    //   let orgTag = timeFrameData.sort(function (a, b) {
    //     return a.name.localeCompare(b.name);
    //   });
    // }

    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const data = [...that.state.timeFrameData];
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        that.setState({
          data,
        });
        let rearrange = [];
        for (let i = 0; i < data.length; i++) {
          rearrange.push({
            tf_id: data[i]["id"],
            order: i,
          });
        }
        that.setState({
          rearranges: rearrange,
        });
        let body = {
          type: "appwide_rearrange",
          rearrange: that.state.rearranges,
        };
        ApiService.adddetails
          .edittimeframe(body)
          .then((data) => {
            that.setState({ isLoading: false });
            if (data.status === 200) {
              message.success("Successfully rearranged the Time frame");
              that.fetchTimeFrames();
              that.props.history.push("/TimeFrame");
            }
          })
          .catch((error) => {
            that.setState({ isLoading: false });

            // if (error.data.status === 702) {
            //   message.error("time frame already exist");
            // }
          });
        console.log(rearrange, "dddddd");
      },
      handleSelector: "a",
    };

    return (
      <>
        <Row>
          <Col span={8} className="flexcenter">
            <h3 className="heading-font">TIME FRAMES</h3>
          </Col>
          <div className="closecode-row">
            <Link to={{ pathname: "" }}>
              <Button
                className="add-btn"
                id=""
                onClick={() => this.setModalVisible(true)}
              >
                Add Time Frame
              </Button>
            </Link>
          </div>
        </Row>

        <Row>
          <Col span={24}>
            <div class="table-scroll">
              <ReactDragListView {...dragProps}>
                <Table
                  columns={this.Tablecolumns()}
                  //rowKey={record => record.id}
                  dataSource={this.state.timeFrameData}
                  pagination={this.state.pagination}
                  loading={!this.state.isLoading}
                  //  onChange={this.handleTableChange}
                />
              </ReactDragListView>
            </div>
          </Col>

          <Modal
            title="Add Time Frame"
            centered
            visible={this.state.modalVisible}
            okButtonProps={{ style: { display: "none" } }}
            onCancel={() =>
              this.setState({
                visible: !this.state.modalVisible,
              })
            }
            footer={[
              <div className="ant-modal-body-footer">
                <Button
                  key="back"
                  onClick={() =>
                    this.setState({
                      modalVisible: !this.state.modalVisible,
                      timeframeName: "",
                    })
                  }
                >
                  Return
                </Button>

                <Button
                  htmlType="submit"
                  key="submit"
                  type="primary"
                  onClick={() => this.doSave()}
                >
                  Submit
                </Button>
              </div>,
            ]}
          >
            <div className="row-4">
              <div className="modal_speciality">
                <Input
                  className={"text-input" + " " + this.state.ErrorInputClass}
                  id=""
                  placeholder="Time Frame"
                  required
                  type="text"
                  value={this.state.timeframeName}
                  onChange={(e) =>
                    this.handleChange(e.target.value, "timeframe")
                  }
                />
              </div>
            </div>
          </Modal>

          {/* edit close code modal */}
          <Modal
            title="Edit Time Frame"
            centered
            visible={this.state.visible}
            okButtonProps={{ style: { display: "none" } }}
            onCancel={() =>
              this.setState({
                visible: !this.state.visible,
              })
            }
            footer={[
              <div className="ant-modal-body-footer">
                <Button
                  key="back"
                  onClick={() =>
                    this.setState({
                      visible: !this.state.visible,
                      timeframeName: "",
                    })
                  }
                >
                  Return
                </Button>

                <Button
                  htmlType="submit"
                  key="submit"
                  type="primary"
                  onClick={() => this.updateSave()}
                >
                  Submit
                </Button>
              </div>,
            ]}
          >
            <div className="row-4">
              <div className="modal_speciality">
                <Input
                  className={"text-input" + " " + this.state.ErrorInputClass}
                  id=""
                  placeholder="Time Frame"
                  required
                  type="text"
                  value={this.state.time_frame_name}
                  onChange={(e) =>
                    this.updateChange(e.target.value, "timeframe")
                  }
                />
              </div>
            </div>
          </Modal>
        </Row>
      </>
    );
  }
}
