import React from 'react'

import SpecialityContent from './Speciality/SpecialityContainer'
// import './admin.css'
import './Chat/chatstyle.css'


export default class ChatList extends React.Component {
    state = {


    }

    render() {
        return <><SpecialityContent />

        </>
    }
}