import React, { Component, lazy, Suspense } from "react";
import {
  Row,
  Col,
  Avatar,
  List,
  Menu,
  Checkbox,
  Button,
  Divider,
  Modal,
  Input,
  message,
  Tooltip,
  Table,
  Slider,
} from "antd";
import {
  UserOutlined,
  EditFilled,
  EditOutlined,
  DeleteOutlined,
  DeleteFilled,
  BellFilled,
  PlusOutlined,
} from "@ant-design/icons";

import ApiService from "../../../services/api-services";
import { Link, NavLink, Redirect } from "react-router-dom";
import "antd/dist/antd.css";
import "./speciality.css";
import UserContext from "../../../UserContext";
// import ChatItems from './ChatItem'

const Clear = "Clear";
const Filter = "Filter";
const MainTitle = "SPECIALTY LISTS";
const SubTitle = "Specialty Lists";
const sort_by = "Org,User,Date,Specialty,Age of response,Close code";
const filter_by = "Unanswered Chats, Unread Chats,Live Chats";
const Add = "Add";
const Update = "Update";
const sortItems = sort_by.split(",");
const filterItems = filter_by.split(",");
const { Search } = Input;
const { confirm } = Modal;
var searchItem = [];

function getOrg(usrid) {
  console.info("will call orgapi");
}
export default class DoctorList extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      token: sessionStorage.getItem("token"),
      isLoading: false,
      bagecount: 0,
      filtermenuVisible: false,
      sortmenuVisible: false,
      isFetchSpeciality: false,
      SpecialityFullyLoaded: false,
      SpeclData: [],
      specialityName: "",
      speciality_id: "",
      spec_name: "",
      spec: "",
      from_age: 0,
      to_age: 100,
      update_from_age: 0,
      update_to_age: 0,
      visible: false,
      modalVisible: false,
      fillterProps: {
        sort_recent_opened: "",
        filter_live: "",
        filter_close: "",
        filter_unread: "",
        filter_unanswered: "",
      },
      PleaseEnterValue: "",
      ErrorText: "",
      ModalError: false,
      ErrorInputClass: "",
    };
  }
  // for adding speciality
  componentDidMount() {
    if (this.state.token === null || this.state.token === "") {
      this.props.history.push("/login");
      window.location.reload(true);
    }
    this.fetchSpeciality();
  }

  fetchSpeciality = () => {
    let body = {
      dob: "",
    };
    ApiService.adddetails
      .getSplType(body)
      .then((data) => {
        this.setState({ isFetchSpeciality: true });
        if (data.status === 200) {
          const newData =
            (data.data && data.data.filter((x) => x.status !== 0)) || [];
          this.setState({
            SpecialityFullyLoaded: true,
            SpeclData: newData,
            isLoading: true,
            isLoading: true,
          });
        }
      })
      .catch((error) => {
        this.setState({ isFetchSpeciality: false });
      });
  };

  filteredvalues = () => {};

  showModal = (item, name, updateFrom, updateTo) => {
    this.setState((prev) => {
      return {
        speciality_id: item,
        spec_name: name,
        modalName: name,
        update_from_age: updateFrom,
        update_to_age: updateTo,
        visible: !prev.visible,
      };
    });
  };

  handleApiCal = (field, propp) => {
    if (field === "Addspeciality") {
      let name = this.state.specialityName;
      let str = "/^[a-zA-Z]*$/";
      //let patt = RegExp(/^[a-zA-Z ]*$/).test(name);
      //console.log(patt, "patt");
      if (name === "") {
        message.error("Please Enter a valid Specialty Name ");

        // this.setState({ ModalError: true, ErrorInputClass: 'ErrorInputClass', ErrorText: "" })
        return;
      }
      let body = {
        name:
          this.state.specialityName.charAt(0).toUpperCase() +
          this.state.specialityName.slice(1),
        from_age: this.state.from_age,
        to_age: this.state.to_age,
      };
      ApiService.adddetails
        .addSpeciality(body)
        .then((data) => {
          this.setState({ isAddSpeciality: true });
          if (data.status === 200) {
            message.success("Specialty Successfully Added");
            this.setState({
              SpecialityAdded: true,
              modalVisible: false,
              specialityName: "",
              isLoading: true,
            });
            this.fetchSpeciality();
          }
        })
        .catch((error) => {
          this.setState({ isAddSpeciality: false });
        });
    }
  };

  handleUpdateCal = (speciality_id, spec_name) => {
    let body = {
      spec_id: speciality_id,
      name: spec_name,
      from_age: this.state.update_from_age,
      to_age: this.state.update_to_age,
    };
    let patt = RegExp(/^[a-zA-Z ]*$/).test(body.name);
    if (patt === false) {
      message.warn("Please enter valid value");
      return;
    }
    ApiService.adddetails
      .editSpec(body)
      .then((data) => {
        if (data.status === 200) {
          this.setState({
            visible: false,
            isLoading: true,
          });
          message.success("Specialty Successfully Updated");
          this.fetchSpeciality();

          // this.props.history.push('/speciality')
          // window.location.reload()
        }
      })
      .catch((error) => {
        this.setState({ isAddSpeciality: false });
      });
  };

  // handleUpdateCal = (field, propp) => {
  //     let body = {
  //         "spec_id": ''
  //     }
  //         ApiService.adddetails.editSpec(body).then(data => {
  //             if (data.status === 200) {
  //                 message.success('Speciality Successfully Updated');
  //               this.props.history.push('/speciality')

  //             }
  //         }).catch(error => {
  //             this.setState({ isAddSpeciality: false })
  //         });

  // }

  /* sign out */
  signOut() {
    const { setLoggin } = this.context;
    setLoggin("false");
    this.props.history.push("/login");
    sessionStorage.setItem("isLoggedIn", false);
  }

  ChartContents = () => {
    return <div> Contents</div>;
  };
  renderProfile({ url, usrid }) {
    getOrg(usrid);
    if (url) {
      return (
        <Avatar
          size={64}
          className="chatItem_avatar"
          shape="square"
          src={url}
        />
      );
    }

    return (
      <Avatar
        size={64}
        shape="square"
        icon={<UserOutlined />}
        className="chatItem_avatar"
      />
    );
  }
  handleRangeChange = (value) => {
    // value is an array containing the selected range
    this.setState({ from_age: value[0], to_age: value[1] });
  };
  handleUpdateRangeChange = (value) => {
    // value is an array containing the selected range
    this.setState({ update_from_age: value[0], update_to_age: value[1] });
  };
  handleChange = (val, field) => {
    if (field === "addSpeciality") {
      this.setState({ specialityName: val });
    }
  };

  updateChange = (e, field) => {
    if (field === "updateSpeciality") {
      this.setState({
        spec_name: e,
      });
    }
  };

  onChange = (fields, item) => (event) => {
    event.preventDefault();
    //  console.log(fields)
    switch (fields) {
      case "Filter":
        let itemIndex = filterItems.indexOf(item);

        if (itemIndex !== -1) {
          const fillterPropsCopy = Object.assign({}, this.state.fillterProps);
          //   console.log(filterItems[0], 'fileteritem')
          //  console.log(item, 'item')
          switch (item) {
            case filterItems[0]:
              if (fillterPropsCopy["filter_unanswered"] === "")
                fillterPropsCopy["filter_unanswered"] = "true";
              else fillterPropsCopy["filter_unanswered"] = "";
              this.setState({ fillterProps: fillterPropsCopy });

              break;
            case filterItems[1]:
              if (fillterPropsCopy["Unread Chats"] === "")
                fillterPropsCopy["Unread Chats"] = "true";
              else fillterPropsCopy["Unread Chats"] = "";
              this.setState({ fillterProps: fillterPropsCopy });
              break;
            case filterItems[2]:
              if (fillterPropsCopy["Live Chats"] === "")
                fillterPropsCopy["Live Chats"] = "true";
              else fillterPropsCopy["Live Chats"] = "";
              this.setState({ fillterProps: fillterPropsCopy });
              break;
            default:
              break;
          }

          //   console.log(this.state)
        }

        break;
      case "Filter-clear":
        const fillterPropsCopy = Object.assign({}, this.state.fillterProps);
        Object.keys(fillterPropsCopy).map((e) => (fillterPropsCopy[e] = ""));
        this.setState({ fillterProps: fillterPropsCopy });
        break;
      case "Filter-call":
        break;
      default:
        break;
    }
  };

  handlefiltermenuVisibleChange = () => {
    this.setState({ filtermenuVisible: !this.state.filtermenuVisible });
  };
  handleSortmenuVisibleChange = () => {
    this.setState({ sortmenuVisible: !this.state.sortmenuVisible });
  };

  setModalVisible = (args) => this.setState({ modalVisible: args });

  handleDelete = (id) => {
    ApiService.adddetails.deleteSpec(id).then((res) => {
      if (res.status === 200) {
        this.setState({ isLoading: true });
        message.success("Specialty Successfully deleted");
        this.fetchSpeciality();
      }
    });
  };

  showDeleteConfirm(id) {
    var that = this;
    // let draftId = this.state.formData._id;
    confirm({
      className: "popup-margin ",
      title: "Are you sure delete this Speciality?",
      //   content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        that.handleDelete(id);
      },
      onCancel() {},
    });
  }

  Tablecolumns = () => [
    {
      title: "Specialty List",
      width: "40%",
      render: (url, record) => {
        return <span>{record["name"]}</span>;
      },
    },
    {
      title: "Age Range",
      width: "40%",
      render: (url, record) => {
        return (
          //className={record.status == 0 ? "hide" : "show"}
          <span>{record["from_age"] + " - " + record["to_age"]}</span>
        );
      },
    },

    {
      title: "Actions",
      width: "20%",
      render: (url, record) => {
        return (
          <span>
            {
              <li id="table-action">
                <Tooltip title="Edit">
                  <EditOutlined
                    id="table-edit"
                    onClick={() =>
                      this.showModal(
                        record["spec_id"],
                        record["name"],
                        record["from_age"],
                        record["to_age"]
                      )
                    }
                  ></EditOutlined>
                </Tooltip>
                <div onClick={(e) => this.showDeleteConfirm(record["spec_id"])}>
                  <Tooltip title="Delete">
                    <DeleteOutlined id="table-delete" />
                  </Tooltip>
                </div>
              </li>
            }
          </span>
        );
      },
    },
  ];

  render() {
    const { SpeclData } = this.state;
    let orgTag = SpeclData.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });
    console.log(this.state);
    const menu = (Items, field, tag) => (
      <>
        {tag === "check" ? (
          <div style={{ paddingTop: "10px", background: "white" }} />
        ) : (
          ""
        )}

        <Menu>
          {Items.length &&
            Items.map((item) => (
              <Menu.Item key={item}>
                {tag === "check" ? (
                  <Checkbox onChange={this.onChange(field, item)}>
                    {item}
                  </Checkbox>
                ) : (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href
                    onClick={this.onChange(field, item)}
                  >
                    {item}
                  </a>
                )}
              </Menu.Item>
            ))}
          {tag === "check" ? (
            <>
              <Divider style={{ margin: "10px 0" }} />

              <div className="filter_buttons_container">
                <Button
                  type="primary"
                  shape="round"
                  size={"default"}
                  onClick={this.onChange("Filter-clear", null)}
                >
                  {Clear}{" "}
                </Button>
                <Button
                  type="primary"
                  shape="round"
                  size={"default"}
                  onClick={this.onChange("Filter-call", null)}
                >
                  {Filter}
                </Button>
              </div>
            </>
          ) : null}
        </Menu>

        {tag === "check" ? (
          <div style={{ paddingBottom: "10px", background: "white" }} />
        ) : (
          ""
        )}
      </>
    );

    return (
      <>
        <Row id="sub-row">
          <Col span={8} className="flexcenter">
            <h3 className="heading-font">{MainTitle}</h3>
          </Col>
          {/* <Col span={16}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>
                        <ul className="chatheaderMenu">
                            <li className="bellNotification">
                                <BellFilled style={{ color: '#0252a1' }} />
                            </li>
                            <li className="profile_button">
                                <div className="headerPorifileDiv">
                                    <Tooltip title="Sally Rivera">
                                        <Avatar shape="square" size="large" icon={<UserOutlined />} style={{ borderRadius: '30%' }} />
                                    </Tooltip>
                                    <span className="proifle_name">
                                        Welcome Sally Rivera
                                    </span>
                                </div>

                            </li>
                            <li className="logoutbutton">
                                <Button className="buttonOutline" ghost size="default">
                                    Logout
                                </Button>
                            </li>
                        </ul>
                    </div>
                </Col> */}
        </Row>
        <Row id="sub-row">
          <Col span={8}>
            <div className="chat_title">
              <h4 className="headerSubtitle"></h4>
            </div>
          </Col>

          <div className="addspec-btn">
            <Search
              placeholder="Search specialty"
              onSearch={(value) => console.log(value)}
              onKeyUp={(value) => {
                // console.log(value.target.value)
                ApiService.general
                  .search_spec({ key: value.target.value })
                  .then((res) => {
                    console.log(res);
                    if (res.data === null && value.target.value === "") {
                      this.props.history.push("/speciality");
                      console.log("hiias");
                    } else {
                      this.setState({ SpeclData: res.data, isLoading: true });
                      this.Tablecolumns();
                    }
                    // this.setView(res.data)
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            />
            <Button
              className="adduser-btn"
              id=""
              type="primary"
              loading={!this.state.isLoading}
              onClick={() => this.setModalVisible(true)}
            >
              Add Specialty
            </Button>

            {/* <Link to={{ pathname: '/admin' }}>
                         <Button className='btn-type' id='' loading={!this.state.isLoading }  >
                                Back
                        </Button>
                    </Link> */}
          </div>
        </Row>
        <Row>
          <Col span={24}>
            <div>
              <Table
                columns={this.Tablecolumns()}
                //rowKey={record => record.id} not my
                dataSource={this.state.SpeclData}
                pagination={this.state.pagination}
                loading={!this.state.isLoading}
              />
            </div>

            <Modal
              title="Add Specialty"
              centered
              visible={this.state.modalVisible}
              okButtonProps={{ style: { display: "none" } }}
              footer={[
                <Button
                  key="cancel"
                  onClick={() =>
                    this.setState({
                      modalVisible: !this.state.modalVisible,
                      specialityName: "",
                      from_age: 0,
                      to_age: 100,
                    })
                  }
                >
                  Cancel
                </Button>,
                <Button
                  key="add"
                  type="primary"
                  onClick={() => this.handleApiCal("Addspeciality", null)}
                >
                  Add
                </Button>,
              ]}
            >
              <div className="row-4">
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", flexdirection: "column" }}>
                    <label style={{ marginRight: 5, color: "red" }}>*</label>
                    <label>Specialty</label>
                  </div>
                  {/* <div className="modal_speciality"> */}
                  <Input
                    className={"text-input" + " " + this.state.ErrorInputClass}
                    id=""
                    placeholder="Specialty Name"
                    required
                    type="text"
                    value={this.state.specialityName}
                    onChange={(e) =>
                      this.handleChange(e.target.value, "addSpeciality")
                    }
                  />

                  {/* <div>{this.state.ModalError ? (
                                    <>
                                        <div>{this.state.ErrorText}</div>
                                    </>
                                ) : null} </div> */}
                </div>
                {/* <div> */}
                <div style={{ width: "100%", marginTop: 5, marginBottom: 30 }}>
                  <div style={{ display: "flex", flexdirection: "column" }}>
                    <label style={{ marginRight: 5, color: "red" }}>*</label>
                    <label>Age Range</label>
                  </div>
                  <Slider
                    max={120}
                    range={{ draggableTrack: true }}
                    value={[this.state.from_age, this.state.to_age]}
                    onChange={this.handleRangeChange}
                    tooltipVisible
                    tooltipPlacement="bottom"
                    tipFormatter={(value) => (value ? `${value}` : " 0 ")}
                  />
                </div>
              </div>
            </Modal>

            <Modal
              title="Update Specialty"
              centered
              visible={this.state.visible}
              okButtonProps={{ style: { display: "none" } }}
              // onCancel={() => this.setState({ visible: !this.state.visible })}
              footer={[
                <Button
                  key="cancel"
                  onClick={() =>
                    this.setState({ visible: !this.state.visible })
                  }
                >
                  Cancel
                </Button>,
                <Button
                  key="update"
                  type="primary"
                  onClick={() =>
                    this.handleUpdateCal(
                      this.state.speciality_id,
                      this.state.spec_name
                    )
                  }
                >
                  Update
                </Button>,
              ]}
            >
              <div className="row-4">
                {/* <div className="modal_speciality"> */}
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", flexdirection: "column" }}>
                    <label style={{ marginRight: 5, color: "red" }}>*</label>
                    <label>Specialty</label>
                  </div>
                  <Input
                    className={"text-input" + " " + this.state.ErrorInputClass}
                    id=""
                    type="text"
                    value={this.state.spec_name}
                    onChange={(e) =>
                      this.updateChange(e.target.value, "updateSpeciality")
                    }
                  />
                  {/* <div>{this.state.ModalError ? (
                                    <>
                                        <div>{this.state.ErrorText}</div>
                                    </>
                                ) : null} </div> */}
                </div>
                <div style={{ width: "100%", marginTop: 5, marginBottom: 30 }}>
                  <div style={{ display: "flex", flexdirection: "column" }}>
                    <label style={{ marginRight: 5, color: "red" }}>*</label>
                    <label>Age Range</label>
                  </div>
                  <Slider
                    max={120}
                    range={{ draggableTrack: true }}
                    value={[
                      this.state.update_from_age,
                      this.state.update_to_age,
                    ]}
                    onChange={this.handleUpdateRangeChange}
                    tooltipVisible
                    tooltipPlacement="bottom"
                    tipFormatter={(value) => (value ? `${value}` : " 0 ")}
                  />
                </div>
              </div>
            </Modal>

            {/* {this.props.data.map(e => console.log('img', e['chief_complaint']))}
                    {

                        this.props.data && this.props.data.map(e => <ChatItems
                            first_name={e['owner2.first_name']}
                            last_name={e['owner2.last_name']}
                            updatedAt={e['updatedAt']}
                            chief_complaint={e['chief_complaint']}
                            image_url={e['owner2.image_url']}
                            badge_count={(function (e, state) {
                                let filterType = state.filterType;
                                if (filterType === 'unanswered') {

                                }
                                else if (filterType === 'unread') {

                                }
                                return 0
                            })(e, this.props.filterType)}
                        />)
                    } */}
          </Col>
        </Row>
      </>
    );
  }
}
