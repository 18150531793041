import requestServer from '../request-server';
import CONFIG from '../config'


var internals = {};
// internals.login = function (email, password) {
  internals.login = function (data) {

  // let data = { email, password };
  return requestServer('/user_management/self_login', data, 'POST', {}, null, null, true, CONFIG.ADMIN_DOMAIN_NAME);
}



export default internals;
